var request = require('request');
// Object to hold debounced emit functions for eac
function log(language, source,Snapshotname, logLine,totalread=1) {
  var options = {
    'method': 'POST',
    'url': 'https://us-central1-livechat-production.cloudfunctions.net/open/log',
    'headers': {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "streams": [
        {
          "stream": {
            "SOURCE": language,
            "FILENAME_PATH": source,
            "Snapshotname": Snapshotname,
            "totalread": totalread
          },
          "values": [
            [
              (new Date().getTime() * 1000000).toString(), // Current time in nanoseconds
              logLine
            ]
          ]
        }
      ]
    })
  };

  // request(options, function (error, response) {
  //   if (error) throw new Error(error);
  //   console.log(response.body);
  // });
}

const uploadFile = async (fileName, type="file",file) => {
  try {
    const formData = new FormData();
    formData.append("file",file);
    formData.append("fileType",type);
    formData.append("projectName","chat");
    const response=await fetch(`https://chatbotupload.appypie.com/saveFile`,{
    // const response = await fetch( `https://canopyapi.appypie.com/fileupload?companyId=${companyId}&botId=${botId}&fileType=${type}&chatId=${chatId}`, {
      method: 'POST',
      body: formData,
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("customer")
      }
    });
    return response.json().then((data) => {
      console.log(data,"------upload-----");
      if (data.success) {
        // return data.data;
        console.log(data.data.file_url)
        return {filename:file.name,path:data.data.file_url,metadata:{}};
      } else {
        return false
      }
    })
  } catch (error) {
    return false
  }
}

module.exports = {
    log,
    uploadFile
  };;