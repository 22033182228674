/**
 * Comman functions
 * Author : Anuj Kumar Gupta
 */
// import db from "../../../config/firebase";
import firebase1  from 'firebase/compat';
import { MAIL_FUNCTION_URL, IP_STACK_URL, IP_STACK_KEY,GATEWAY_URL,FUNCTION_API_KEY } from '../../../config/siteConfig';
import moment from 'moment-timezone';
import Favico from 'react-favico-js';
import firebaseServices from "../../../firebase";
const favic = Favico({
	position  :'up',
	//animation :'pop',  //slide, fade, pop, popFade, none, 
	bgColor   :'#dd2c00', //228f2a-green, e87b2e-orange, dd2c00-red
	textColor :'#fff0e2',
	fontFamily : 'Times New Roman',
    //elementId : 'badgeFont'
	//type : 'circle', // rectangle, 
});
const publicIp = require("public-ip");

/**
 * Get getTimeFromDate from given timestamp
 * @param {timestamp} timestamp 
 */
export const getTimeFromDate = (timestamp) => {
	if (timestamp == null) {
		return;
	}
	var todayDate = new Date().getTime();
	todayDate = Math.floor(todayDate / 1000);
	var todayUTCDate = moment.tz(todayDate, "X", moment.tz.guess(true)).tz("UTC").format("YYYYMMDD");
	var current = parseInt(todayUTCDate);

	timestamp = Math.floor(timestamp / 1000);
	var previousUTCDate = moment.tz(timestamp, "X", "UTC").tz("UTC").format("YYYYMMDD");
	var previous = parseInt(previousUTCDate);

	var dateString = '';
	if (current === previous) { 
		dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("hh:mm A");
	} else {
		dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("DD/MM/YYYY hh:mm A");
    }
    return dateString;
}

export const getDateInUTC = async () => {
	var returnvat = 0;
	let newDate = new Date().getTime();
	await fetch(MAIL_FUNCTION_URL + "/dateApi/getdate?v=" + newDate).then((resp) => resp.json())
		.then(resp => {
			var todayUTCDate1 = parseInt(resp.date);
			returnvat = todayUTCDate1;
		}).catch(async () => {
			await firebase1.database().ref('/.info/serverTimeOffset')
				.once('value')
				.then(function stv(data) {
					var todayUTCDate = moment.tz(data.val() + Date.now(), "X", moment.tz.guess(true)).tz("UTC").format("X");
					todayUTCDate = parseInt(todayUTCDate);
					returnvat = todayUTCDate;
				}, function (err) {
					return err;
				});
		});


	// Add it in local storage ////
	addUpdateServerTime(returnvat);
	// Add it in local storage ////
	
	return returnvat;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getChatFileFolder = () => {
	var folderName = "";
	var dt = new Date();
	var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	folderName = dt.getDate() + '' + months[dt.getMonth()] + '' + dt.getFullYear();
	return folderName;
}

export const checkUrl = (text) => {
    const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return text.replace(urlRegex, function (url) {
		if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
			url = "http://" + url;
		}
      	return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
}	

export const getClientDateInUTC = async () => {
	var returnvat = 0;
	await firebase1.database().ref('/.info/serverTimeOffset')
				.once('value')
				.then(function stv(data) {
					var todayUTCDate = moment.tz(data.val() + Date.now(), "X", moment.tz.guess(true)).tz("UTC").format("X");
					todayUTCDate = parseInt(todayUTCDate);
					returnvat = todayUTCDate;
				}, function (err) {
					returnvat = parseInt(Date.now());
				});
	return returnvat;
}

export const createLog = async (agent, visitor, message) => {
	var serverTimestamp = await getClientDateInUTC();
	const logId = serverTimestamp + Math.ceil(Math.random() * 999999);
	firebaseServices.db.collection('users').doc(agent.ownerId).collection('visitors').doc(visitor.client_id).collection('logs').doc("LOG"+logId).set({
		addedon: serverTimestamp,
		agent_id: agent.agentId,
		agent_name: agent.name,
		msg: message,
		incoming: visitor.incoming, 
		status: visitor.visitor_status
	});
}

export const updateAgentChatData = async (agent, chatId, type) => {
	if (type === "first_msg_response") {
		const chkFirstResponse = agent.currentVisitor.first_res_time ? agent.currentVisitor.first_res_time : false;
		if(!chkFirstResponse) {
			var agentReference = firebaseServices.db.collection('users').doc(agent.ownerId).collection("agents").doc(agent.agentId);
			var visitorRef = firebaseServices.db.collection('users').doc(agent.ownerId).collection("visitors").doc(chatId);
			var serverTimestamp = await getDateInUTC();
			visitorRef.collection("chat_data").doc("FORM_DATA").get().then(vData => {
				if (vData.exists) {
					const chatForm = vData.data();
					agentReference.get().then(async (doc) => {
						if (doc.exists) {
							var _data = doc.data();
							var avg_first_response = _data.avg_first_response ? _data.avg_first_response : 0;
							var total_done_first_res = _data.total_done_first_res ? _data.total_done_first_res : 0;
							var new_response_time = serverTimestamp - chatForm.addedon;
							new_response_time = Math.ceil(new_response_time / 1000); // In Seconds
							if (avg_first_response === 0) {
								agentReference.update({  avg_first_response: new_response_time, total_done_first_res : 1 }).then(res => { }).catch((error) => {
									console.error("Error updating document Line 139 in comman.js : ", error);
								});
							} else {
								var total = (avg_first_response * total_done_first_res) + new_response_time;
								var addOne = eval(total_done_first_res) + 1;
								var new_avg = total / addOne;
								new_avg = Math.ceil(new_avg);
								agentReference.update({  avg_first_response: new_avg, total_done_first_res : addOne }).then(res => { }).catch((error) => {
									console.error("Error updating document Line 146 in comman.js : ", error);
								});
							}
						}
					});
				}
			});
			visitorRef.update({ first_res_time: serverTimestamp, updatedon: serverTimestamp});
		}
	} else {
		var agentReference = firebaseServices.db.collection('users').doc(agent.ownerId).collection("agents").doc(agent.agentId);
		var visitorRef = firebaseServices.db.collection('users').doc(agent.ownerId).collection("visitors").doc(chatId);
		visitorRef.get().then(vData => {
			if (vData.exists) {
				const visitor = vData.data();
				var addedon = visitor.addedon;
				var picktime = visitor.picktime;
				var end_chat_from = visitor.end_chat_from ? visitor.end_chat_from : "";
				var formStartTime = visitor.formStartTime ? visitor.formStartTime : 0;
				var agentIds = visitor.agent_ids;

				agentReference.get().then(async (doc) => {
					if (doc.exists) {
						var _data = doc.data();
						var avg_response = _data.avg_response_time ? _data.avg_response_time : 0;
						var avg_duration = _data.avg_chat_duration ? _data.avg_chat_duration : 0;
						var chat_total = _data.chat_total ? _data.chat_total : 0;
						var chat_closed = _data.chat_closed ? _data.chat_closed : 0;
						const increment = firebase1.firestore.FieldValue.increment(1);
						if (type === "response") {
							var serverTimestamp = await getDateInUTC();
							var new_response_time = serverTimestamp - formStartTime;
							new_response_time = Math.ceil(new_response_time / 1000);
							new_response_time = formStartTime === 0 ? 0 : new_response_time;
							if (avg_response === 0) {
								agentReference.update({ chat_total: increment, avg_response_time: new_response_time }).then(res => { }).catch((error) => {
									console.error("Error updating document Line 122 in comman.js : ", error);
								});
							} else {
								var total = (avg_response * chat_total) + new_response_time;
								var total_start = eval(chat_total) + 1;
								var new_avg = total / total_start;
								new_avg = Math.ceil(new_avg);
								agentReference.update({ chat_total: increment, avg_response_time: new_avg }).then(res => { }).catch((error) => {
									console.error("Error updating document Line 122 in comman.js : ", error);
								});
							}
							visitorRef.update({ chat_response_time: new_response_time }).then(res => { }).catch((error) => {
								console.error("Error updating document Line 122 in comman.js : ", error);
							});

							/* Send Email to Admin when Picked the chat By Agent Start */
							
							try {
								if (!agent.pickedFromVisitor && agent.incoming_picked_email===true) {
									var agentDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
									if (agentDetails.ownerEmail) {
										let agentName = agentDetails.alias ? agentDetails.alias : agentDetails.name;
										var body = [`formStartTime=${formStartTime}&type=agent_start_chat&chatId=${chatId}&email=${agentDetails.ownerEmail.toLowerCase()}&vname=${visitor.client_name}&vemail=${visitor.client_email}&vnumber=${visitor.client_phone}&vcountry=${visitor.geoDetails.country_name}&vos=${visitor.operating}&vbrowser=${visitor.browser}&vquery=${visitor.client_query}&vip=${visitor.geoDetails.country_ip}&vsystem=${visitor.system}&sourceUrl=${visitor.PageUrl_a[0]}&agentName=${agentName}`];
										//fetch(MAIL_FUNCTION_URL + '/mailFunctions/sendEmail', {
										fetch(GATEWAY_URL + '/sendEmail', {
											method: "post",
											body: body,
											headers: { "X-API-Key" : FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
										})
											.then(response => { })
											.catch(e => { });
									}
								}
							} catch (errrr) { }
							
							/* Send Email to Admin when Picked the chat By Agent End */

						} else if (type === "close") {
							const isChatend = agent.isChatend ? agent.isChatend : 0;
							if (end_chat_from !== "visitor" && isChatend === 0 && agentIds.length > 0) {
								var serverTimestamp = await getDateInUTC();
								var new_duration = serverTimestamp - picktime;
								new_duration = Math.ceil(new_duration / 1000);
								if (avg_duration === 0) {
									agentReference.update({ chat_closed: increment, avg_chat_duration: new_duration }).then(res => { }).catch((error) => {
										console.error("Error updating document Line 122 in comman.js : ", error);
									});
								} else {
									var total = (avg_duration * chat_closed) + new_duration;
									var total_closed = eval(chat_closed) + 1;
									var new_avg = total / total_closed;
									new_avg = Math.ceil(new_avg);
									agentReference.update({ chat_closed: increment, avg_chat_duration: new_avg }).then(res => { }).catch((error) => {
										console.error("Error updating document Line 153 in comman.js : ", error);
									});
								}
								var query = visitorRef.collection('agent_data').where('status', '==', 1);
								query.get().then((docs) => {
									docs.forEach(doc => {
										if (doc.exists) {
											doc.data().agent_ref.get().then((agentDocs) => {
												if (agentDocs.exists) {
													var agentData = agentDocs.data();
													if (agentData.chat_completed === '' || agentData.chat_completed === undefined || agentData.chat_completed === null || typeof agentData.chat_completed === undefined) {
														return doc.data().agent_ref.update({ chat_completed: 1 });
													} else {
														return doc.data().agent_ref.update({ chat_completed: increment });
													}
												}
											}).catch((err) => {
												console.error("Error updating document Line 190 in comman.js : ", err);
											})
										}
									});
								}).catch((err) => {
									console.error("Error updating document Line 195 in comman.js : ", err);
								});
								visitorRef.update({ chat_duration: new_duration }).then(res => { }).catch((error) => {
									console.error("Error updating document Line 198 in comman.js : ", error);
								});
							}
						}
					}
				});
			}
		});
	}
}

export const checkIsVisitorsLeft = (agent, leftseconds) => {
	try {
		//fetch(MAIL_FUNCTION_URL+"/app/checkvisitorleft", {
		fetch(GATEWAY_URL+"/checkvisitorleft", {
			method: "post",
			body: JSON.stringify({ ownerId: agent.ownerId, leftseconds: leftseconds }),
			headers: {
				"X-API-Key" : FUNCTION_API_KEY,
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Origin": window.location.origin
			}
		}).then(res => res.json())
		.then(response => { 
			return response;
		})
		.catch(e => {
			return "error";
		});
	} catch(err) {
		return "error";
	}
}

export const checkOngoingChats = (agent) => {
	try {
		var body = [`ownerId=${agent.ownerId}&agentId=${agent.agentId}&bId=${agent.TeleToken?agent.TeleToken.split(":")[0]:""}&wId=${agent.whatsapp&&agent.whatsapp.whatsappAccessId?agent.whatsapp.whatsappAccessId:""}&fId=${agent.metaFb?agent.metaFb.fbAccessId:""}`];
		//fetch(MAIL_FUNCTION_URL + "/app/chkongoingchats", {
		fetch(GATEWAY_URL + "/chkongoingchats", {
			method: "post",
			body: body,
			headers: {
				"X-API-Key" : FUNCTION_API_KEY,
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json",
				"Origin": window.location.origin
			}
		}).then(res => res.json())
			.then(response => {
				return response;
			})
			.catch(e => {
				return "error";
			});
	} catch (err) {
		return "error";
	}
}

export const getUserRole = (agentRoleId) => {
	return new Promise( resolve => {
		let agentDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
		if(agentDetails) {
			firebaseServices.db.collection('users').doc(agentDetails.ownerId).collection('roles').doc(agentRoleId).get().then((docs) => {
				if (docs.exists) {
					let name = docs.data().name;
					resolve(name);
				} else {
					resolve("agent");
				}
			}).catch(err => {
				resolve("agent");
			});
		} else {
			resolve("agent");
		}
	});
}

export const isMobileDevice = () => {
	let check = false;

	(function(a, p) {
		// Check for general mobile devices (phones)
		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)) {
			check = true;
		}
		// Check for iPads (standard, Mini, Air, Pro)
		else if (/ipad/i.test(a) || 
				 (p === 'MacIntel' && 'ontouchend' in document)) {
			check = true;
		}
		// Check for Android tablets (Samsung, Asus, etc.)
		else if (/android/i.test(a) && !/mobile/i.test(a)) {
			check = true;
		}
		// Check specifically for Samsung or Asus tablets
		else if (/samsung|sm-|gt-|asus/i.test(a)) {
			check = true;
		}
		// Fallback for devices that support touch but are not desktop
		else if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
			check = true;
		}
	})(navigator.userAgent || navigator.vendor || window.opera, navigator.platform);

	//console.log(`Is Mobile Device: ${check}`);
	return check;
};


export const addUpdateServerTime = (serverTime) => {
	var localTime = (new Date()).getTime();
	var type = "";
	var difftime = 0;
	if (localTime > serverTime) {
		difftime = localTime - serverTime;
		type = "minus";
	} else {
		difftime = serverTime - localTime;
		type = "plus";
	}
	localStorage.setItem("agentTimeZone", JSON.stringify({ serverTime: serverTime, localTime: localTime, type: type, difftime: difftime }));
}

export const nameIntials = (nameData) => {
	if(nameData){
		var arrayData = nameData.split(' ');
		var nameIntial = "NA";
		if(arrayData.length === 1) {
			nameIntial = nameData.substr(0, 2).toUpperCase();
		} else {
			nameIntial = arrayData[0].charAt(0).toUpperCase() + arrayData[arrayData.length - 1].charAt(0).toUpperCase();
		}
		return nameIntial;
	}
	else{
		return "Agent";
	}

}

export const isCallingEnable = (agent) => {
	let agentphoneNumber = (agent.phone_number && agent.phone_number !== "") ? agent.phone_number : false;
	if(agent.phone_call === 1 && agent.voip_call === 1) {
		if (agentphoneNumber && agent.calling_status === 1) {
			return "phone_voip";
		} else if(!agentphoneNumber && agent.calling_status === 1) {
			return "voip";
		}
	} else if(agent.phone_call === 1 && agent.voip_call === 0) {
		if (agentphoneNumber && agent.calling_status === 1) {
			return "phone";
		}
	} else if(agent.phone_call === 0 && agent.voip_call === 1) {
		if (agent.calling_status === 1) {
			return "voip";
		}
	}
	return false;
}

export const removeHtmlTags = (str) => {
	if ((str===null) || (str===''))
	return "";
	else
	str = str.toString();
	str = str.replace( /(<([^>]+)>)/ig, '');  
	str = str.replace(/https?:\/\/[^\s]+/ig, ''); 
	return str;	
}

export const getClientGeoDetail = () => {
	return new Promise(resolve => {
		publicIp.v4().then(async (ip4) => {
			fetch(IP_STACK_URL+'/'+ip4+"?access_key="+IP_STACK_KEY)
			  .then(resp => resp.json())
			  .then((resp) => {
				if (resp.country_name !== undefined && resp.country_name !== 'undefined') {
					resolve(resp)
				} else {
					resolve(false);
				}
			  }).catch((err) => {
				resolve(false);
			  });   
		}).catch((err) => {
			resolve(false);
		});
	});
}

export const saveAgentSession = (agent, onstatus) => {
	return true;
	/* try {
		var body = [`ownerId=${agent.ownerId}&agent_id=${agent.agentId}&type=login_logout&online_status=${onstatus}`];
		fetch(MAIL_FUNCTION_URL + "/analytics/modifysession", {
			method: "post",
			body: body,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Accept": "application/json",
				"Origin": window.location.origin
			}
		}).then(res => res.json())
			.then(response => {
				return response;
			})
			.catch(e => {
				return "error";
			});
	} catch (err) {
		return "error";
	} */
}

export const CHAT_TYPES = ["incoming_call","outgoing_call","outgoing_sms","incoming_sms","incoming_whatsapp","incoming_fb","incoming_tdm", "incoming_insta"];

export const getDeviceBrowser = () => {
	var sBrowser, sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Firefox") > -1) {
		sBrowser = "Mozilla";
		// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
	} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
		sBrowser = "Samsung";
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
	} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
		sBrowser = "Opera";
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
	} else if (sUsrAg.indexOf("Trident") > -1) {
		sBrowser = "IE";
		//sBrowser = "Microsoft Internet Explorer";
		// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
	} else if (sUsrAg.indexOf("Edge") > -1) {
		sBrowser = "Edge";
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	} else if (sUsrAg.indexOf("Chrome") > -1) {
		sBrowser = "Chrome";
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
	} else if (sUsrAg.indexOf("Safari") > -1) {
		sBrowser = "Safari";
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
	} else {
		sBrowser = "unknown";
	}

	return sBrowser;
}


export const decodeEntities = (function() {
	// this prevents any overhead from creating the object each time
	var element = document.createElement('div');
  
	function decodeHTMLEntities (str) {
	  if(str && typeof str === 'string') {
		// strip script/html tags
		str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
		str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
		element.innerHTML = str;
		str = element.textContent;
		element.textContent = '';
	  }
  
	  return str;
	}
  
	return decodeHTMLEntities;
  })();

export const notifyInBrowserTab = (total, webtitle, reset = false) => {
	try {
		let newTitle = webtitle;
		if(total > 0) {
			newTitle =  '(' + total + ') ' +  webtitle;
		}
		document.title = newTitle;
	} catch (err) { }

	/* By favicon icon start */
	try {
		if (reset) {
			favic.favico.reset();
		} else {
			favic.favico.badge(total, 'slide');
		}
	} catch (err) {}
	/* By favicon icon end */
}