import React from 'react';
// import db, { fb } from '../../config/firebase';
//import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
/*import { login, validation, loginWithGoogle, 
		googleLogin, MakeItLogout, 
		isUserExistWithEmail ,
		getConvertPassCed,
		liveChatGoogleLogin
} from "./../../config/SignupLogin"; */
import { login, getSessionCheck, logInWithCustomToken } from "./../../config/SignupLogin";
import { getDateInUTC, saveAgentSession } from "../livechat/components/Comman";
import CountryList from "country-list-with-dial-code-and-flag";
import { MAIL_FUNCTION_URL, COMMON_LOGIN_URL, commonLoginFacebook, commonLoginGoogle, CLOSE_INACTIVE_CHATS_FROM, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY, DOMAIN_NAME } from '../../config/siteConfig';
import Device from 'react-device';

import { getOwnerDetails, agentLoginFromAWS, setNewPassword, userLogout, activityLog, setCookies, MFAVerify, emailVerifyAWS, resendEmailOTP, checkAgentSession, ownerCommongLoginWithMobileNumber } from "../../services/service";
import { toast, ToastContainer } from 'react-toastify';
import firebaseServices from '../../firebase';


const dateFormat = 'X';
// console.log('---CountryList---', CountryList.getAll());
let list = CountryList.getAll()

class Login extends React.Component {
	constructor(props) {
		super();
		this.ownerId = '';
		this.companyName = '';
		this.ownerEmail = '';
		this.allOwnerData = {};
		this.state = {
			email: '',
			mobile_number: '',
			pwd: '',
			login_error: '',
			email_error: '',
			pwd_error: '',
			ownerId: '',
			ownerIdentifire: '',
			alias: '',
			loader: false,
			sevenDaysLogin: true,
			deactivateAccountShow: false,
			agentDetails: {},
			UA: '',
			myBrowser: '',
			OS: '',
			iframeUrl: COMMON_LOGIN_URL + "/poplogin",
			ownerLogin: false,
			agentLogin: true,
			showForcePasswordBox: false,
			old_password: '',
			new_password: '',
			old_password_error: false,
			new_password_error: false,
			emailExistasOwner: {},
			haveGetCompany: false,
			isEmailVerified: true,
			isMFAEnabled: false,
			cookiesList: [],
			mfa_otp_error: '',
			mfa_otp: '',
			cinfirmLogin: "",
			passwordVisible: false,
			showOtpLogin: false,
			isEmail: true,
			isPhoneNumber: false,
			countryCode: "+91",
			countriesList: list,
			show_full_country_name: false,
		}	
	}


    verifyUser = () => {
        let userDetails = localStorage.getItem('agent');
        userDetails = JSON.parse(userDetails);
        let details = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : (userDetails ? userDetails.token : null);
        if(userDetails!==null && details!==null) {
            this.setState({
                loader:true
            })
            let body = [`token=${details}&&uid=${userDetails.ownerId}&&type=${userDetails.type}&&agentId=${userDetails.agentId}`];
            //fetch(MAIL_FUNCTION_URL+"/loginApi/verify", {
            fetch(GATEWAY_URL+"/verify", {
                method:"post",
                body:body,
                headers : {"X-API-Key" : FUNCTION_API_KEY,"Content-type": "application/x-www-form-urlencoded" }
            })
            .then(response => response.json())
            .then(response => {
                // console.log(response);
                if(response.valid===true) {
                    window.location.href= "/"+userDetails.ownerIdentifire;
                } else {
                    this.logout(userDetails.ownerId,userDetails.agentId);
                }
             });
        } else {
            // getSessionCheck();
            localStorage.removeItem('common_redirect');
            this.setState({
                loader:true
            });
            ///// Check owner identiire exist or not ///////
            let ownerIdentifire = this.props.match.params.ownerIden ? this.props.match.params.ownerIden : "";
            ownerIdentifire = ownerIdentifire.trim();
						
						console.log("ownerIdentifire",ownerIdentifire, firebaseServices.db);
            firebaseServices.db.collection("users").where("organization_alias", "==", ownerIdentifire).where("deleted", '==', false).limit(1).get().then(users => {
                if(users.size === 0) {
                    window.location.href= "/login";
                    return false;
                } else {
                    users.forEach(async (data) => {
                        let _data = data.data();
                        this.ownerId = data.id;
                        this.allOwnerData = _data;
                        if(_data.deleted===true) {
                            window.location.href= "/login";
                            return false;
                        }
                        // let emailExistasOwner = await getOwnerDetails(ownerIdentifire);
                        // if(emailExistasOwner.status === 200) {
                        //     this.setState({
                        //         emailExistasOwner: emailExistasOwner,
                        //         haveGetCompany: true
                        //     })
                        // }
						this.setState({
							emailExistasOwner: {},
							haveGetCompany: true
						})
                    })
                    this.setState({
                        loader:false
                    });
                }
            });
            ///// Check owner identiire exist or not ///////
        }
    }

    logout = (ownerId,agentId) => {
        firebaseServices.db.collection("users").doc(ownerId).collection("agents").doc(agentId).update({online_status:0}).then((ref) => {
            localStorage.removeItem('agent');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('agentData');
            localStorage.removeItem('common_redirect');
            localStorage.removeItem('current_login');
            this.setState({
                loader:false
            })
        })
    }



	async componentDidMount() {
		localStorage.removeItem('loggingout');
		try {
			localStorage.removeItem('chatTranslator');
			localStorage.removeItem('changeTextLangArr');
		} catch (erorrr) {
			console.log("Error in removing localstorage data of Translator while logout");
		}
		this.verifyUser();
		const showUnAuthorizedToast = localStorage.getItem("showUnAuthorizedToast");
		if (showUnAuthorizedToast === "true") {
			localStorage.removeItem("showUnAuthorizedToast");
			toast.error("Your account is not activated");
		}
		this.setState({
			ownerIdentifire: this.props.match.params.ownerIden
			//ownerId:this.props.match.params.id
		})
	}

	changehandle = (e) => {
		let target = e.target;
		let values = target.value.trim();
		this.setState({
			[target.name]: values
		})
		if (target.name === 'email' && values === '') {
			this.setState({
				email_error: 'Field cannot be left blank'
			})
		}
		else if (target.name === 'email' && !this.validateEmail(values)) {
			this.setState({
				email_error: 'Enter valid email address'
			})
		}
		else if (target.name === 'email') {


			if (values == localStorage.getItem('otpEmail')) {

				this.setState({ showOtpLogin: true, email_error: "" })
			} else {
				this.setState({ showOtpLogin: false, email_error: "" })
			}
		}

		if (target.name === 'pwd' && values === '') {
			this.setState({
				pwd_error: 'Field cannot be left blank'
			})
		} else if (target.name === 'pwd') {
			this.setState({
				pwd_error: ''
			})
		}
	}

	handleInputChange = (event) => {
		const input = event.target.value;
		this.setState({ contact: input });

		// Use a regular expression to check if the input looks like an email address
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		const isEmail = emailRegex.test(input);

		// Use a regular expression to check if the input looks like a phone number
		const phoneRegex = /^\+?\d+$/;
		const isPhoneNumber = phoneRegex.test(input);
		console.log('---isEmail---', isEmail, isPhoneNumber);
		this.setState({ isEmail, isPhoneNumber });
		if(isEmail)
			this.setState({email : input})
		else if(isPhoneNumber)
			this.setState({mobile_number : input})
		else
			this.setState({email : input})

	};

	timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
	}


	sendToCommonLogin = () => {
		window.location.href = COMMON_LOGIN_URL + "/login?frompage=" + DOMAIN_NAME + "/common-session&website=" + DOMAIN_NAME;
		return
	}

	checkPaymentStatus = (_data) => {
		return new Promise(async (resolve, reject) => {
			let paymentStatus = false;
			if (_data.payment_status === 'complete') {
				paymentStatus = true;
			} else {
				let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
				if (nextBillingDate) {
					let serverTimeStamp = await getDateInUTC();
					let splitDate = nextBillingDate.split('-');
					let splitDay = parseInt(splitDate[2]) + 1;
					var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
					if (paymentDate < serverTimeStamp) {
						paymentStatus = false;
					} else {
						paymentStatus = true;
					}
				} else {
					paymentStatus = false;
				}
			}
			_data.paymentStatus = paymentStatus;
			resolve(paymentStatus);
		})
	}

	getCompanyDetails = (ownerIdentifire) => {
		console.log('---322----');
		return new Promise((resolve, reject) => {
			let companyDetails = {};
			firebaseServices.db.collection("users").where("organization_alias", "==", ownerIdentifire).where('deleted', '==', false).limit(1).get().then(users => {
				if (users.size > 0) {
					users.forEach((user) => {
						companyDetails = user.data();
						companyDetails.id = user.id;
					})
					resolve(companyDetails)
				} else {
					window.location.href = "/login";
					resolve(false);
				}
			}).catch((err) => {
				console.log('error at login line 365', err);
				window.location.href = "/login";
				resolve(false);
			});
		})
	}

	validateUsers = async () => {
		let emailValue
		let mobile_number
		console.log('---this.state.isEmail---', this.state.countryCode, this.state.isPhoneNumber);
		if (this.state.isEmail) {
			emailValue = this.state.email ? this.state.email : document.getElementById("email").value;
		} else if (this.state.isPhoneNumber) {
			// set mobile number with country code
			mobile_number = this.state.mobile_number ? this.state.mobile_number :  document.getElementById("mobile_number").value
		}
		emailValue = emailValue?.toLowerCase();
		console.log('---emailValue---', emailValue, mobile_number);
		let pwdValue = this.state.pwd ? this.state.pwd : document.getElementById("pwd").value;
		let validate = this.validation();
		if (validate === true) {
			this.setState({
				loader: true
			});
			let ownerIdentifire = this.state.ownerIdentifire ? this.state.ownerIdentifire : "";
			ownerIdentifire = ownerIdentifire.trim();
			console.log('------this.allOwnerData>>>>', this.allOwnerData);
			let companyDetails = this.allOwnerData;
			companyDetails.id = this.ownerId;
			if (this.ownerId === "" || !this.allOwnerData.company_name || !this.allOwnerData.organization_alias) {
				companyDetails = await this.getCompanyDetails(ownerIdentifire);
			}
			localStorage.removeItem('agentData');
			localStorage.removeItem('common_redirect');
			localStorage.removeItem('current_login');
			this.ownerId = companyDetails.id;
			this.companyName = companyDetails.company_name;
			let emailExistasOwner = this.state.emailExistasOwner
			if (!this.state.haveGetCompany) {
				emailExistasOwner = await getOwnerDetails(companyDetails.organization_alias);
			}
			let checkPaymentStatus = await this.checkPaymentStatus(companyDetails);
			if (parseInt(emailExistasOwner.status) === 200||true) {
				let ownerData = emailExistasOwner.data;
				this.ownerEmail = ownerData?.email;
				
					
					if (checkPaymentStatus === false) {
						this.setState({
							pwd_error: 'Account is not paid, unable to log in.',
							loader: false
						})
						return false;
					} else {
						let password = (pwdValue); //as discussed with common login
						/* Added by AG Start */
						let results = await checkAgentSession(ownerIdentifire, emailValue.trim(), password);
						
							if (results.status === 200 && results.userdata && results.userdata.code!=="NotAuthorizedException") {
								if (results.status_msg === 400) {
									this.setState({
										showForcePasswordBox: true,
										loader: false
									})
								} else {
									localStorage.setItem('alias',results.user )									
									let userData = results.loginWith=="agent"?results.agentData:results.data;
									let ownerDataApi=results.data;
									// let payloadData = userData.idToken.payload;
									let refId = userData.preferred_username;
									let url=GATEWAY_URL + '/updateAgentDetails'
									var body = [
										`ownerId=${companyDetails.id}&agentId=${refId}&sevenDaysLogin=${this.state.sevenDaysLogin}`
									]
									if (results.loginWith == "owner") {
											var body = [`ownerId=${companyDetails.id}`];

											url = GATEWAY_URL + "/getOwnerAgent";
										}
									fetch(url, {
										method: "post",
										body: body,
										headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
									})
										.then(response => response.json())
										.then(async (response) => {
											if (response.status === 200) {
												localStorage.setItem('redirectAgent', 'false');
												let _data = response.data;
												this.setState({
													agentDetails: _data
												})
												if (response.deactivated === true) {
													if (_data.roleType === "Owner") {
														this.setState({
															deactivateAccountShow: true,
															loader: false
														})
													} else {
														this.setState({
															pwd_error: 'Account is deactivated',
															loader: false
														})
													}
													return false;
												} else {
													_data.email = emailValue;
													localStorage.setItem('accessToken', _data.jwToken);
													delete _data.jwToken;
													_data.ownerIdentifire = ownerIdentifire;
													if (userData.pic && userData.pic !== '' && userData.pic !== 'notexist') {
														_data.profile_img = userData.imageurl + '/' + userData.thumbnail;
														_data.profile_img_orig = userData.imageurl + '/' + userData.pic;
													} else {
														_data.profile_img = '';
														_data.profile_img_orig = ''
													}
													_data.first_name = userData['first_name'];
													_data.last_name = userData['last_name'];
													_data.agent_name = _data.last_name && _data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? _data.first_name + ' ' + _data.last_name : _data.first_name;
													_data.alias = userData['alias'] && userData['alias'] !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userData['alias'] : '';
													if (_data.alias !== "") {
														_data.name = _data.alias;
													}
													_data.company_name = this.companyName;
													_data.ownerDetails = ownerDataApi;//new api response
													_data.ownerEmail = ownerDataApi.email;//new api respone
													_data.phone_call = companyDetails.phone_call ? companyDetails.phone_call : 0;
													_data.voip_call = companyDetails.voip_call ? companyDetails.voip_call : 0;
													_data.widget_voip_call = companyDetails.widget_voip_call ? companyDetails.widget_voip_call : 0;
													_data.whatsapp_msg = companyDetails.whatsapp_msg ? companyDetails.whatsapp_msg : 0;
													_data.languages = companyDetails.languages ? companyDetails.languages : [];
													_data.isInHouseAccount = companyDetails.isInHouseAccount ? companyDetails.isInHouseAccount : 0;
													_data.fb_page_token = companyDetails.fb_page_token ? companyDetails.fb_page_token : "";
													_data.tdm_tokens = companyDetails.tdm_tokens ? companyDetails.tdm_tokens : "";
													_data.smart_compose = companyDetails.smart_compose ? companyDetails.smart_compose : 0;
													_data.triggers_status = companyDetails.triggers_status ? companyDetails.triggers_status : 0;

													/* Add Agent session start */
													saveAgentSession(_data, "login");
													/* Add Agent session end */
													
													/* For extra security sign in with token start */
													const isLogedInInSecurity = await logInWithCustomToken(companyDetails.id, refId);
													/* For extra security sign in with token end */

													const newData = { ...companyDetails, ..._data };
													
													localStorage.setItem('agent', JSON.stringify(newData));
													await activityLog('login');
													var agentLoginCookies = {
														"61be0d8c3c195df8308def9fac389355": 1
													}
													await setCookies(agentLoginCookies);
													const redirectTo = localStorage.getItem('redirect_to');
													localStorage.removeItem('usernotexist');
													if (redirectTo) {
														localStorage.removeItem('redirect_to');
														window.location.href = redirectTo;
													} else {
														window.location.href = "/" + ownerIdentifire;
													}
												}
											} else if (response.status === 403) {
												await userLogout();
												let message = response.message ? response.message : 'Incorrect email or password';
												this.setState({
													loader: false,
													pwd_error: message
												});
											} else if (response.status === 401) {
												await userLogout();
												let message = response.message ? response.message : 'Your account has been suspended';
												this.setState({
													loader: false,
													pwd_error: message
												});
											} else {
												toast.error("Something went wrong, Please try again after some time!")
												this.setState({
													loader: false
												})

											}
										}).catch((err) => {
											console.log(err);
										})
								}
							} else if (results.status === 400 ) {
								toast.error(`${results.msg}`)
								this.setState({
									loader: false,
									pwd_error: "Incorrect email or password"
								});
							} else {
								toast.error("Something went wrong, Please try again after some time!")
								this.setState({
									loader: false
								})
							}
						}
						/* Added by AG End */
					// }
				// }
			} else {
				toast.error("Something went wrong, Please try again after some time!")
				this.setState({
					loader: false
				})
			}
		}
	}

	/* Added by AG Start */

	confirmLogIn = (val) => {
		if (val === "yes") {
			this.setState({ cinfirmLogin: val, loader: true });
		} else {
			this.setState({ cinfirmLogin: val });
		}
	}

	closeConfirmLogIn = () => {
		this.setState({ cinfirmLogin: "" });
	}

	/* Added by AG End */

	confirmEmailVerified = async () => {
		this.setState({
			loader: true
		})
		await setCookies(this.state.cookiesList);
		await getSessionCheck();
	}

	verifyEmailAws = async () => {
		this.setState({
			loader: true
		})
		let emailValue = this.state.email ? this.state.email : document.getElementById("email").value;
		let email_otp = this.state.email_otp ? this.state.email_otp : document.getElementById("email_otp").value;
		const verifyEmail = await emailVerifyAWS(emailValue, email_otp);
		console.log('verifyEmail >>> ', verifyEmail);
		if (verifyEmail.status === 200) {
			this.confirmEmailVerified();
		} else {
			this.setState({
				loader: false,
				email_otp_error: verifyEmail.message ? verifyEmail.message : 'Something went wrong, please try again after some time!'
			})
		}
	}

	resendEmailOTP = async () => {
		this.setState({
			loader: true
		})
		let emailValue = this.state.email ? this.state.email : document.getElementById("email").value;
		const resendEmail = await resendEmailOTP(emailValue);
		if (resendEmail.status === 200) {
			this.setState({
				loader: false,
			})
			toast.success("Please check your registered email for verification")
		} else {
			let errorMsg = resendEmail.message ? resendEmail.message : 'Something went wrong, please try again after some time!'
			this.setState({
				loader: false
			}, () => {
				toast.error(errorMsg);
			})
		}
	}

	verifyMFA = async () => {
		this.setState({
			loader: true
		})
		let emailValue = this.state.email ? this.state.email : document.getElementById("email").value;
		let mfaOtp = this.state.mfa_otp ? this.state.mfa_otp : document.getElementById("mfa_otp").value;
		const verifyMFA = await MFAVerify(emailValue, mfaOtp);
		console.log('verifyMFA >>> ', verifyMFA);
		if (verifyMFA.status === 200) {
			this.confirmEmailVerified();
		} else {
			this.setState({
				loader: false,
				mfa_otp_error: verifyMFA.message ? verifyMFA.message : 'Something went wrong, please try again after some time!'
			})
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.validateUsers();
		return false;
	}

	onChangeHandler = (e) => {
		let target = e.target;
		this.setState({
			[target.name]: target.value,
			new_password_error: false,
			old_password_error: false
		})
	}

	validation() {
		let email_error = '';
		let pwd_error = '';
		let email
		let mobile_number
		if (this.state.isEmail) {
			email = this.state.email ? this.state.email : document.getElementById("email").value;
		}
		let password = this.state.pwd ? this.state.pwd : document.getElementById("pwd").value;
		if (this.state.isPhoneNumber) {
			mobile_number = this.state.mobile_number ? this.state.mobile_number : this.state.countryCode + document.getElementById("mobile_number").value;
		}
		if (email === '' && this.state.isEmail) {
			email_error = 'error';
			this.setState({
				email_error: 'Field cannot be left blank'
			})
		}
		else if (!this.validateEmail(email) && this.state.isEmail) {
			email_error = 'error';
			this.setState({
				email_error: 'Enter valid email address'
			})
		} else if (this.state.isPhoneNumber && email === '' && mobile_number === " ") {
			email_error = 'error';
			this.setState({
				email_error: 'Field cannot be left blank'
			})
		}

		if (password === '') {
			pwd_error = 'error';
			this.setState({
				pwd_error: 'Field cannot be left blank'
			})
		} else {
			pwd_error = '';
			this.setState({
				pwd_error: ''
			})
		}

		if (email_error !== '' || pwd_error !== '') {
			return false;
		} else {
			return true;
		}

	}

	validateEmail = email => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	update7DaysLogin = (e) => {
		let target = e.target;
		this.setState({
			[target.name]: target.checked
		})
	}

	cancelReactivateAccount = () => {
		this.setState({
			deactivateAccountShow: false
		})
	}

	confirmReactivateAccount = () => {
		var body = [`ownerId=${this.ownerId}&ownerEmail=${this.state.agentDetails.email.toLowerCase()}&browser=${this.state.myBrowser}&OS=${this.state.OS}&ownerName=${this.state.agentDetails.name}&companyName=${this.companyName}`]
		//fetch(MAIL_FUNCTION_URL+"/loginApi/reactivateAccount", {
		fetch(GATEWAY_URL + "/reactivateAccount", {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
		})
			.then(response => response.json())
			.then(response => {
				if (response.status === true) {
					var _data = this.state.agentDetails;
					localStorage.setItem('agentData', JSON.stringify(_data));
					console.log('----', this.state.email, this.state.pwd);
					var userkey = btoa(this.state.email + '-' + this.state.pwd);
					var url = COMMON_LOGIN_URL + '/authenticate?userkey=' + userkey;
					var win = window.open(url, 'popup', 'width=600,height=600');
					this.setState({
						deactivateAccountShow: false
					})
					var timer = setInterval(function () {
						if (win.closed) {
							clearInterval(timer);
							this.setState({
								loader: true
							})
						}
					}.bind(this), 1000);
					// let ownerIdentifire = this.state.ownerIdentifire?this.state.ownerIdentifire : "";
					// ownerIdentifire = ownerIdentifire.trim();
					// if(_data.alias !== "") {
					//     _data.agent_name = _data.name;
					//     _data.name = _data.alias;
					// }
					// localStorage.setItem('accessToken',_data.jwToken);
					// delete _data.jwToken;
					// _data.ownerIdentifire = ownerIdentifire;
					// _data.ownerEmail = this.ownerEmail;
					// localStorage.setItem('agent',JSON.stringify(_data));
					// window.location.href = "/"+ownerIdentifire;
				} else {
					this.setState({
						pwd_error: "Something went wrong!",
						loader: false
					})
				}
			});
	}

	handleCountryCodeChange = (event) => {
		console.log(event);
		this.setState({ countryCode: event });
	};
	onChangee = (deviceInfo) => {
		this.setState({
			UA: deviceInfo.ua,
			myBrowser: deviceInfo.browser.name,
			OS: deviceInfo.os.name
		})
	}

	iframeLoad() { }

	iframeReady() { }
	showHide(ownerLogin, agentLogin) {
		this.setState({
			ownerLogin: ownerLogin,
			agentLogin: agentLogin
		})
	}
	windowopen(e, type) {
		e.preventDefault();
		//alert("aaaaaaaaaaaa");
		if (type == 'google') {
			window.open(commonLoginGoogle, 'popup', 'width=600,height=600'); return false;
		}
		else {
			window.open(commonLoginFacebook, 'popup', 'width=600,height=600'); return false;
		}
	}

	updateNewPassword = async () => {
		if (this.state.old_password === '') {
			this.setState({
				old_password_error: true
			})
			document.getElementById('old_password').focus();
			return false;
		} else if (this.state.new_password === '') {
			this.setState({
				new_password_error: true
			})
			document.getElementById('new_password').focus();
			return false;
		} else {
			this.setState({
				loader: true
			})
			let ownerIdentifire = this.state.ownerIdentifire ? this.state.ownerIdentifire : "";
			ownerIdentifire = ownerIdentifire.trim();
			let updatepassword = await setNewPassword(this.state.old_password, this.state.new_password, ownerIdentifire, this.state.email);
			if (updatepassword.status === 200) {
				toast.success(updatepassword.msg);
				this.closePasswordChangeBox();
				this.setState({
					loader: false
				})
			} else {
				this.setState({
					loader: false
				})
				toast.error(updatepassword.msg);
			}
		}
	}

	closePasswordChangeBox = () => {
		this.setState({
			showForcePasswordBox: false
		})
	}

	handleViewPassword = () => {
		if (this.state.passwordVisible) {
			this.setState({
				passwordVisible: false
			})
		} else {
			this.setState({
				passwordVisible: true
			})
		}
	}

	render() {

		return (
			<>
				<ToastContainer autoClose={5000} />
				<div className="login-container loginBG">
					<Device onChange={this.onChangee} />
					<div className="loginPage">
						<div className="container">
							<div className="login-header">
								<img
									className="loginHeaderLogo"
									src={require("../../assets/images/appypie-livechat.svg")}
									alt="logo"
								/>
							</div>
						</div>
						<div className="container">
							{this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
							<div className="loginBody pt-5">


								<div className="login-description signup-description veryfy-your-mail-description">
									<h5>Login to chat with your visitors and customers</h5>
								</div>
								<div id="owner_login" style={{ display: this.state.ownerLogin ? 'block' : 'none' }} >
									<div className="login-body" style={{ textAlign: "center" }}>
									</div>
									<div className="text-center pt-8">
										<a className="cursor-pointer" onClick={() => this.showHide(false, true)} >Login as Agent</a>
									</div>
								</div>
								<div id="agent_login" style={{ display: this.state.agentLogin ? 'block' : 'none' }} >
									<div className="login-body">
										<div className="veryfy-your-mail-body">
											<div className="veryfy-your-mail-form veryfy-your-mail-form-cutome">
												<form onSubmit={this.handleSubmit}>
													<div className="form-group">
														{/* <label htmlFor="email">Email:</label> */}
														{ }
														{this.state.isEmail ? <input type="text" className="form-control " id="email" placeholder="Enter Email/ Phone number" name="email" onChange={this.handleInputChange} /> : (this.state.isPhoneNumber ? <div className='phoneInput'>
															<select
																as="select"
																id="countryCode"
																className="customArrow customArrowBorder0 text-dark-gray"
																value={this.state.countryCode}
																onChange={(e) =>
																	this.handleCountryCodeChange(e.target.value)
																}
																onClick={() => { this.setState({ show_full_country_name: true }) }}
																onBlur={() => { this.setState({ show_full_country_name: false }) }}
															>
																{this.state.countriesList.map((country, i) => (
																	<option
																		key={i}
																		value={country.dial_code}
																		onClick={() => { this.setState({ show_full_country_name: false }) }}
																	>
																		{this.state.show_full_country_name
																			? `${country.flag} ${country.dial_code} ${country.name}`
																			: `${country.flag} ${country.dial_code}`}
																	</option>
																))}
															</select>
															<input type="text" className={this.state.isEmail ? 'form-control' : 'form-control p-cstm'} id="mobile_number" value={this.state.mobile_number} placeholder="Enter Email/ Phone number" name="mobile_number" onChange={this.handleInputChange} /></div> : <input type="text" className="form-control " id="email" placeholder="Enter Email/ Phone number" name="email" onChange={this.handleInputChange} value={this.state.email} />)}
														<p className="error">{this.state.email_error}</p>
													</div>

													<div className="form-group eyepass">
														{/* <label htmlFor="pwd">Password:</label> */}
														<input type={this.state.passwordVisible ? "text" : "password"} className="form-control" id="pwd" placeholder="Enter Password" name="pwd" onChange={this.changehandle} />
														<div className="view-pass" onClick={this.handleViewPassword}>
															{this.state.passwordVisible ? (
																<svg id="Capa_1" enableBackground="new 0 0 512 512" style={{ width: '25px', height: '25px' }} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m436.193 169.626c-5.507-4.735-11.04-9.258-16.589-13.59l-64.701 64.701c3.942 11.027 6.097 22.898 6.097 35.263 0 57.897-47.103 105-105 105-12.365 0-24.236-2.155-35.263-6.098l-48.558 48.558c28.356 11.632 56.481 17.54 83.821 17.54 32.657 0 66.432-8.396 100.384-24.955 26.662-13.005 53.514-31.063 79.809-53.671 44.455-38.226 71.841-76.024 72.984-77.615 3.765-5.232 3.765-12.285 0-17.518-1.144-1.591-28.529-39.389-72.984-77.615z" /><path d="m256 331c41.355 0 75-33.645 75-75 0-3.598-.27-7.134-.763-10.598l-84.835 84.835c3.465.493 7 .763 10.598.763z" /><path d="m507.607 4.394c-5.857-5.857-15.355-5.857-21.213 0l-117.848 117.848c-4.056-2.208-8.111-4.311-12.162-6.286-33.952-16.56-67.727-24.956-100.384-24.956s-66.432 8.396-100.384 24.955c-26.662 13.005-53.514 31.063-79.809 53.671-44.454 38.226-71.84 76.024-72.984 77.615-3.765 5.232-3.765 12.285 0 17.518 1.144 1.591 28.529 39.39 72.984 77.615 13.623 11.713 27.396 22.192 41.214 31.391l-112.627 112.629c-5.858 5.857-5.858 15.355 0 21.213 2.929 2.928 6.767 4.393 10.606 4.393s7.678-1.465 10.606-4.394l482-482c5.859-5.857 5.859-15.355.001-21.212zm-356.607 251.606c0-57.897 47.103-105 105-105 23.551 0 45.315 7.794 62.85 20.938l-21.52 21.52c-11.859-7.864-26.065-12.458-41.33-12.458-41.355 0-75 33.645-75 75 0 15.264 4.594 29.47 12.458 41.33l-21.52 21.52c-13.144-17.535-20.938-39.299-20.938-62.85z" /></g></svg>
															) : (
																<svg id="Capa_2" enableBackground="new 0 0 461.312 461.312" style={{ width: '25px', height: '25px' }} viewBox="0 0 461.312 461.312" xmlns="http://www.w3.org/2000/svg"><g><path d="M230.656,156.416c-40.96,0-74.24,33.28-74.24,74.24s33.28,74.24,74.24,74.24s74.24-33.28,74.24-74.24 S271.616,156.416,230.656,156.416z M225.024,208.64c-9.216,0-16.896,7.68-16.896,16.896h-24.576 c0.512-23.04,18.944-41.472,41.472-41.472V208.64z" /></g><g><path d="M455.936,215.296c-25.088-31.232-114.688-133.12-225.28-133.12S30.464,184.064,5.376,215.296c-7.168,8.704-7.168,21.504,0,30.72c25.088,31.232,114.688,133.12,225.28,133.12s200.192-101.888,225.28-133.12C463.104,237.312,463.104,224.512,455.936,215.296z M230.656,338.176c-59.392,0-107.52-48.128-107.52-107.52s48.128-107.52,107.52-107.52s107.52,48.128,107.52,107.52S290.048,338.176,230.656,338.176z" /></g></svg>
															)}
														</div>
														<p className="error">{this.state.pwd_error}</p>
													</div>
													<div className="k-flex align-items-center checkBoxM">
														<div className="settings-feld form-feilds k-flex align-items-center ">
															<span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked"><input type="checkbox" onChange={this.update7DaysLogin} name="sevenDaysLogin" defaultValue="true" checked={this.state.sevenDaysLogin} /><span className="checkmark" /></label></span>
															<h6 className="card-title mb-0 mt-n1">Keep me signed in for 7 days</h6>
														</div>
														<div className="ml-auto recnet-verification-code">
															<a href={`/${this.state.ownerIdentifire}/agent-reset-password`} >Forgot your password?</a>
														</div>
													</div>
													<div className="text-center pt-8">
														{!this.state.showOtpLogin ?
															<button type="submit" className="btn-blue white-text btn-radius  px-10 py-4">Login</button>
															:
															<div className='d-flex align-item-center justify-content-center'>
																<button type="submit" className="btn-blue white-text btn-radius  px-10 py-4">Login</button>
																<span className='vr-line'></span>
																<button onClick={() => { this.sendToCommonLogin() }} className="otp-btn link-btn px-10 py-4">Login with OTP
																	<span className='d-inline-block pl-4' style={{ marginBottom: '5px' }}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 77.572 40.929">
																			<path id="Icon_open-arrow-thick-right" data-name="Icon open-arrow-thick-right" d="M34.108,0V13.643H-23V27.286H34.108V40.929L54.572,20.26Z" transform="translate(23)" fill="#0775ff" />
																		</svg>
																	</span>
																</button>
															</div>
														}
													</div>
													<div className="text-center pt-8 k-flex align-item-center justify-content-center">
														<button className="btn-social btnsocial-FB mr-5" onClick={(e) => this.windowopen(e, 'facebook')} >
															<span className="sLo-1">
																<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 32 32" version={1}>
																	<path fill="#FFFFFF" d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z" />
																	<path fill="#4267b2" d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z" />
																</svg>
															</span>
															<p>Login as facebook</p>
														</button>
														<button className="btn-social btnsocial-gp" onClick={(e) => this.windowopen(e, 'google')} >
															<span className="sLo-2">
																<svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
																	<defs><path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" /></defs><clipPath id="b"><use xlinkHref="#a" overflow="visible" /></clipPath><path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" /><path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" /><path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" /><path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
																</svg>
															</span>
															<p>Login as Google</p></button>
													</div>



												</form>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					{this.state.deactivateAccountShow ? (<div id="shadowMasking" className="popupShadow" style={{ display: 'block' }}></div>) : (null)}
					{this.state.deactivateAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
						<div className="popupHeader py-4 px-5 k-flex align-items-center">
							<h6 className="card-title mb-0 white-text">Confirm</h6>
							<span className="close-popup ml-auto cursor-pointer" onClick={this.cancelReactivateAccount}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
						</div>
						<div className="popupBody py-5 px-5 my-4 mx-4">
							<div className="pb-5 feilds-instruction">
								<p className="card-body-text pb-4 text-black">Your account is deactivated. Do you want to reactivate your account?</p>
							</div>
							<div>
								<button className="btnBlueSettings mr-2" onClick={this.confirmReactivateAccount}>Yes</button>
								<button className="btnWhiteSettings" onClick={this.cancelReactivateAccount}>No</button>
							</div>
						</div>
					</div>) : (null)}
				</div>

				{this.state.cinfirmLogin === "showmdel" && (<div className="verification-popup">
					<div className="cstmpopup">
						<div className="popup-header">
							<strong>Confirm</strong>
							<span onClick={this.closeConfirmLogIn} className="close-icon-cstm"></span>
						</div>
						<div className="popup-body">
							<div className="input-control">
								<label>You are already logged in to a session. You will be logged out from that session.</label>
								<div className="text-center">
									<span onClick={() => this.confirmLogIn("yes")} className="btn btn-primary cstm-input-resend">Continue</span>
									<span className="btn btn-primary cstm-input-resend" onClick={() => this.confirmLogIn("no")}>Cancel</span>
								</div>
							</div>
						</div>
					</div>
				</div>)}


				{/* To show Masking div start from here */}
				{this.state.showForcePasswordBox || this.state.isEmailVerified === false || this.state.isMFAEnabled === true ? (<div id="shadowMasking" className="popupShadow" style={{ display: 'block' }}></div>) : (null)}
				{/* To show Masking div start end here */}

				{/* To set New password after login first time start here */}
				{this.state.showForcePasswordBox ? (<div className="popupInstallLivechat popupLivechat">
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
						<h6 className="card-title mb-0 white-text">Change Password</h6>
						<span className="close-popup ml-auto" onClick={() => this.closePasswordChangeBox()} >
							<svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
								<path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
								<g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
									<rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
									<rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
								</g>
							</svg>
						</span>
					</div>
					<div className="popupBody pb-5 px-5">
						<div className="py-5 feilds-instruction">
							<h6 className="card-title mb-0">Enter Old Password</h6>
							<input type="password" placeholder="*********" id="old_password" name="old_password" onChange={(e) => this.onChangeHandler(e)} value={this.state.old_password} style={{ border: this.state.old_password_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} />
						</div>
						<div className="pb-5 feilds-instruction">
							<h6 className="card-title mb-0">Enter New password</h6>
							<input type="password" placeholder="*********" id="new_password" name="new_password" onChange={(e) => this.onChangeHandler(e)} value={this.state.new_password} style={{ border: this.state.new_password_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} />
						</div>
						<div>
							<button onClick={() => this.updateNewPassword()} className="btnBlueSettings">Update </button>
							&nbsp;
							<button className="btnWhiteSettings mr-2" onClick={() => this.closePasswordChangeBox()}>Cancel</button>
						</div>
					</div>
				</div>) : (null)}
				{/* To set New password after login first time end here */}


				{this.state.isEmailVerified === false ? (<div className="popupInstallLivechat popupLivechat">
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
						<h6 className="card-title mb-0 white-text">Verify your email</h6>
						<span className="close-popup ml-auto" onClick={() => this.setState({ isEmailVerified: true })} >
							<svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
								<path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
								<g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
									<rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
									<rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
								</g>
							</svg>
						</span>
					</div>
					<div className="popupBody pb-5 px-5">
						<div className="py-5 feilds-instruction">
							<h6 className="card-title mb-0">Enter OTP</h6>
							<input type="password" placeholder="*********" id="email_otp" name="email_otp" onChange={(e) => this.onChangeHandler(e)} value={this.state.email_otp} style={{ border: this.state.email_otp_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} />
							{this.state.email_otp_error !== '' ? (<p className="error">{this.state.email_otp_error}</p>) : (null)}
						</div>
						<div>
							<button className="btnBlueSettings" onClick={this.verifyEmailAws}>Verify </button>
							&nbsp;
							<button className="btnWhiteSettings mr-2" onClick={() => this.setState({ isEmailVerified: true })}>Cancel</button>

							<button className="btnBlueSettings" onClick={this.resendEmailOTP}>Resend OTP </button>
						</div>
					</div>
				</div>) : (null)}

				{this.state.isMFAEnabled === true ? (<div className="popupInstallLivechat popupLivechat">
					<div className="popupHeader py-4 px-5 k-flex align-items-center">
						<h6 className="card-title mb-0 white-text">Two step verification</h6>
						<span className="close-popup ml-auto" onClick={() => this.setState({ isMFAEnabled: false })} >
							<svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
								<path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
								<g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
									<rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
									<rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
								</g>
							</svg>
						</span>
					</div>
					<div className="popupBody pb-5 px-5">
						<div className="py-5 feilds-instruction">
							<h6 className="card-title mb-0">Enter Google Authenticater OTP</h6>
							<input type="password" placeholder="*********" id="mfa_otp" name="mfa_otp" onChange={(e) => this.onChangeHandler(e)} value={this.state.mfa_otp} style={{ border: this.state.mfa_otp_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} />
							{this.state.mfa_otp_error !== '' ? (<p className="error">{this.state.mfa_otp_error}</p>) : (null)}
						</div>
						<div>
							<button className="btnBlueSettings" onClick={this.verifyMFA}>Verify </button>
							&nbsp;
							<button className="btnWhiteSettings mr-2" onClick={() => this.setState({ isMFAEnabled: false })}>Cancel</button>
						</div>
					</div>
				</div>) : (null)}
			</>
		)
	}
}

export default Login;



