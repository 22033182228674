import React from "react";
import ReactTooltip from "react-tooltip";
// import db from '../../../config/firebase';
import { toast } from "react-toastify";
import { mailUrl } from "../../../config/mailFunctions.js";
import "@lottiefiles/lottie-player";
import Device from "react-device";
import {
  MAIL_FUNCTION_URL,
  DOMAIN_NAME,
  WIDGET_DOMAIN,
  PAYMENT_CHECKOUT_URL,
  currencyData,
  FB_APP_ID,
  API_VERSION,
  GATEWAY_URL,
  FUNCTION_API_KEY,
  ACCOUNT_ID,
  ACCOUNT_BOT_ID,
} from "../../../config/siteConfig";
import { activityLog, logActivityInFirestore } from "../../../services/service";
import { checkPaymentStatus } from "../../../services/payment";

import LeftPanel from "./LeftPanel";
import moment from "moment-timezone";
///import { getDateInUTC } from "../../livechat/components/Comman";
import queryString from "query-string";
import PaymentForm from "./paymentForm";

import TextField from "@material-ui/core/TextField";
import firebaseServices from "../../../firebase";

const publicIp = require("public-ip");

var youtubeVideos = {
  wordpress: "02lzuhgUtNY",
  shopify: "U9KyTKUo700",
  magento: "",
  wix: "XsVR7kl09dc",
  squarespace: "itjTnH6FomE",
  weebly: "AzLo9kw9eDo",
  godaddy: "bTBVaGO7fiQ",
  webflow: "",
};

var docLinks = {
  wordpress:
    "https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-wordpress",
  shopify:
    "https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-shopify",
  magento: "https://www.appypie.com",
  wix: "https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-wix",
  squarespace:
    "https://www.appypie.com/faqs/how-to-deploy-appy-pie-livechat-s-widget-in-squarespace-website",
  weebly:
    "https://www.appypie.com/faqs/how-to-deploy-livechat-s-widget-in-a-website-created-in-weebly",
  godaddy:
    "https://www.appypie.com/faqs/how-to-deploy-livechat-s-widget-in-a-website-created-in-godaddy",
  webflow: "https://www.appypie.com",
};

class Testsetup extends React.Component {
  instaPagesSnapshot = null;

  constructor() {
    super();
    this.agent = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    this.state = {
      ownerId: "",
      chatBot: "",
      developerEmail: "",
      instructions:
        "We wish to start using Appy Pie Livechat and I want you to install its widget code, enclosed in this email along with instructions, in our website.",
      email_error: "",
      loader: false,
      openEmailBox: false,
      userCollection: "",
      verified: "",
      allDomains: [],
      currentWebsite: "",
      successMessage: false,
      domainCollection: "",
      liveChatUrl: "",
      filePath: window.location.origin + "/lf20_sTumYD.json",
      UA: "",
      myBrowser: "",
      OS: "",
      isDevice: false,
      ownerEmail: "",
      ownername: "",
      IPv4: "",
      agentName: "",
      showType: 0,
      widgetOnCustomWebsite: false,
      selectedCMS: "",
      showTroubleShoot: true,
      setup_guide: true,
      setup_guide_step: 0,
      selectedPlanType: 0,
      plan: "",
      modalVisible: false,
      billing_cycle: "",
      amount: "",
      agents: "",
      chats_completed: "",
      next_billing_date: "",
      billing_information: "",
      plan_data: [],
      loader: false,
      billingList: "",
      no_of_agent: 1,
      requestKey: "",
      errormessage: "",
      currency: "$",
      errormessage: "",
      planprice: "",
      totalprice: "",
      payment_list: [],
      historyCount: 0,
      history_link: "",
      owner_id: "",
      company_name: "",
      owner_email: "",
      current_plan_id: "",
      current_plan_period: "",
      current_agent: 1,
      commonlogin_user_id: "",
      payment_status: "",
      payment_enabled: 0,
      chat_count: 1,
      currencyData: currencyData,
      show_currency: "",
      country_code: "US",
      plan_list: [],
      planPriceYearly: 84,
      planPriceMonthly: 10,
      planPriceDiscount: 3,
      showGoToDashboard: true,
      paymentStatus: "",
      consumer_key: "",
      consumer_secret: "",
      access_token: "",
      access_secret: "",
      error_consumer_key: "",
      error_consumer_secret: "",
      error_access_token: "",
      error_access_secret: "",
      twitterTokens: {},
      error_twitter_dm: "",
      success_twitter_dm: "",
      pageList: [],
      page_token: {},
      show_old_free_plan: true,
      instaPagesList: [],
      showIOSTab: true,
      widgetUrl: "",
      widgetUrl_old: "",
    };
  }

  createAutoChat = () => {
    const urlValues = queryString.parse(window.location.search);

    let plan_type = urlValues.ptype ? urlValues.ptype : "";
    //let current_plan_id = urlValues.cpId ? urlValues.cpId : "";
    let isTrial = "";
    if (plan_type === "paid") {
      isTrial = "no";
    } else if (plan_type === "trial") {
      isTrial = "yes";
    }

    var user_email = "";
    if (this.agent.ownerEmail) {
      user_email = this.agent.ownerEmail;
    } else if (
      this.agent.notification_email &&
      this.agent.notification_email.length > 0 &&
      this.agent.notification_email[0]
    ) {
      user_email = this.agent.notification_email[0];
    }

    try {
      var visitorId = "";
      var variableName = "ClIENTID_" + ACCOUNT_BOT_ID + "=";
      var documntCookie = unescape(document.cookie);
      var decodedCookie = decodeURIComponent(documntCookie);
      var ca = decodedCookie.split(";");

      for (var kp = 0; kp < ca.length; kp++) {
        var c = ca[kp];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(variableName) === 0) {
          visitorId = c.substring(variableName.length, c.length);
        }
      }

      if (ACCOUNT_ID && visitorId) {
        let query =
          this.agent.name +
          " has subscribed Premium plan for his Livechat app. Below are the subscription details- Company name: " +
          this.agent.company_name +
          ", Account id: " +
          this.agent.ownerId +
          ", Subscription Plan: Premium";
        let clientName = encodeURIComponent(this.agent.name);
        let email = encodeURIComponent(user_email);
        let phone = "";
        let planName = encodeURIComponent("Premium");
        let appName = encodeURIComponent(this.agent.company_name);
        let appId = encodeURIComponent(this.agent.ownerId);
        let client_query = encodeURIComponent(query);
        var apiData = [
          "companyId=" +
            ACCOUNT_ID +
            "&visitorId=" +
            visitorId +
            "&name=" +
            clientName +
            "&email=" +
            email +
            "&phone=" +
            phone +
            "&client_query=" +
            client_query +
            "&planName=" +
            planName +
            "&appName=" +
            appName +
            "&appId=" +
            appId +
            "&isTrial=" +
            isTrial,
        ];
        fetch(MAIL_FUNCTION_URL + "/autoStartVisitorChat", {
          method: "POST",
          body: apiData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }).then((resp) => {});
      }
    } catch (err) {}
  };

  async componentDidMount() {
    const urlValues = queryString.parse(window.location.search);
    console.log("urlValues hii >>> ", urlValues);
    let urlType = urlValues.type ? urlValues.type : "";
    if (urlType === "success") {
      this.createAutoChat();
      this.setState({
        showType: 5,
      });
    }
    // if (urlType === 'success' || this.state.showType === 5) {
    let script = document.createElement("script");
    script.id = "calendly-main-script";
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    document.getElementsByTagName("head")[0].appendChild(script);
    window.addEventListener("message", (e) => {
      if (this.isCalendlyEvent(e)) {
        console.log(e.data.event);
        if (e.data.event && e.data.event === "calendly.event_scheduled") {
          this.setState({
            showGoToDashboard: false,
          });
        }
      }
    });
    // }

    this.getFbPages();
    this.getInstagramPages();

    var body = document.getElementById("root");
    if (body) {
      body.addEventListener("click", this.hideTourPopup, false);
    }
    let userDetails = JSON.parse(localStorage.getItem("agent"));

    this.setState(
      {
        ownerId: userDetails.ownerId,
        loader: true,
        liveChatUrl: "/" + userDetails.ownerIdentifire,
      },
      async () => {
        this.getWidget();
        // this.getBotId();
        this.getDomainsList();
        this.getWidgetScript();
        await this.getOwnerDetails_OLD();
      }
    );
    publicIp.v4().then((ip4) => {
      this.setState({
        IPv4: ip4,
      });
    });
    this.getVisitorGuide(userDetails);
    this.setState({
      loader: false,
    });
    this.getTwitterTokens();
    activityLog("Opened setup page");
  }

  getFbPages = () => {
    this.pagesSnapshot = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("fb_pages")
      .onSnapshot(async (querySnapshot) => {
        let pageList = [];
        let pagetoken = {};
        querySnapshot.forEach((doc) => {
          const {
            addedon,
            page_category,
            page_name,
            page_token,
            recipient_id,
            subscribe,
          } = doc.data();
          pagetoken[recipient_id] = page_token;
          let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
          pageList.push({
            key: doc.id,
            addedon: addDate,
            page_category,
            page_name,
            page_token,
            recipient_id,
            subscribe,
          });
        });

        if (Object.keys(pagetoken).length > 0) {
          var loggedInAgent = this.agent;
          loggedInAgent.fb_page_token = pagetoken;
          this.agent = loggedInAgent;
          localStorage.setItem("agent", JSON.stringify(loggedInAgent));
        }

        this.setState({ page_token: pagetoken, pageList: pageList }, () => {
          this.setState({ loader: false });
        });
      });
  };

  isCalendlyEvent = (e) => {
    return e.data.event && e.data.event.indexOf("calendly") === 0;
  };

  hideTourPopup = (e) => {
    let ePath = e.path || (e.composedPath && e.composedPath());
    let hidePopup = true;
    if (ePath) {
      ePath.forEach((path) => {
        if (
          path.className &&
          (path.className === "tour_guide" || path.id === "tour_guide")
        ) {
          hidePopup = false;
        }
      });
    }
    if (
      hidePopup === true &&
      this.state.setup_guide === false &&
      this.state.openEmailBox === false &&
      this.state.showType === 2
    ) {
      this.endGuideTour("setup", "setup_guide");
    }
  };

  getVisitorGuide = (agent2) => {
    firebaseServices.db
      .collection("users")
      .doc(agent2.ownerId)
      .collection("onboarding_guide")
      .doc("setup")
      .get()
      .then((getRsult) => {
        console.log("getRsult>>>  ", getRsult);
        if (getRsult.exists) {
          let _dataResult = getRsult.data();
          console.log("_dataResult >>> ", _dataResult);
          this.setState({
            setup_guide: _dataResult.status,
            setup_guide_step: _dataResult.step ? _dataResult.step : 0,
          });
        }
      });
  };

  getOwnerDetails_OLD = async () => {
    let ownerDetails = await firebaseServices.db
      .collection("users")
      .doc(this.state.ownerId)
      .onSnapshot(async (docs) => {
        if (docs.exists) {
          let paymentStatus = await checkPaymentStatus(docs.data());
          this.setState({
            ownerEmail: docs.data().email,
            ownername: docs.data().company_name,
            // show_old_free_plan: false,
            // {/*docs.data().show_old_free_plan === true && docs.data().no_chat_served < 500 ? true : false */},
            paymentStatus: paymentStatus,
          });
          // ownerDetails();
        }
      });

    firebaseServices.db
      .collection("users")
      .doc(this.state.ownerId)
      .collection("agents")
      .where("roleType", "==", "Owner")
      .get()
      .then((datas) => {
        if (datas.empty === false) {
          let agentName = "";
          datas.forEach((doc) => {
            agentName = doc.data().name;
          });
          this.setState({
            agentName: agentName,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangee = (deviceInfo) => {
    this.setState({
      UA: deviceInfo.ua,
      myBrowser: deviceInfo.browser.name,
      OS: deviceInfo.os.name,
    });
  };

  getWidget = () => {
    this.state.userCollection = firebaseServices.db
      .collection("users")
      .doc(this.state.ownerId)
      .onSnapshot((docs) => {
        if (docs.exists) {
          let data = docs.data();
          this.setState({
            verified: data.widget_status,
          });
          if (
            (data.verified === undefined || data.verified === false) &&
            data.widget_status === true
          ) {
            //// Updated By AG Start /////////
            /*this.setState({
                        successMessage:true
                    })*/
            firebaseServices.db
              .collection("users")
              .doc(this.state.ownerId)
              .update({ verified: true })
              .then(() => {
                let userDetails = JSON.parse(localStorage.getItem("agent"));
                window.location.href =
                  "/" + userDetails.ownerIdentifire + "/thankyou";
                return false;
              });

            //// Updated By AG End /////////
          }
        }
      });
  };

  getBotId = () => {
    var defBot = firebaseServices.db
      .collection("users")
      .doc(this.state.ownerId)
      .collection("chatbots")
      .where("status", "==", 1)
      .onSnapshot((chDocs) => {
        if (!chDocs.empty) {
          let defaultChatBot = "";
          chDocs.forEach((doc) => {
            defaultChatBot = doc.id;
          });
          this.setState({
            chatBot: defaultChatBot,
            widgetUrl_old: `<script id="appyWidget" src="${WIDGET_DOMAIN}/widget/build.js?cid=${this.state.ownerId}-${defaultChatBot}"></script>`,
          });
          defBot();
        }
      });
  };

  getWidgetScript = () => {
    var defBot = firebaseServices.db
      .collection("users")
      .doc(this.state.ownerId)
      .collection("chatbots")
      .where("status", "==", 1)
      .onSnapshot((chDocs) => {
        if (!chDocs.empty) {
          let defaultChatBot = "";
          chDocs.forEach((doc) => {
            defaultChatBot = doc.id;
          });
          this.setState({
            chatBot: defaultChatBot,
            widgetUrl: ` <script id="appyWidget" src="${WIDGET_DOMAIN}/widget/buildone.js?cid=${this.state.ownerId}-${defaultChatBot}"></script>`,
          });
          defBot();
        }
      });
  };

  getDomainsList = () => {
    this.state.domainCollection = firebaseServices.db
      .collection("reports")
      .doc("widget")
      .collection("installation")
      .doc(this.state.ownerId)
      .collection("domains")
      .onSnapshot((domains) => {
        if (!domains.empty) {
          let allDomains = [];
          domains.forEach((domain) => {
            allDomains.push(domain.data().domain);
          });
          this.setState({
            allDomains: allDomains,
          });
        }
      });
  };

  componentWillUnmount() {
    this.state.userCollection();
    this.state.domainCollection();
    try {
      this.pagesSnapshot();
      this.instaPagesSnapshot();
    } catch (errrr) {}
    let getDiv = document.getElementById("calendly-main-script");
    console.log(getDiv);
    try {
      getDiv.remove();
    } catch (err) {}
  }

  installMessenger = () => {
    let domain_name = DOMAIN_NAME;
    if (window.location.hostname === "localhost") {
      domain_name = "http://localhost:3000";
    }
    let cbUrl = domain_name + "/cb-fb-messenger/pagesetup";
    let state22 = Math.random().toString(36).substring(7);
    let params = `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=900,height=500,left=100,top=100`;
    window.open(
      "https://www.facebook.com/v" +
        API_VERSION +
        "/dialog/oauth?response_type=code&client_id=" +
        FB_APP_ID +
        "&redirect_uri=" +
        cbUrl +
        "&state=" +
        state22 +
        "&scope=pages_messaging,pages_show_list,public_profile,email,pages_read_engagement,pages_manage_metadata",
      "Facebook Messenger",
      params
    );
  };

  copyCode = (e) => {
    var copyText = document.getElementById("myInputCopy");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    // if (document.selection) { //IE
    //     var range = document.body.createTextRange();
    //     range.moveToElementText(document.getElementById('widgetCode'));
    //     range.select();
    // } else if (window.getSelection) { //others
    //     var range = document.createRange();
    //     range.selectNode(document.getElementById('widgetCode'));
    //     window.getSelection().addRange(range);
    // }
    // var copyText = document.getElementById("widgetCode");

    // /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    // /* Copy the text inside the text field */
    // document.execCommand("copy");
    // document.execCommand('copy');
    // window.getSelection().empty();
    toast.success("Code Copied!");
    this.guidenextStep("setup", 1, "setup_guide_step");
  };

  copyToClipboard = (textToCopy, num) => {
    console.log(num);
    let selectCopyText = document.getElementById(`frameSelect${num}`);
    const range = document.createRange();
    range.selectNode(selectCopyText);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      toast.success("Code Copied!");
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      toast.success("Code Copied!");
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  sendEmail = (body = "", showToast = true) => {
    let mailType = "widget_setup";
    let email = this.state.developerEmail;
    let instruction = this.state.instructions;
    let ownerEmail = this.agent.ownerEmail
      ? this.agent.ownerEmail
      : this.state.ownerEmail;
    let urlToSend =
      'id="appyWidget" src="' +
      WIDGET_DOMAIN +
      "/widget/build.js?cid=" +
      this.state.ownerId +
      "-" +
      this.state.chatBot +
      '"';
    if (body === "") {
      body = [
        `URL=${urlToSend}&instruction=${instruction}&email=${email}&type=${mailType}&ownername=${this.state.ownername}&ownerEmail=${ownerEmail}&ownerAgentName=${this.state.agentName}`,
      ];
    }
    fetch(GATEWAY_URL + "/sendEmail", {
      method: "post",
      body: body,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then()
      .then((response) => {
        this.closeEmailBox();
        this.setState(
          {
            loader: false,
          },
          () => {
            if (showToast) {
              toast.success("Email has been sent to developer email id");
            }
          }
        );
      })
      .catch((e) => {
        this.setState({
          loader: false,
        });
        console.log(e, "ERROR");
      });
  };

  sendEmailDeveloper = () => {
    let validate = this.validateForm();
    if (this.state.email_error === "" && validate) {
      this.setState({
        loader: true,
      });
      this.sendEmail();
      activityLog("Sent instructions to developer");
    }
  };

  validateForm = () => {
    let returnValue = true;
    if (this.state.developerEmail === "") {
      returnValue = false;
      this.setState({
        email_error: "Email can not be blank",
      });
    }
    return returnValue;
  };

  handleDeveloperMessage = (e) => {
    this.setState({
      instructions: e.target.value,
    });
  };

  validateEmail = (email) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  onChangeHandler = (e) => {
    let email = e.target.value;
    if (!this.validateEmail(email)) {
      this.setState({
        email_error: "Incorrect Email",
        developerEmail: email,
      });
    } else {
      this.setState({
        email_error: "",
        developerEmail: email,
      });
    }
  };

  openEmailBox = () => {
    this.setState({
      openEmailBox: true,
    });
    this.guidenextStep("setup", 2, "setup_guide_step");
  };

  validateURL = (e) => {
    let returnValue = true;
    if (this.state.currentWebsite === "") {
      returnValue = false;
      this.setState({
        url_error: "Please Enter a Valid URL!",
      });
    } else if (
      this.state.currentWebsite.match(
        /(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      ) === null &&
      this.state.currentWebsite.match(/(http(s)?:\/\/.)?(localhost)/g) === null
    ) {
      returnValue = false;
      this.setState({
        url_error: "Please Enter a Valid URL!",
      });
    } else {
      this.setState({
        url_error: "",
      });
    }
    return returnValue;
  };

  verifyWebsite = () => {
    let validate = this.validateURL();
    if (this.state.currentWebsite !== "" && validate) {
      firebaseServices.db
        .collection("reports")
        .doc("widget")
        .collection("installation")
        .doc(this.state.ownerId)
        .collection("domains")
        .where("domain", "==", this.state.currentWebsite)
        .get()
        .then((docs) => {
          if (docs.empty === true) {
            let ownerEmail = this.agent.ownerEmail
              ? this.agent.ownerEmail
              : this.state.ownerEmail;
            var emailBody = [
              `email=${ownerEmail.toLowerCase()}&&type=widget_not_installed&&vip=${
                this.state.IPv4
              }&&vsystem=${this.state.UA}&&currnt_url=${DOMAIN_NAME}&&check=` +
                true,
            ];
            this.sendEmail(emailBody, false);
            toast.error("Widget not installed on this domain");
          } else {
            toast.success(
              "Widget has been successfully installed on the mentioned website"
            );
          }
        });
    }
  };

  handleChange = (e) => {
    let target = e.target;
    this.setState({
      [target.name]: target.value,
    });
  };

  closeEmailBox = () => {
    this.setState({
      openEmailBox: false,
      developerEmail: "",
      email_error: "",
      instructions:
        "We wish to start using Appy Pie Livechat and I want you to install its widget code, enclosed in this email along with instructions, in our website.",
    });
  };

  hideSuceesMessage = () => {
    /*window.location.href = this.state.liveChatUrl;
        this.setState({
            successMessage:false
        }); */
  };

  endGuideTour = (docId, context) => {
    firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("onboarding_guide")
      .doc(docId)
      .update({
        status: true,
      });
    this.setState({
      [context]: true,
    });
  };

  guidenextStep = (docId, step, context) => {
    firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("onboarding_guide")
      .doc(docId)
      .update({
        step: step,
      });
    this.setState({
      [context]: step,
    });
  };

  openWidget = () => {
    var x = document
      .getElementById("Appypie")
      .contentWindow.document.getElementsByClassName("iconBotdynamic");
    if (x && x[0]) {
      x[0].click();
    }
  };

  openPopup = () => {
    var billingData = this.state.billing_information.split("_");
    var agent = this.state.no_of_agent;
    if (
      parseInt(this.state.no_of_agent) <= parseInt(this.state.current_agent) ||
      this.state.no_of_agent === ""
    ) {
      agent = parseInt(this.state.current_agent) + 1;
    }
    var netprice = parseInt(billingData[4]) * parseInt(agent);
    this.setState({
      totalprice: netprice,
      no_of_agent: agent,
      modalVisible: true,
      errormessage: "",
    });
    logActivityInFirestore(
      "Subscription upgrade button clicked",
      "upgrade_action_btn"
    );
  };

  goToDashboard = () => {
    this.props.props.history.push("/" + this.agent.ownerIdentifire);
  };

  goToTab = (showType) => {
    const urlValues = queryString.parse(window.location.search);
    console.log("urlValues >>> ", urlValues.type);
    let urlType = urlValues.type ? urlValues.type : "";
    if (urlType === "success") {
      this.props.props.history.push(
        "/" + this.agent.ownerIdentifire + "/install-widget"
      );
      this.setState({
        showType: showType,
      });
    } else {
      this.setState({
        showType: showType,
      });
    }
  };

  selectPlanType = (planType) => {
    if (this.state.plan_list.length > 1) {
      let priceData = this.state.plan_data;
      let billing_information = document.getElementById(
        "billing_information"
      ).value;
      let billArray = billing_information.split("_");
      let planPrice;

      if (planType === 0) {
        billing_information =
          priceData[1].productName +
          "_" +
          priceData[1].planId +
          "_" +
          priceData[1].planPeriod +
          "_" +
          billArray[3] +
          "_" +
          priceData[1].planPrice;
        planPrice = priceData[1].planPrice;
      } else {
        billing_information =
          priceData[0].productName +
          "_" +
          priceData[0].planId +
          "_" +
          priceData[0].planPeriod +
          "_" +
          billArray[3] +
          "_" +
          priceData[0].planPrice;
        planPrice = priceData[0].planPrice;
      }

      if (this.state.no_of_agent == "") {
        var netprice = parseInt(planPrice);
        this.setState({
          billing_information: billing_information,
          planprice: planPrice,
          totalprice: netprice,
          selectedPlanType: planType,
        });
      } else {
        var netprice = parseInt(planPrice) * parseInt(this.state.no_of_agent);
        this.setState({
          billing_information: billing_information,
          planprice: planPrice,
          totalprice: netprice,
          errormessage: "",
          selectedPlanType: planType,
        });
      }
    } else {
      this.setState({
        selectedPlanType: planType,
      });
    }
  };

  /* Twitter functions start */

  getTwitterTokens = () => {
    let tdmFrmData = { owner_id: this.agent.ownerId };
    //fetch(MAIL_FUNCTION_URL + "/twitter/gettokens", {
    fetch(GATEWAY_URL + "/gettokens", {
      method: "POST",
      body: JSON.stringify(tdmFrmData),
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "success") {
          if (Object.keys(responseJson.allTokens).length > 0) {
            this.setState({
              twitterTokens: responseJson.allTokens,
              consumer_key: responseJson.allTokens.consumer_key,
              consumer_secret: responseJson.allTokens.consumer_secret,
              access_token: responseJson.allTokens.access_token,
              access_secret: responseJson.allTokens.access_secret,
              loader: false,
            });
          }
        }
      });
  };

  handleTDMChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTDMFormSubmit = (e) => {
    e.preventDefault();

    let error_consumer_key = "";
    let error_consumer_secret = "";
    let error_access_token = "";
    let error_access_secret = "";

    if (this.state.consumer_key === "") {
      error_consumer_key = "Please enter consumer key!";
    }

    if (this.state.consumer_secret === "") {
      error_consumer_secret = "Please enter consumer secret!";
    }

    if (this.state.access_token === "") {
      error_access_token = "Please enter account token!";
    }

    if (this.state.access_secret === "") {
      error_access_secret = "Please enter account secret!";
    }

    if (
      error_consumer_key !== "" ||
      error_consumer_secret !== "" ||
      error_access_token !== "" ||
      error_access_secret !== ""
    ) {
      this.setState({
        error_consumer_key: error_consumer_key,
        error_consumer_secret: error_consumer_secret,
        error_access_token: error_access_token,
        error_access_secret: error_access_secret,
        error_twitter_dm: "",
        success_twitter_dm: "",
      });
      return false;
    }

    this.setState({ loader: true });
    let tdmFrmData = {
      owner_id: this.agent.ownerId,
      consumer_key: encodeURIComponent(this.state.consumer_key),
      consumer_secret: encodeURIComponent(this.state.consumer_secret),
      access_token: encodeURIComponent(this.state.access_token),
      access_secret: encodeURIComponent(this.state.access_secret),
    };
    //fetch(MAIL_FUNCTION_URL + "/twitter/subscribe", {
    fetch(GATEWAY_URL + "/subscribe", {
      method: "POST",
      body: JSON.stringify(tdmFrmData),
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "success") {
          this.setState({
            error_twitter_dm: "",
            success_twitter_dm: "Live Chat now connected to Twitter.",
            error_consumer_key: "",
            error_consumer_secret: "",
            error_access_token: "",
            error_access_secret: "",
          });
        } else {
          this.setState({
            loader: false,
            error_twitter_dm:
              "Something went wrong. please check details once and submit again!",
            success_twitter_dm: "",
            error_consumer_key: "",
            error_consumer_secret: "",
            error_access_token: "",
            error_access_secret: "",
          });
        }
        setTimeout(() => {
          this.getTwitterTokens();
        }, 2000);
      });
  };

  disconnectTwitterDm = (e) => {
    this.setState({ loader: true });
    let tdmFrmData = {
      owner_id: this.agent.ownerId,
      consumer_key: encodeURIComponent(this.state.consumer_key),
      consumer_secret: encodeURIComponent(this.state.consumer_secret),
      access_token: encodeURIComponent(this.state.access_token),
      access_secret: encodeURIComponent(this.state.access_secret),
    };

    //fetch(MAIL_FUNCTION_URL + "/twitter/disconnectTDM", {
    fetch(GATEWAY_URL + "/disconnectTDM", {
      method: "POST",
      body: JSON.stringify(tdmFrmData),
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        Accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === "success") {
          this.setState({
            twitterTokens: null,
            consumer_key: "",
            consumer_secret: "",
            access_token: "",
            access_secret: "",
            loader: false,
            error_twitter_dm: "",
            success_twitter_dm:
              "Live Chat disconnected with twitter successfully.",
            error_consumer_key: "",
            error_consumer_secret: "",
            error_access_token: "",
            error_access_secret: "",
          });
        } else {
          this.setState({
            loader: false,
            error_twitter_dm: responseJson.message,
            success_twitter_dm: "",
            error_consumer_key: "",
            error_consumer_secret: "",
            error_access_token: "",
            error_access_secret: "",
          });
        }
      });
  };

  /* Twitter functions end */

  /* Facebook functions start */

  goToFacebook = () => {
    this.props.props.history.push(
      "/" + this.agent.ownerIdentifire + "/install-fb-messenger"
    );
  };

  connectDisconnectPage = (pageDetail) => {
    if (pageDetail.subscribe) {
      this.disconnectPage(pageDetail);
    } else {
      this.connectPage(pageDetail);
    }
  };

  disconnectPage = (pageDetail) => {
    this.setState({ loader: true });
    let recipientId = pageDetail.recipient_id;
    let pageToken = pageDetail.page_token
      ? encodeURIComponent(pageDetail.page_token)
      : "";
    let body22 = [
      `ownerId=${this.agent.ownerId}&pageId=${recipientId}&pageToken=${pageToken}`,
    ];
    //fetch(MAIL_FUNCTION_URL + "/facebook/disconnect", {
    fetch(GATEWAY_URL + "/disconnect", {
      method: "post",
      body: body22,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        this.setState({ loader: false });
      });
  };

  connectPage = (pageDetail) => {
    this.setState({ loader: true });
    let pageToken = pageDetail.page_token
      ? encodeURIComponent(pageDetail.page_token)
      : "";
    let body22 = [
      `ownerId=${this.agent.ownerId}&pageId=${pageDetail.recipient_id}&pageToken=${pageToken}`,
    ];
    //fetch(MAIL_FUNCTION_URL + "/facebook/connect", {
    fetch(GATEWAY_URL + "/connect", {
      method: "post",
      body: body22,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        this.setState({ loader: false });
      });
  };

  deletePage = () => {
    this.setState({ loader: true });
    let body22 = [`ownerId=${this.agent.ownerId}`];
    //fetch(MAIL_FUNCTION_URL + "/facebook/delete", {
    fetch(GATEWAY_URL + "/delete", {
      method: "post",
      body: body22,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        if (response.status === "success") {
          let agent22 = localStorage.getItem("agent")
            ? JSON.parse(localStorage.getItem("agent"))
            : {};
          agent22.fb_page_token = {};
          this.agent = agent22;
          localStorage.setItem("agent", JSON.stringify(agent22));
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
        }
      });
  };

  /* Facebook functions end */

  /* Instagram functions start */

  installInstaMessenger = () => {
    let domain_name = DOMAIN_NAME;
    if (window.location.hostname === "localhost") {
      domain_name = "http://localhost:3000";
    }
    let cbUrl = domain_name + "/cb-insta-messenger/pagesetup";
    let state22 = Math.random().toString(36).substring(7);
    let params = `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=900,height=500,left=100,top=100`;
    /*  window.open("https://www.facebook.com/v" + API_VERSION + "/dialog/oauth?response_type=code&client_id=" + FB_APP_ID + "&redirect_uri=" + cbUrl + "&state=" + state22 + "&scope=pages_messaging,pages_show_list,instagram_basic,instagram_manage_messages,public_profile,instagram_manage_insights,instagram_manage_comments,email,pages_read_engagement,pages_manage_metadata", "Instagram Messenger", params); */
    window.open(
      "https://www.facebook.com/v" +
        API_VERSION +
        "/dialog/oauth?response_type=code&client_id=" +
        FB_APP_ID +
        "&redirect_uri=" +
        cbUrl +
        "&state=" +
        state22 +
        "&scope=pages_messaging,pages_show_list,instagram_basic,instagram_manage_messages,public_profile,instagram_manage_insights,instagram_manage_comments,email,pages_read_engagement,pages_manage_metadata",
      "Instagram Messenger",
      params
    );
  };

  connectDisconnectInstaPage = (pageDetail) => {
    if (pageDetail.subscribe) {
      this.disconnectInstagramPage(pageDetail);
    } else {
      this.setState({ loader: true });
      let pageToken = pageDetail.page_token
        ? encodeURIComponent(pageDetail.page_token)
        : "";
      let body22 = [
        `ownerId=${this.agent.ownerId}&pageId=${pageDetail.recipient_id}&pageToken=${pageToken}`,
      ];
      //fetch(MAIL_FUNCTION_URL + "/facebook/ig_connect", {
      fetch(GATEWAY_URL + "/ig_connect", {
        method: "POST",
        body: body22,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          console.log("-----------------   response  ---------------------");
          console.log(response);
          this.setState({ loader: false });
          if (response.status === "error") {
            toast.error(response.message);
          }
        });
    }
  };

  getInstagramPages = () => {
    this.instaPagesSnapshot = firebaseServices.db
      .collection("users")
      .doc(this.agent.ownerId)
      .collection("instagram_pages")
      .onSnapshot(async (querySnapshot) => {
        let instaPagesList = [];
        let pagetoken = {};
        querySnapshot.forEach((doc) => {
          const {
            addedon,
            page_category,
            page_name,
            page_token,
            recipient_id,
            subscribe,
          } = doc.data();
          pagetoken[recipient_id] = page_token;
          let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
          instaPagesList.push({
            key: doc.id,
            addedon: addDate,
            page_category,
            page_name,
            page_token,
            recipient_id,
            subscribe,
          });
        });

        if (Object.keys(pagetoken).length > 0) {
          var loggedInAgent = this.agent;
          loggedInAgent.insta_pages_token = pagetoken;
          this.agent = loggedInAgent;
          localStorage.setItem("agent", JSON.stringify(loggedInAgent));
        }

        this.setState({ instaPagesList: instaPagesList }, () => {
          this.setState({ loader: false });
        });
      });
  };

  deleteInstaPage = () => {
    this.setState({ loader: true });
    let body22 = [`ownerId=${this.agent.ownerId}`];
    //fetch(MAIL_FUNCTION_URL + "/facebook/ig_delete", {
    fetch(GATEWAY_URL + "/ig_delete", {
      method: "POST",
      body: body22,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        if (response.status === "success") {
          let agent22 = localStorage.getItem("agent")
            ? JSON.parse(localStorage.getItem("agent"))
            : {};
          agent22.insta_pages_token = {};
          this.agent = agent22;
          localStorage.setItem("agent", JSON.stringify(agent22));
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
        }
      });
  };

  disconnectInstagramPage = (pageDetail) => {
    this.setState({ loader: true });
    let recipientId = pageDetail.recipient_id;
    let pageToken = pageDetail.page_token
      ? encodeURIComponent(pageDetail.page_token)
      : "";
    let body22 = [
      `ownerId=${this.agent.ownerId}&pageId=${recipientId}&pageToken=${pageToken}`,
    ];
    //fetch(MAIL_FUNCTION_URL + "/facebook/ig_disconnect", {
    fetch(GATEWAY_URL + "/ig_disconnect", {
      method: "post",
      body: body22,
      headers: {
        "X-API-Key": FUNCTION_API_KEY,
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Origin: window.location.origin,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        this.setState({ loader: false });
      });
  };

  /* Instagram functions end */

  render() {
    let widgetInstalledMessage;
    if (this.state.verified === true) {
      widgetInstalledMessage = (
        <span className="pl-4">
          <img
            alt=""
            src={require("../../../assets/img/icon/checked.png")}
            className="widgetWarningImage"
          />
          <span className="widgetsucess">Widget installed successfully</span>
        </span>
      );
    } else if (this.state.verified === false) {
      widgetInstalledMessage = (
        <span className="pl-4">
          <img
            alt=""
            src={require("../../../assets/images/danger.png")}
            className="widgetWarningImage"
          />
          <span className="widgetWarning">Widget hasn’t been implemented</span>
        </span>
      );
    }

    let widgetMessages;
    if (this.state.verified === true) {
      widgetMessages = (
        <span className="k-flex" style={{ alignItems: "center" }}>
          <img
            alt=""
            src={require("../../../assets/img/icon/checked.png")}
            className="widgetWarningImage"
          />
          <span className="pl-3">
            Widget has been successfully installed on the mentioned website
          </span>
        </span>
      );
    } else if (this.state.verified === false) {
      widgetMessages = (
        <span>
          <img
            alt=""
            src={require("../../../assets/images/danger.png")}
            className="widgetWarningImage"
          />
          <span className="widgetWarning no-border">
            Widget hasn’t been implemented
          </span>
        </span>
      );
    }

    let currentSelectedCMS = this.state.selectedCMS
      ? this.state.selectedCMS
      : "wordpress";
    let currentSelectedVideo = youtubeVideos[currentSelectedCMS]
      ? "https://www.youtube.com/embed/" + youtubeVideos[currentSelectedCMS]
      : "";
    let currentSelectedDoc = docLinks[currentSelectedCMS];

    let onOwnClass;
    let takehelpClass;
    if (this.state.showType === 1 || this.state.showType === 5) {
      takehelpClass = "active";
      onOwnClass = "";
    } else {
      takehelpClass = "";
      onOwnClass = "active";
    }
    return (
      <>
        <LeftPanel />
        {this.state.setup_guide === false &&
        this.state.setup_guide_step < 3 &&
        this.state.showType === 2 &&
        !this.state.openEmailBox ? (
          <div className="overlay-guide"></div>
        ) : null}
        <Device onChange={this.onChangee} />
        <ReactTooltip />
        {this.state.paymentStatus === false &&
        this.state.show_old_free_plan === false ? (
          <PaymentForm />
        ) : null}
        {(this.state.paymentStatus ||
          this.state.show_old_free_plan === true) && (
          <div className="page-wrapper sidebar-collapse" data-mobile-height="">
            <div className="main-wrapper container-fluid">
              {(this.state.showType === 2 ||
                this.state.showType === 3 ||
                this.state.showType === 4 ||
                this.state.showType === 6 ||
                this.state.showType === 7 ||
                this.state.showType === 20 ||
                this.state.showType === 22) && (
                <div className="breadcrumbs">
                  <p className="pt-6">
                    <img
                      src={require("../../../assets/img/icon/home-icon.svg")}
                      style={{ marginTop: "-4px" }}
                    />
                    <span
                      className="link-style text-underline pl-3"
                      onClick={() => this.setState({ showType: 0 })}
                    >
                      Self-service
                    </span>
                    {this.state.showType === 2 && (
                      <> &gt; Install widget on custom website</>
                    )}
                    {this.state.showType === 3 && (
                      <> &gt; Install widget in a third party CMS</>
                    )}
                    {this.state.showType === 4 && (
                      <>
                        {" "}
                        &gt;{" "}
                        <span
                          className="link-style"
                          onClick={() => this.setState({ showType: 3 })}
                        >
                          Install widget in a third party CMS
                        </span>{" "}
                        &gt; Install widget on {currentSelectedCMS} website
                      </>
                    )}
                    {this.state.showType === 6 && (
                      <> &gt; Install widget on social media platforms</>
                    )}
                    {this.state.showType === 8 && (
                      <> &gt; Deploy bot using mobile SDK</>
                    )}
                    {this.state.showType === 7 && (
                      <>
                        {" "}
                        &gt;{" "}
                        <span
                          className="link-style"
                          onClick={() => this.setState({ showType: 6 })}
                        >
                          Install widget on social media platforms
                        </span>{" "}
                        &gt; Deploy on facebook page
                      </>
                    )}
                    {this.state.showType === 20 && (
                      <>
                        {" "}
                        &gt;{" "}
                        <span
                          className="link-style"
                          onClick={() => this.setState({ showType: 6 })}
                        >
                          Install widget on social media platforms
                        </span>{" "}
                        &gt; Deploy on Twitter page
                      </>
                    )}
                    {this.state.showType === 22 && (
                      <>
                        {" "}
                        &gt;{" "}
                        <span
                          className="link-style"
                          onClick={() => this.setState({ showType: 6 })}
                        >
                          Install widget on social media platforms
                        </span>{" "}
                        &gt; Deploy on Instagram page
                      </>
                    )}
                  </p>
                </div>
              )}

              <div className="main-heading main-heading-title py-5">
                <h3 className="heding-text">Install widget</h3>
              </div>
              <div className="chatform-Preview bg-white mt-1">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 borderRight">
                      <div className="chat-forms py-5 px-5 position-relative">
                        <div className="setup-section">
                          <div className="setup-heading k-flex align-items-center tabs-view">
                            <button
                              className={onOwnClass}
                              onClick={() => this.goToTab(0)}
                            >
                              Self-service
                            </button>
                            <button
                              className={takehelpClass}
                              onClick={() => this.goToTab(5)}
                            >
                              Managed
                            </button>
                          </div>

                          <div
                            className="setup-list-design pt-5"
                            style={{
                              display:
                                this.state.showType === 5 ? "block" : "none",
                            }}
                          >
                            <p>
                              Thank you for subscribing to <b>Managed</b>{" "}
                              service from Appy Pie where our engineers will
                              assist you in installing the Livechat widget for
                              you. So, please go ahead and book an appointment
                              with one of our representatives so that they can
                              set up your Livechat widget on your website or
                              app.
                            </p>
                            <iframe
                              src="https://cal.services/sonal/Zoom-Session/eaFQlhjD5"
                              width="100%"
                              height="950"
                              scrolling="no"
                              frameborder="0"
                            ></iframe>
                            {/* <div className="calendly-inline-widget" data-url="https://calendly.com/joel-appypie/demo-for-livechat" style={{ minWidth: 320, height: 630 }}></div> */}
                            {/* <p>Please click <a href="https://cal.services/joel/Meeting/1wjp3IgM" target="_blank" className="link-style">here</a> to schedule a meeting.</p> */}
                            {this.state.showGoToDashboard ? (
                              <p>
                                I'll do this later. Take me to{" "}
                                <span
                                  className="link-style"
                                  onClick={this.goToDashboard}
                                >
                                  Dashboard
                                </span>
                              </p>
                            ) : null}
                          </div>

                          {this.state.showType === 1 ? (
                            <div className="planDetails mt-5">
                              <p className="mt-8 mb-8">
                                In our <b>Managed</b> service, one of our
                                engineers will assist you in deploying your
                                Livechat widget on your website or app.
                              </p>
                              <div className="row">
                                <div className="col-xl-5">
                                  <div className="planwrap shadow-view">
                                    <h5>
                                      <b>Basic</b>
                                    </h5>
                                    <div className="plantab mt-4">
                                      <a
                                        href="javascript:void(0)"
                                        className={
                                          this.state.selectedPlanType === 1
                                            ? "active plantab_link"
                                            : "plantab_link"
                                        }
                                        id="plantab_0"
                                        onClick={() => {
                                          this.selectPlanType(1);
                                        }}
                                      >
                                        Monthly
                                      </a>
                                      <a
                                        href="javascript:void(0)"
                                        className={
                                          this.state.selectedPlanType !== 1
                                            ? "active plantab_link"
                                            : "plantab_link"
                                        }
                                        id="plantab_1"
                                        onClick={() => {
                                          this.selectPlanType(0);
                                        }}
                                      >
                                        Annual
                                      </a>
                                    </div>
                                    <div className="annualplan">
                                      {this.state.selectedPlanType === 1 ? (
                                        <div
                                          className="plantop"
                                          id="plan_detail_0"
                                        >
                                          <h5>
                                            <b>
                                              {this.state.currency}
                                              {this.state.planPriceMonthly}/
                                              monthly
                                            </b>
                                          </h5>
                                        </div>
                                      ) : (
                                        <div
                                          className="plantop"
                                          id="plan_detail_1"
                                        >
                                          <h5>
                                            <b>
                                              {this.state.currency}
                                              {this.state.planPriceYearly}/
                                              yearly
                                            </b>
                                          </h5>
                                          <p>
                                            Save {this.state.currency}
                                            {this.state.planPriceDiscount}{" "}
                                            monthly
                                          </p>
                                        </div>
                                      )}

                                      <div className="planbtn my-4 py-1 btn-single">
                                        <a
                                          href="javascript:void(0)"
                                          className=""
                                          onClick={this.openPopup}
                                          style={{ borderRadius: "30px 30px" }}
                                        >
                                          Buy Now
                                        </a>
                                      </div>
                                      <div className="plandfeature">
                                        <ul className="list-view">
                                          <li>Advanced widget customization</li>
                                          <li>Chat forms</li>
                                          <li>User consent</li>
                                          <li>Email notifications</li>
                                          <li>Download transcript</li>
                                          <li>Email support</li>
                                          <li>Live agent support</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-7">
                                  <div className="planfeaturedetail">
                                    <div className="row">
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/add-agents.svg")}
                                          />
                                          <p>Add unlimited agents</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/unlimited-chats.svg")}
                                          />
                                          <p>Unlimited chats / month</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/attachments.svg")}
                                          />
                                          <p>Send/receive attachments</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/chat-history.svg")}
                                          />
                                          <p>Unlimited chat history</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/preset-roles.svg")}
                                          />
                                          <p>Preset roles</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-lg-4">
                                        <div className="plan-iconwrap cursor-default">
                                          <img
                                            src={require("../../../assets/images/custom-roles.svg")}
                                          />
                                          <p>Custom roles available</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {this.state.showType === 0 ? (
                            <div className="setup-list-design pt-5">
                              <ul className="title-card">
                                <li
                                  onClick={() => this.setState({ showType: 3 })}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/third-party.svg")}
                                  />
                                  <button>
                                    Install widget on a third party CMS
                                  </button>
                                </li>
                                <li
                                  onClick={() => this.setState({ showType: 2 })}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/custom-widget.svg")}
                                  />
                                  <button>
                                    Install widget on a custom website
                                  </button>
                                </li>
                                <li
                                  onClick={() => this.setState({ showType: 8 })}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/mobile-sdk.svg")}
                                  />
                                  <button>Deploy bot using mobile SDK</button>
                                </li>
                                {this.agent.isInHouseAccount === 1 && false && (
                                  <li
                                    onClick={() =>
                                      this.setState({ showType: 6 })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {/* <img src={require('../../../assets/images/facebook_icon_130940.png')} /> */}
                                    <span className="k-flex flex-wrap socialAlign">
                                      <span className="social-img">
                                        <img
                                          src={require("../../../assets/images/fb-logo.svg")}
                                        />
                                        <span>Facebook Page</span>
                                      </span>
                                      <span className="social-img heightEqual">
                                        <svg
                                          viewBox="0 -4 30 34"
                                          aria-hidden="true"
                                          className="install-twitter-dm"
                                        >
                                          <g>
                                            <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path>
                                          </g>
                                        </svg>
                                        <span>Twitter DM</span>
                                      </span>
                                      <span className="social-img">
                                        <img
                                          style={{ height: "96px" }}
                                          src={require("../../../assets/images/instagram.svg")}
                                        />
                                        <span>Instagram Page</span>
                                      </span>
                                    </span>
                                    <button>
                                      Install widget on social media platforms
                                    </button>
                                  </li>
                                )}
                              </ul>
                            </div>
                          ) : null}

                          {this.state.showType === 2 ? (
                            <div className="setup-list-design pt-5 widget-design">
                              <h4 className="card-title mb-5 bold">
                                Install widget code manually
                                {widgetInstalledMessage}
                              </h4>

                              <ul className="after-none">
                                <li className="active mt-6">
                                  {/* <span className="setup-step-no">Step 1</span> */}
                                  <div
                                    className={
                                      this.state.setup_guide === false &&
                                      this.state.setup_guide_step === 0 &&
                                      !this.state.openEmailBox
                                        ? "k-flex align-items-center position-top"
                                        : "k-flex align-items-center"
                                    }
                                  >
                                    <h6 className="card-title mb-0">
                                      Copy this widget code and paste it in the
                                      &lt;body&gt; section of every page of your
                                      website.{" "}
                                    </h6>
                                    <div className="ml-auto">
                                      {/* <button className="btnBlue mr-2">Copy Code</button>
                                                                <button className="btnBlue" onClick={this.openEmailBox}>Send email</button> */}
                                      <span
                                        className="pl-5 cursorPointer"
                                        onClick={this.copyCode}
                                        data-tip="Copy code"
                                        id="tour_guide"
                                      >
                                        <button className="btnBlue mr-2 yellowBtn">
                                          Copy Code
                                        </button>
                                      </span>
                                      {this.state.setup_guide === false &&
                                      this.state.setup_guide_step === 0 ? (
                                        <div className="tour_guide">
                                          <p className="title">Step 1 of 3</p>
                                          <p className="text-center">
                                            To copy the widget code click on
                                            this option.
                                          </p>
                                          <button
                                            className="btn-next"
                                            onClick={() => {
                                              this.guidenextStep(
                                                "setup",
                                                1,
                                                "setup_guide_step"
                                              );
                                            }}
                                          >
                                            Next
                                          </button>
                                          <button
                                            className="end-btn"
                                            onClick={() => {
                                              this.endGuideTour(
                                                "setup",
                                                "setup_guide"
                                              );
                                            }}
                                          >
                                            End tour
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="setupScriptCodeArea mt-4 px-5 py-5">
                                    <div
                                      className="scriptCodeSetup"
                                      id="setuppage"
                                    >
                                      <textarea
                                        readOnly={true}
                                        row="5"
                                        style={{
                                          height: 60,
                                          border: "none",
                                          background: "transparent !important",
                                          resize: "none",
                                          width: "50%",
                                        }}
                                        id="myInputCopy"
                                        name="GFG_text"
                                        value={this.state.widgetUrl}
                                      ></textarea>
                                      <p id="widgetCode"></p>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      this.state.setup_guide === false &&
                                      this.state.setup_guide_step === 1 &&
                                      !this.state.openEmailBox
                                        ? "pl-5 cursorPointer float-right position-top"
                                        : "pl-5 cursorPointer float-right"
                                    }
                                  >
                                    <div className="torgideNew">
                                      <span
                                        className="cursorPointer link-style"
                                        onClick={this.openEmailBox}
                                        data-tip="Email widget's code to developer"
                                        id="tour_guide"
                                      >
                                        Send widget code to developer
                                      </span>
                                      {this.state.setup_guide === false &&
                                      this.state.setup_guide_step === 1 ? (
                                        <div className="tour_guide">
                                          <p className="title">Step 2 of 3</p>
                                          <p className="text-center">
                                            In case you wish to send the widget
                                            code to a developer who can install
                                            the widget on your behalf then you
                                            can click on this option.
                                          </p>
                                          <button
                                            className="btn-next"
                                            onClick={() => {
                                              this.guidenextStep(
                                                "setup",
                                                2,
                                                "setup_guide_step"
                                              );
                                            }}
                                          >
                                            Next
                                          </button>
                                          <button
                                            className="end-btn"
                                            onClick={() => {
                                              this.endGuideTour(
                                                "setup",
                                                "setup_guide"
                                              );
                                            }}
                                          >
                                            End tour
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  {/* <span className="setup-step-no">Step 2</span> */}
                                  <div className="k-flex align-items-center">
                                    <h6 className="card-title mb-0">
                                      Reload your website. Your Appy Pie
                                      Livechat widget will appear at the bottom.
                                    </h6>
                                  </div>
                                </li>
                                <li>
                                  {/* <span className="setup-step-no">Step 3</span> */}
                                  <div className="k-flex align-items-center">
                                    <h6 className="card-title mb-0">
                                      Once the widget code is added you need to
                                      enter the URL where you added the code
                                      snippet and click on "Verify"{" "}
                                    </h6>
                                  </div>
                                  <div
                                    className={
                                      this.state.setup_guide === false &&
                                      this.state.setup_guide_step === 2 &&
                                      !this.state.openEmailBox
                                        ? "k-flex mt-4 mb-4 form-feilds website-verifylink-feilds position-top"
                                        : "k-flex mt-4 mb-4 form-feilds website-verifylink-feilds"
                                    }
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <div className="input-group material-input mr-5">
                                      <TextField
                                        type="ttext"
                                        className="form-control"
                                        value={this.state.currentWebsite}
                                        label="Website Link"
                                        variant="outlined"
                                        name="currentWebsite"
                                        onChange={this.handleChange}
                                      />
                                      <p className="error">
                                        {this.state.url_error}
                                      </p>
                                    </div>

                                    {/* <div className="wrapperInfo mr-4 w-100P">
                                                                    <input type="text" placeholder="Website Link" value={this.state.currentWebsite} className="w-100" name="currentWebsite" onChange={this.handleChange} />
                                                                    <p className="error">{this.state.url_error}</p>
                                                                </div> */}
                                    <button
                                      className="verifyBtn grayBtn"
                                      onClick={this.verifyWebsite}
                                    >
                                      Verify
                                    </button>
                                    {this.state.setup_guide === false &&
                                    this.state.setup_guide_step === 2 &&
                                    !this.state.openEmailBox ? (
                                      <div className="tour_guide guide-position">
                                        <p className="title">Step 3 of 3</p>
                                        <p className="text-center">
                                          To verify the installation of widget
                                          you can enter the website URL here and
                                          click on verify button
                                        </p>
                                        <button
                                          className="end-btn"
                                          onClick={() => {
                                            this.endGuideTour(
                                              "setup",
                                              "setup_guide"
                                            );
                                          }}
                                        >
                                          End tour
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>

                                  {widgetMessages}

                                  {this.state.verified ? (
                                    <div className="trouble-shotes pt-5">
                                      <ul className="my-2 pl-5">
                                        {this.state.allDomains.map(
                                          (domains, index) => (
                                            <li key={index}>{domains}</li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  ) : null}

                                  {!this.state.verified ? (
                                    <div className="trouble-shotes pt-5">
                                      <h6 className="card-title mb-0">
                                        Are you unable to see livechat widget on
                                        your website even after deploying it ?{" "}
                                        <span
                                          className="link-style"
                                          onClick={() => {
                                            this.setState({
                                              showTroubleShoot:
                                                !this.state.showTroubleShoot,
                                            });
                                          }}
                                        >
                                          {this.state.showTroubleShoot
                                            ? "Hide"
                                            : "View"}{" "}
                                          steps to troubleshoot
                                        </span>
                                      </h6>
                                      {this.state.showTroubleShoot ? (
                                        <>
                                          <h6 className="card-title mb-0">
                                            Following are the steps to
                                            troubleshoot installation of widget
                                          </h6>
                                          <ol className="my-2 pl-5">
                                            <li>
                                              Check if your internet connect is
                                              working fine or not.{" "}
                                            </li>
                                            <li>
                                              Ensure that the URL you entered is
                                              some as the one in which you added
                                              the widget code.{" "}
                                            </li>
                                            <li>
                                              Ensure that the widget code is
                                              entered in the &lt;body&gt;
                                              section.
                                            </li>
                                          </ol>
                                          <p className="card-body-text">
                                            if the widget is still to appearing
                                            even after following the above
                                            mentioned steps then you can either{" "}
                                            <span
                                              className="link-style"
                                              onClick={this.openWidget}
                                            >
                                              chat with us
                                            </span>{" "}
                                            or email us at{" "}
                                            <a
                                              href="mailto:support@appypiellp.com"
                                              className="link-style"
                                            >
                                              support@appypiellp.com
                                            </a>
                                          </p>
                                        </>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </li>
                              </ul>
                            </div>
                          ) : null}

                          {this.state.showType === 3 ? (
                            <div className="pt-5 setup-list-design">
                              <ul className="title-card box-4 k-flex">
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "wordpress",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/wordpress.svg")}
                                  />
                                  <button>
                                    Install widget on your wordpress website
                                  </button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "shopify",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/Shopify_logo.svg")}
                                  />
                                  <button>
                                    Install widget on your shopify website
                                  </button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "wix",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/Wix.svg")}
                                  />
                                  <button>
                                    Install widget on your wix website
                                  </button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "squarespace",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/squarespace.svg")}
                                  />
                                  <button>
                                    Install widget on your squarespace website
                                  </button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "godaddy",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/godaddy.svg")}
                                  />
                                  <button>
                                    Install widget on your godaddy website
                                  </button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      selectedCMS: "weebly",
                                      showType: 4,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/weebly.svg")}
                                  />
                                  <button>
                                    Install widget on your weebly website
                                  </button>
                                </li>
                                {/* <li onClick={() => { 
                                                            // this.setState({ selectedCMS: 'magento', showType: 4 }) 
                                                            alert('Video coming soon!');
                                                        }} style={{ cursor: 'pointer' }}>
                                                            <img src={require('../../../assets/images/magento.svg')} />
                                                            <button>Install widget on your magento website</button>
                                                        </li>
                                                        <li onClick={() => {
                                                            // this.setState({ selectedCMS: 'webflow', showType: 4 }) 
                                                            alert('Video coming soon!');
                                                        }} style={{ cursor: 'pointer' }}>
                                                            <img src={require('../../../assets/images/webflow.svg')} />
                                                            <button>Install widget on your webflow website</button>
                                                        </li> */}
                              </ul>
                            </div>
                          ) : null}

                          {this.agent.isInHouseAccount &&
                          this.agent.isInHouseAccount === 1 &&
                          this.state.showType === 6 &&
                          false ? (
                            <div className="pt-5 setup-list-design">
                              <ul className="title-card box-4 k-flex">
                                <li
                                  onClick={() => {
                                    this.setState({ showType: 7 });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../../assets/images/fb-logo.svg")}
                                  />
                                  <span className="width-clear">
                                    Facebook Page
                                  </span>
                                  <button>Deploy on facebook page</button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({
                                      showType: 20,
                                      error_consumer_key: "",
                                      error_consumer_secret: "",
                                      error_access_token: "",
                                      error_access_secret: "",
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                    className="install-twitter-dm"
                                  >
                                    <g>
                                      <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path>
                                    </g>
                                  </svg>
                                  <span className="width-clear">
                                    Twitter DM
                                  </span>
                                  <button>Deploy on Twitter page</button>
                                </li>
                                <li
                                  onClick={() => {
                                    this.setState({ showType: 22 });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    style={{ height: "110px" }}
                                    src={require("../../../assets/images/instagram.svg")}
                                  />
                                  <span className="width-clear">
                                    Instagram Page
                                  </span>
                                  <button>Deploy on Instagram page</button>
                                </li>
                              </ul>
                            </div>
                          ) : null}

                          {this.state.showType === 7 ? (
                            <div className="pt-5 setup-list-design">
                              <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                <div className="fb-connect">
                                  <div className="k-flex align-items-center">
                                    <div className="fb-details">
                                      <h2>facebook</h2>
                                      <p>
                                        Reply to Facebook messages right from
                                        your inbox
                                      </p>
                                      <div>
                                        <a
                                          href="https://www.appypie.com/faqs/how-to-deploy-livechat-bot-on-facebook"
                                          target="_blank"
                                          className="link-type"
                                        >
                                          <img
                                            src={require("../../../assets/img/icon/book.svg")}
                                          />
                                          User Guide
                                        </a>
                                      </div>
                                    </div>

                                    <div className="fb-btn">
                                      {this.state.pageList.length > 0 ? (
                                        <button onClick={this.deletePage}>
                                          <span>
                                            <img
                                              src={require("../../../assets/img/icon/check-mark.svg")}
                                            />
                                          </span>
                                          Disconnect
                                        </button>
                                      ) : (
                                        <button onClick={this.installMessenger}>
                                          <span>
                                            <img
                                              src={require("../../../assets/img/icon/link-symbol.svg")}
                                            />
                                          </span>
                                          Connect
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {this.state.pageList.length > 0 ? (
                                  <table className="table mt-10 fb-tbl-view">
                                    <thead>
                                      <tr>
                                        <th>Page Name</th>
                                        <th>Page Category</th>
                                        <th>Page Id</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.pageList.map(
                                        (pageDet, indx) => {
                                          return (
                                            <tr key={"keyy_" + indx}>
                                              <td>{pageDet.page_name}</td>
                                              <td>{pageDet.page_category}</td>
                                              <td>{pageDet.recipient_id}</td>
                                              <td>{pageDet.addedon}</td>
                                              <td>
                                                <div className="custom-switch">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      pageDet.subscribe
                                                        ? "checked"
                                                        : ""
                                                    }
                                                    onChange={() =>
                                                      this.connectDisconnectPage(
                                                        pageDet
                                                      )
                                                    }
                                                    className="custom-control-input"
                                                    id={
                                                      "switch_" +
                                                      pageDet.recipient_id
                                                    }
                                                    name="connectdisconnect"
                                                  />
                                                  <label
                                                    className="custom-control-label cursor-pointer"
                                                    htmlFor={
                                                      "switch_" +
                                                      pageDet.recipient_id
                                                    }
                                                  ></label>
                                                  <span>
                                                    {pageDet.subscribe
                                                      ? "Connected"
                                                      : "Not Connect"}
                                                  </span>
                                                </div>
                                              </td>
                                              {/* <td key={"subscribe_" + indx}>{pageDet.subscribe ? "Yes" : "No"}</td>
                                                                    {pageDet.subscribe ? (null) : (<li key={"connectpage_" + indx}><button name="connectpage" onClick={() => this.connectPage(pageDet)}>Connect</button></li>)}
                                                                    {pageDet.subscribe ? (<li key={"disconnectpage_" + indx}><button name="disconnectpage" onClick={() => this.disconnectPage(pageDet)}>Disconnect</button></li>) : (null)} */}
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                ) : null}
                              </div>
                            </div>
                          ) : null}

                          {this.state.showType === 22 ? (
                            <div className="pt-5 setup-list-design">
                              <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                <div className="fb-connect">
                                  <div className="k-flex align-items-center">
                                    <div className="fb-details">
                                      <h2>Instagram</h2>
                                      <p>
                                        Reply to Instagram messages right from
                                        your inbox
                                      </p>
                                      {/* <div>
                                                                        <a href="#" target="_blank" className="link-type"><img src={require('../../../assets/img/icon/book.svg')} />User Guide</a>
                                                                    </div> */}
                                    </div>

                                    <div className="fb-btn">
                                      {this.state.instaPagesList.length > 0 ? (
                                        <button onClick={this.deleteInstaPage}>
                                          <span>
                                            <img
                                              src={require("../../../assets/img/icon/check-mark.svg")}
                                            />
                                          </span>
                                          Disconnect
                                        </button>
                                      ) : (
                                        <button
                                          onClick={this.installInstaMessenger}
                                        >
                                          <span>
                                            <img
                                              src={require("../../../assets/img/icon/link-symbol.svg")}
                                            />
                                          </span>
                                          Connect
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {this.state.instaPagesList.length > 0 ? (
                                  <table className="table mt-10 fb-tbl-view">
                                    <thead>
                                      <tr>
                                        <th>Page Name</th>
                                        <th>Page Category</th>
                                        <th>Page Id</th>
                                        <th>Added Date</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.instaPagesList.map(
                                        (pageDet, indx) => {
                                          return (
                                            <tr key={"keyy_" + indx}>
                                              <td>{pageDet.page_name}</td>
                                              <td>{pageDet.page_category}</td>
                                              <td>{pageDet.recipient_id}</td>
                                              <td>{pageDet.addedon}</td>
                                              <td>
                                                <div className="custom-switch">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      pageDet.subscribe
                                                        ? "checked"
                                                        : ""
                                                    }
                                                    onChange={() =>
                                                      this.connectDisconnectInstaPage(
                                                        pageDet
                                                      )
                                                    }
                                                    className="custom-control-input"
                                                    id={
                                                      "switch_" +
                                                      pageDet.recipient_id
                                                    }
                                                    name="connectdisconnect"
                                                  />
                                                  <label
                                                    className="custom-control-label cursor-pointer"
                                                    htmlFor={
                                                      "switch_" +
                                                      pageDet.recipient_id
                                                    }
                                                  ></label>
                                                  <span>
                                                    {pageDet.subscribe
                                                      ? "Connected"
                                                      : "Not Connect"}
                                                  </span>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                ) : null}
                              </div>
                            </div>
                          ) : null}

                          {this.state.showType === 4 ? (
                            <div className="setup-list-design pt-5">
                              <p className="mb-5">
                                To view steps of adding your Appy Pie Livechat
                                account's widget in {currentSelectedCMS} website
                                check out the help doc -{" "}
                                <a
                                  className="link-style"
                                  href={currentSelectedDoc}
                                >
                                  How to deploy Appy Pie Livechat's widget in{" "}
                                  {currentSelectedCMS}
                                </a>
                              </p>
                              {currentSelectedVideo ? (
                                <>
                                  <p className="mb-5">
                                    You can also check out this video added
                                    below:{" "}
                                  </p>
                                  <div className="text-center mt-10">
                                    <iframe
                                      width="720"
                                      height="420"
                                      style={{ width: "100%" }}
                                      src={currentSelectedVideo}
                                      title="YouTube video player"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen={false}
                                    ></iframe>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          ) : null}

                          {/* Twitter HTML Start */}

                          {this.state.showType === 20 ? (
                            <div className="setting-section pb-1">
                              <div className="mx-8">
                                <div className="main-heading main-heading-title py-5">
                                  <h3 className="heding-text">Twitter DM</h3>
                                  <div>
                                    <a
                                      href="https://www.appypie.com/faqs/how-to-deploy-livechat-bot-on-twitter"
                                      target="_blank"
                                      className="link-type"
                                    >
                                      <img
                                        src={require("../../../assets/img/icon/book.svg")}
                                      />
                                      User Guide
                                    </a>
                                  </div>
                                </div>
                              </div>

                              {this.state.twitterTokens &&
                                this.state.twitterTokens.is_subscribe && (
                                  <div className="mx-8 pb-5">
                                    <div className="main-heading main-heading-title py-5">
                                      <h4>
                                        Your Website Is Connected To Twitter.
                                      </h4>
                                      <p>
                                        Already connected. Reconnect if you have
                                        any trouble.
                                      </p>
                                    </div>
                                  </div>
                                )}

                              {(this.state.success_twitter_dm ||
                                this.state.error_twitter_dm) && (
                                <div className="mx-8">
                                  <div className="main-heading main-heading-title pb-5">
                                    {this.state.success_twitter_dm && (
                                      <span style={{ color: "#1ca91c" }}>
                                        {this.state.success_twitter_dm}
                                      </span>
                                    )}
                                    {this.state.error_twitter_dm && (
                                      <span style={{ color: "#d12828" }}>
                                        {this.state.error_twitter_dm}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}

                              <form onSubmit={this.handleTDMFormSubmit}>
                                <div className="settings-form-feilds mb-8 mx-8">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                      <TextField
                                        className="w-100"
                                        id="consumer_key"
                                        label="Twitter Consumer Key"
                                        variant="outlined"
                                        name="consumer_key"
                                        value={this.state.consumer_key}
                                        onChange={this.handleTDMChange}
                                        error={
                                          this.state.error_consumer_key
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.error_consumer_key
                                            ? this.state.error_consumer_key
                                            : ""
                                        }
                                      />
                                      {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                <h6 className="card-title mb-0">Twitter Consumer Key</h6>
                                                                            </div>
                                                                            <div className="wrapperInfo">
                                                                                <input type="text" className="w-100" name="consumer_key" value={this.state.consumer_key} placeholder="Your Twitter Consumer Key" onChange={this.handleTDMChange} />
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="settings-form-feilds mb-8 mx-8">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                      <TextField
                                        className="w-100"
                                        id="consumer_secret"
                                        label="Twitter Consumer Secret"
                                        variant="outlined"
                                        name="consumer_secret"
                                        value={this.state.consumer_secret}
                                        onChange={this.handleTDMChange}
                                        error={
                                          this.state.error_consumer_secret
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.error_consumer_secret
                                            ? this.state.error_consumer_secret
                                            : ""
                                        }
                                      />
                                      {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                <h6 className="card-title mb-0">Twitter Consumer Secret</h6>
                                                                            </div>
                                                                            <div className="wrapperInfo">
                                                                                <input type="text" className="w-100" name="consumer_secret" value={this.state.consumer_secret} placeholder="Your Twitter Consumer Secret" onChange={this.handleTDMChange} />
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="settings-form-feilds mb-8 mx-8">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                      <TextField
                                        className="w-100"
                                        id="access_token"
                                        label="Twitter Account Token"
                                        variant="outlined"
                                        name="access_token"
                                        value={this.state.access_token}
                                        onChange={this.handleTDMChange}
                                        error={
                                          this.state.error_access_token
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.error_access_token
                                            ? this.state.error_access_token
                                            : ""
                                        }
                                      />
                                      {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                <h6 className="card-title mb-0">Twitter Account Token</h6>
                                                                            </div>
                                                                            <div className="wrapperInfo">
                                                                                <input type="text" className="w-100" name="access_token" value={this.state.access_token} placeholder="Your Twitter Account Token" onChange={this.handleTDMChange} />
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="settings-form-feilds mb-8 mx-8">
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
                                      <TextField
                                        className="w-100"
                                        id="access_secret"
                                        label="Twitter Account Secret"
                                        variant="outlined"
                                        name="access_secret"
                                        value={this.state.access_secret}
                                        onChange={this.handleTDMChange}
                                        error={
                                          this.state.error_access_secret
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.error_access_secret
                                            ? this.state.error_access_secret
                                            : ""
                                        }
                                      />
                                      {/* <div className="settings-feld k-flex align-items-center mb-2">
                                                                                <h6 className="card-title mb-0">Twitter Account Secret</h6>
                                                                            </div>
                                                                            <div className="wrapperInfo">
                                                                                <input type="text" className="w-100" name="access_secret" value={this.state.access_secret} placeholder="Your Twitter Account Secret" onChange={this.handleTDMChange} />
                                                                            </div> */}
                                    </div>
                                  </div>
                                </div>

                                <button
                                  className="btnBlueSettings mr-2 mx-8"
                                  type="submit"
                                >
                                  {this.state.twitterTokens &&
                                  this.state.twitterTokens.is_subscribe
                                    ? "Reconnect my Twitter account"
                                    : "Connect my Twitter account"}
                                </button>

                                {this.state.twitterTokens &&
                                  this.state.twitterTokens.is_subscribe && (
                                    <button
                                      className="btnBlueSettings mr-2 mx-8"
                                      type="button"
                                      onClick={this.disconnectTwitterDm}
                                    >
                                      Disconnect
                                    </button>
                                  )}
                              </form>
                            </div>
                          ) : null}

                          {/* Twitter HTML End */}

                          {/* Mobile SDK part */}
                          {this.state.showType === 8 ? (
                            <div className="sdkInstall-view mb-5">
                              <ul className="tabs-view">
                                <li
                                  className={
                                    this.state.showIOSTab
                                      ? "tablink on"
                                      : "tablink"
                                  }
                                  data-tab-id="iOS"
                                  onClick={() => {
                                    this.setState({ showIOSTab: true });
                                  }}
                                >
                                  iOS SDK
                                </li>
                                <li
                                  className={
                                    this.state.showIOSTab
                                      ? "tablink"
                                      : "tablink on"
                                  }
                                  data-tab-id="Android"
                                  onClick={() => {
                                    this.setState({ showIOSTab: false });
                                  }}
                                >
                                  Android SDK
                                </li>
                              </ul>

                              <div className="content-section mt-5">
                                {this.state.showIOSTab ? (
                                  <div
                                    id="iOS"
                                    className={
                                      this.state.showIOSTab
                                        ? "content on"
                                        : "content"
                                    }
                                  >
                                    <h5>Livechat SDK initialization in iOS</h5>
                                    <ol>
                                      <li className="position-relative">
                                        Download Livechat SDK from here.
                                        <a
                                          className="btnBlue"
                                          href="https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/SDK%2FChatAgentFolder.zip?alt=media&token=dd3230a6-2ad3-4088-98de-8105ada09343"
                                          download="LivechatSDKiOS.zip"
                                        >
                                          Download SDK
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.521"
                                            height="13.484"
                                            viewBox="0 0 14.521 13.484"
                                          >
                                            <g
                                              id="Group_13169"
                                              data-name="Group 13169"
                                              transform="translate(-674.885 -327)"
                                            >
                                              <path
                                                id="Icon_metro-file-download"
                                                data-name="Icon metro-file-download"
                                                d="M23.519,12.65H19.37V6.427H13.146V12.65H9l7.261,7.261Z"
                                                transform="translate(665.888 320.573)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </a>
                                      </li>

                                      <li>
                                        First Add ChatAgentFramework to your
                                        project using below steps:
                                        <ul
                                          className="pl-4 mt-3"
                                          style={{
                                            listStyleType: "lower-roman",
                                          }}
                                        >
                                          <li>
                                            {" "}
                                            You can directly drag and drop the
                                            framework into the project using
                                            Xcode with your open project or by
                                            adding the same in the project files
                                            where your project is available in
                                            your system.
                                          </li>
                                          <li>
                                            {" "}
                                            Then tap on your project name on the
                                            top left corner in your Xcode, right
                                            hand side you will have “General”
                                            setting of your project slightly
                                            scroll to the bottom you will get
                                            “Framework, Libraries, and Embedded
                                            Content”.
                                          </li>
                                          <li>
                                            {" "}
                                            Tap on + icon just below the option
                                            above.
                                          </li>
                                          <li>
                                            {" "}
                                            Tapping on that, there will be a
                                            popup “Choose frameworks and
                                            libraries to add:".
                                          </li>
                                          <li>
                                            {" "}
                                            On the bottom left corner there will
                                            be a button “Add Other” with bottom
                                            arrow.
                                          </li>
                                          <li>
                                            {" "}
                                            Tap on the button “Add Other” then
                                            tap on the option “Add Files…”.
                                          </li>
                                          <li>
                                            {" "}
                                            Tapping on the button “Add Files…”
                                            it will open your project directory
                                            where you can select your framework
                                            “ChatAgentFrameworkDev.framework”
                                            for device and
                                            “ChatAgentFrameworkSim.framework”
                                            for simulator as per the name given
                                            while creating your framework.
                                          </li>
                                          <li>
                                            {" "}
                                            Finally, after selecting your
                                            framework tap on the “Open” on the
                                            bottom right corner.
                                          </li>
                                        </ul>
                                        By following the all above steps, your
                                        framework will added to your project.
                                      </li>

                                      <li>
                                        Add Livechat Framework to your project
                                        from:
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                              Add Livechat Framework to your
                                              project from:
                                            </p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(
                                                  `pod 'Firebase/Firestore'
                                                    pod 'Firebase/Auth'
                                                    pod 'Firebase/Messaging'
                                                    pod 'Firebase/Storage'
                                                    pod 'ADCountryPicker'
                                                    pod 'PaddingLabel'
                                                    pod 'SwiftJWT'
                                                    pod 'SVGKit'
                                                    pod 'IQKeyboardManagerSwift'
                                                    pod 'Kingfisher'#, '~> 7.0'
                                                    pod 'Cosmos'#, '~> 23.0'
                                                    pod 'DropDown'`,
                                                  1
                                                );
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect1">
                                              {" "}
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Firebase/Firestore'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Firebase/Auth'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Firebase/Messaging'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Firebase/Storage'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'ADCountryPicker'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'PaddingLabel'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'SwiftJWT'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'SVGKit'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'IQKeyboardManagerSwift'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Kingfisher'#, '~{">"} 7.0'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'Cosmos'#, '~{">"} 23.0'
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                pod
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                'DropDown'
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        Make sure in your build settings {"->"}{" "}
                                        Validate Workspace - YES.
                                      </li>
                                      <li>
                                        Add the below code from where you want
                                        to open Livechat
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                              Add the below code from where you
                                              want to open Livechat
                                            </p>
                                          </div>                                                        
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(
                                                  `let frameWorkBundle = Bundle(identifier: "com.appypiellc.appypiellc.LiveChatAgentFramework")
                                                      let storyBoard = UIStoryboard(name: "Main", bundle: frameWorkBundle)
                                                      let mainViewController = storyBoard.instantiateViewController(withIdentifier: "ChatAgentViewController") as? ChatAgentViewController
                                                      mainViewController?.USER_ID = ${this.state.ownerId}
                                                      mainViewController?.TEMPLATE_ID = ${this.state.chatBot}
                                                      mainViewController?.modalPresentationStyle = .fullScreen
                                                      self.present(mainViewController!, animated: true, completion: nil)`,
                                                  2
                                                );
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect2">
                                              <span className="aquaCodeText">
                                                let
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                frameWorkBundle
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                Bundle(identifier:
                                                "com.appypiellc.appypiellc.LiveChatAgentFramework")
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                let
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                storyBoard
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                UIStoryboard(name: "Main",
                                                bundle: frameWorkBundle)
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                let
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                mainViewController
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                storyBoard.instantiateViewController(withIdentifier:
                                                "ChatAgentViewController") as?
                                                ChatAgentViewController
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                mainViewController?.USER_ID
                                              </span>{" "}
                                              ={" "}
                                              <span className="redCodeText">
                                                {this.state.ownerId}
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                mainViewController?.TEMPLATE_ID
                                              </span>{" "}
                                              ={" "}
                                              <span className="redCodeText">
                                                {this.state.chatBot}
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                mainViewController?.modalPresentationStyle
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                .fullScreen
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                self.present(mainViewController!,
                                                animated: true, completion: nil)
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>Last step : Run your application</li>
                                    </ol>
                                  </div>
                                ) : (
                                  <div
                                    id="Android"
                                    className={
                                      this.state.showIOSTab
                                        ? "content"
                                        : "content on"
                                    }
                                  >
                                    <h5>
                                      Steps of importing Livechat aar file in
                                      Android Studio
                                    </h5>
                                    <ol>
                                      <li className="position-relative">
                                        {" "}
                                        Download Livechat SDK from here
                                        <a
                                          className="btnBlue"
                                          href="https://drive.google.com/file/d/1-C26Y2SXF4BLEHPEpuk0LNECUx163nGV/view?usp=sharing"
                                          download="LivechatSDKandroid.zip"
                                        >
                                          Download SDK
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.521"
                                            height="13.484"
                                            viewBox="0 0 14.521 13.484"
                                          >
                                            <g
                                              id="Group_13169"
                                              data-name="Group 13169"
                                              transform="translate(-674.885 -327)"
                                            >
                                              <path
                                                id="Icon_metro-file-download"
                                                data-name="Icon metro-file-download"
                                                d="M23.519,12.65H19.37V6.427H13.146V12.65H9l7.261,7.261Z"
                                                transform="translate(665.888 320.573)"
                                                fill="#fff"
                                              />
                                            </g>
                                          </svg>
                                        </a>
                                      </li>
                                      <li>
                                        Generate google-services.json file from{" "}
                                        <a href="https://console.firebase.google.com/">
                                          Firebase console
                                        </a>{" "}
                                        and add inside 'app' folder.
                                      </li>
                                      <li>
                                        Create a 'libs' folder in the 'app'
                                        folder in the project and add Livechat
                                        aar file downloaded in step 1.
                                      </li>
                                      <li>
                                        Add following dependencies code in the
                                        app level build.gradle file.
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                              Add following dependencies code in
                                              the app level build.gradle file.
                                            </p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this
                                                  .copyToClipboard(`dependencies {
                                                                                            // Implement jar files from the libs folder
                                                                                            implementation fileTree(dir: 'libs', include: ['*.jar'])
                                                                                            
                                                                                            // Lifecycle dependencies
                                                                                            implementation "androidx.lifecycle:lifecycle-common-java8:$lifecycle_version"
                                                                                            implementation "androidx.lifecycle:lifecycle-extensions:$lifecycle_version"
                                                                                            
                                                                                            // Firebase dependencies
                                                                                            implementation "com.google.firebase:firebase-storage:$firebase_storage"
                                                                                            implementation "com.google.firebase:firebase-core:$firebase_core"
                                                                                            api "com.google.firebase:firebase-firestore:$firebase_firestore"
                                                                                            implementation "com.google.firebase:firebase-auth-ktx:$firebase_auth_ktx"
                                                                                            
                                                                                            // Volley for network operations
                                                                                            api "com.android.volley:volley:$volley_version"
                                                                                            
                                                                                            // Material Components
                                                                                            implementation "com.google.android.material:material:$google_material"
                                                                                            
                                                                                            // AndroidX components
                                                                                            implementation "androidx.appcompat:appcompat:$androidx_appcompat"
                                                                                            implementation "androidx.core:core-ktx:$androidx_ktx"
                                                                                            api "androidx.activity:activity-ktx:$activity_ktx"
                                                                                            api "androidx.fragment:fragment-ktx:$fragment_ktx"
                                                                                            api "androidx.constraintlayout:constraintlayout:$androidx_constraintlayout"
                                                                                            api "androidx.activity:activity:1.6.0-alpha05"
                                                                                            
                                                                                            // Kotlin reflection
                                                                                            implementation "org.jetbrains.kotlin:kotlin-reflect:$kotlin_version"
                                                                                            
                                                                                            // Dimens and layout libraries
                                                                                            api "com.intuit.sdp:sdp-android:$sdp_android"
                                                                                            api "com.intuit.ssp:ssp-android:$sdp_android"
                                                                                            
                                                                                            // Glide for image loading
                                                                                            implementation ("com.github.bumptech.glide:glide:$glide@aar") {
                                                                                                transitive = true
                                                                                            }
                                                                                            kapt "com.github.bumptech.glide:compiler:$glide"
                                                                                            
                                                                                            // Static visual elements and custom libraries
                                                                                            implementation "com.caverock:androidsvg-aar:$caverock_svg"
                                                                                            implementation files('libs/livechat.aar')
                                                                                            
                                                                                            // Gson for JSON parsing
                                                                                            api "com.google.code.gson:gson:$gson"
                                                                                            
                                                                                            // Firebase Auth (if needed)
                                                                                            // implementation "com.google.firebase:firebase-auth:$firebase_auth"
                                                                                        }
                                                                                        `,3);
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect3">
                                              {/* <span className="aquaCodeText">dependencies</span> {"{"} */}
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              fileTree(dir:{" "}
                                              <span className="greenCodeText">
                                                'libs'
                                              </span>
                                              , include: [
                                              <span className="greenCodeText">
                                                '*.jar'
                                              </span>
                                              ])
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.lifecycle:lifecycle-common-java8:$lifecycle_version"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.lifecycle:lifecycle-extensions:$lifecycle_version"
                                              </span>
                                              <br />
                                              <br />
                                              {/* // Firebase dependencies */}
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.firebase:firebase-storage:$firebase_storage"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.firebase:firebase-core:$firebase_core"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.firebase:firebase-firestore:$firebase_firestore"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.firebase:firebase-auth-ktx:$firebase_auth_ktx"
                                              </span>
                                              <br />
                                              <br />
                                              {/* // Networking and material design */}
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.android.volley:volley:$volley_version"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.android.material:material:$google_material"
                                              </span>
                                              <br />
                                              <br />
                                              {/* // AndroidX and Kotlin extensions */}
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.appcompat:appcompat:$androidx_appcompat"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.core:core-ktx:$androidx_ktx"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.activity:activity-ktx:$activity_ktx"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.fragment:fragment-ktx:$fragment_ktx"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "org.jetbrains.kotlin:kotlin-reflect:$kotlin_version"
                                              </span>
                                              <br />
                                              <br />
                                              // Dimens utilities
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.intuit.sdp:sdp-android:$sdp_android"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.intuit.ssp:ssp-android:$sdp_android"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.constraintlayout:constraintlayout:$androidx_constraintlayout"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "androidx.activity:activity:1.6.0-alpha05"
                                              </span>
                                              <br />
                                              <br />
                                              // Glide (image loading library)
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              (
                                              <span className="greenCodeText">
                                                "com.github.bumptech.glide:glide:$glide@aar"
                                              </span>
                                              ) {"{"}
                                              <br />
                                              transitive = true
                                              <br />
                                              {"}"}
                                              <br />
                                              <span className="aquaCodeText">
                                                kapt
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.github.bumptech.glide:compiler:$glide"
                                              </span>
                                              <br />
                                              <br />
                                              // No need to change
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.caverock:androidsvg-aar:$caverock_svg"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                implementation
                                              </span>{" "}
                                              files(
                                              <span className="greenCodeText">
                                                'libs/livechat.aar'
                                              </span>
                                              )
                                              <br />
                                              <br />
                                              // JSON parsing
                                              <br />
                                              <span className="aquaCodeText">
                                                api
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                "com.google.code.gson:gson:$gson"
                                              </span>
                                              <br />
                                              {/* {"}"} */}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        Add following dependencies inside
                                        'buildscript' project level build.gradle
                                        file.
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                              Add following dependencies inside
                                              'buildscript' project level
                                              build.gradle file.
                                            </p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(`
                                                //=========== Configuration for build and dependencies =================
                                                ext.versions = [
                                                  'compileSdk': 33,
                                                  'minSdk': 24,
                                                  'targetSdk': 33,
                                                  'version_name': '1.0',
                                                  'version_code': 1
                                                ]
                                                
                                                ext.names = [
                                                  'applicationId': 'com.chat.myapplication'
                                                ]
                                                
                                                ext.gradle_version = '7.2.1'
                                                
                                                ext {
                                                  google_services = '4.3.10'
                                                  androidx_ktx = '1.3.2'
                                                  androidx_appcompat = '1.2.0'
                                                  google_material = '1.2.1'
                                                  androidx_constraintlayout = '2.0.4'
                                                  androidx_liveData = '2.3.0'
                                                  androidx_viewModel = '2.3.0'
                                                  junit = '4.12'
                                                  androidx_espresso = '3.2.0'
                                                  firebase_core = '19.0.1'
                                                  lifecycle_version = '2.2.0'
                                                  firebase_storage = '20.0.0'
                                                  volley_version = '1.2.1'
                                                  activity_ktx = '1.3.1'
                                                  fragment_ktx = '1.3.6'
                                                  sdp_android = '1.0.6'
                                                  glide = '4.14.2'
                                                  gson = '2.8.9'
                                                  firebase_firestore = '23.0.3'
                                                  firebase_auth_ktx = '21.0.1'
                                                  firebase_message = '23.0.6'
                                                  grpc_okhttp = '1.32.2'
                                                  firebase_analytics = '21.0.0'
                                                  commons_io = '2.7'
                                                }
                                                
                                                repositories {
                                                  google()
                                                  jcenter()
                                                  flatDir {
                                                    dirs 'libs'
                                                  }
                                                }`,4);
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect4">
                                              //=========== Configuration for
                                              build and dependencies
                                              =================
                                              <br />
                                              <span className="aquaCodeText">
                                                ext
                                              </span>
                                              .
                                              <span className="whiteCodeText">
                                                versions
                                              </span>{" "}
                                              = [
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'compileSdk'
                                              </span>
                                              :{" "}
                                              <span className="whiteCodeText">
                                                33
                                              </span>
                                              ,
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'minSdk'
                                              </span>
                                              :{" "}
                                              <span className="whiteCodeText">
                                                24
                                              </span>
                                              ,
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'targetSdk'
                                              </span>
                                              :{" "}
                                              <span className="whiteCodeText">
                                                33
                                              </span>
                                              ,
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'version_name'
                                              </span>
                                              :{" "}
                                              <span className="greenCodeText">
                                                '1.0'
                                              </span>
                                              ,
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'version_code'
                                              </span>
                                              :{" "}
                                              <span className="whiteCodeText">
                                                1
                                              </span>
                                              <br />
                                              ]
                                              <br />
                                              <br />
                                              <span className="aquaCodeText">
                                                ext
                                              </span>
                                              .
                                              <span className="whiteCodeText">
                                                names
                                              </span>{" "}
                                              = [
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="greenCodeText">
                                                'applicationId'
                                              </span>
                                              :{" "}
                                              <span className="greenCodeText">
                                                'com.chat.myapplication'
                                              </span>
                                              <br />
                                              ]
                                              <br />
                                              <br />
                                              <span className="aquaCodeText">
                                                ext
                                              </span>
                                              .
                                              <span className="whiteCodeText">
                                                gradle_version
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '7.2.1'
                                              </span>
                                              <br />
                                              <br />
                                              <span className="aquaCodeText">
                                                ext
                                              </span>{" "}
                                              {"{"}
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                google_services
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '4.3.10'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_ktx
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.3.2'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_appcompat
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.2.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                google_material
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.2.1'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_constraintlayout
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.0.4'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_liveData
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.3.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_viewModel
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.3.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                junit
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '4.12'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                androidx_espresso
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '3.2.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_core
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '19.0.1'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                lifecycle_version
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.2.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_storage
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '20.0.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                volley_version
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.2.1'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                activity_ktx
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.3.1'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                fragment_ktx
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.3.6'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                sdp_android
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.0.6'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                glide
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '4.14.2'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                gson
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.8.9'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_firestore
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '23.0.3'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_auth_ktx
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '21.0.1'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_message
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '23.0.6'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                grpc_okhttp
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '1.32.2'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                firebase_analytics
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '21.0.0'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                commons_io
                                              </span>{" "}
                                              ={" "}
                                              <span className="greenCodeText">
                                                '2.7'
                                              </span>
                                              <br />
                                              {"}"}
                                              <br />
                                              <br />
                                              <span className="aquaCodeText">
                                                repositories
                                              </span>{" "}
                                              {"{"}
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                google
                                              </span>
                                              ()
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                jcenter
                                              </span>
                                              ()
                                              <br />
                                              &nbsp;&nbsp;
                                              <span className="aquaCodeText">
                                                flatDir
                                              </span>{" "}
                                              {"{"}
                                              <br />
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              <span className="whiteCodeText">
                                                dirs
                                              </span>{" "}
                                              <span className="greenCodeText">
                                                'libs'
                                              </span>
                                              <br />
                                              &nbsp;&nbsp;{"}"}
                                              <br />
                                              {"}"}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        Add below the line on button click in
                                        Activity File
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                              Add below the line on button click
                                              in Activity File
                                            </p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            {" "}
                                            <ReactTooltip />
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(
                                                  ` 
                                                          val intent:Intent = Intent(this,LiveChatActivity::class.java)
                                                          startActivity(intent)
                                                          `,
                                                  5
                                                );
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect5">
                                              {" "}
                                              <span className="aquaCodeText">
                                                val
                                              </span>{" "}
                                              <span className="whiteCodeText">
                                                intent
                                              </span>
                                              :
                                              <span className="aquaCodeText">
                                                Intent
                                              </span>{" "}
                                              ={" "}
                                              <span className="whiteCodeText">
                                                Intent
                                              </span>
                                              (
                                              <span className="aquaCodeText">
                                                this
                                              </span>
                                              ,
                                              <span className="greenCodeText">
                                                LiveChatActivity::class.java
                                              </span>
                                              ) <br />
                                              <span className="whiteCodeText">
                                                startActivity
                                              </span>
                                              (
                                              <span className="aquaCodeText">
                                                intent
                                              </span>
                                              )
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        Create a App File and write down below
                                        code. 
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">Create a App File and write down below
                                        code.</p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(
                                                  `import android.app.Application
                                                  import com.appypie.livechat.activity.LiveChatActivity
                                                  
                                                  class App: Application () {
                                                  
                                                  override fun onCreate() {
                                                  super.onCreate()
                                                  LiveChatActivity.initializeCredential("Account Key", "Bot Id")
                                                  
                                                  }
                                                  }
                                                  
                                                  `,6);
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                     
                                      
                                      <blockquote>
                                       
                                          <div className="code-view">
                                            <div className="code-info">
                                              <p id="frameSelect6">
                                                <span className="aquaCodeText">
                                                  import
                                                </span>{" "}
                                                <span className="whiteCodeText">
                                                  android.app.Application
                                                </span>
                                                <br />
                                                <span className="aquaCodeText">
                                                  import
                                                </span>{" "}
                                                <span className="whiteCodeText">
                                                  com.appypie.livechat.activity.LiveChatActivity
                                                </span>
                                                <br />
                                                <br />
                                                <span className="aquaCodeText">
                                                  class
                                                </span>{" "}
                                                <span className="whiteCodeText">
                                                  App
                                                </span>
                                                :{" "}
                                                <span className="whiteCodeText">
                                                  Application
                                                </span>{" "}
                                                <span className="greenCodeText">
                                                  () {"{"}
                                                </span>
                                                <br />
                                                <br />
                                                <span className="aquaCodeText">
                                                  override
                                                </span>{" "}
                                                <span className="aquaCodeText">
                                                  fun
                                                </span>{" "}
                                                <span className="whiteCodeText">
                                                  onCreate
                                                </span>
                                                () {"{"}
                                                <br />
                                                <span className="whiteCodeText">
                                                  {" "}
                                                  super.onCreate()
                                                </span>
                                                <br />
                                                <span className="whiteCodeText">
                                                  {" "}
                                                  LiveChatActivity.initializeCredential
                                                </span>
                                                (
                                                <span className="greenCodeText">
                                                  "Account Key"
                                                </span>
                                                ,{" "}
                                                <span className="greenCodeText">
                                                  "Bot Id"
                                                </span>
                                                )<br />
                                                <br />
                                                <span className="whiteCodeText">
                                                  {"}"}
                                                </span>
                                                <br />
                                                <span className="whiteCodeText">
                                                  {"}"}
                                                </span>
                                                <br />
                                              </p>
                                            </div>
                                          </div>
                                       
                                      </blockquote>
                                      </li>
                                      <li>
                                        Change in Manifest file.
                                        <div className="code-viewHeader d-flex align-items-center">
                                          <div className="codeViewHeaderLeft">
                                            <p className="card-body-text">
                                            Change in Manifest file.{" "}
                                            </p>
                                          </div>
                                          <div className="codeViewHeaderRight ml-auto">
                                            <span
                                              data-tip="Click to copy"
                                              onClick={() => {
                                                this.copyToClipboard(
                                                  `<application
                                                    android:label="flutter_android"
                                                    android:name=".App"
                                                    tools:replace="android:icon,label"
                                                    android:icon="@mipmap/ic_launcher">
                                                </application>
                                            `,7);
                                              }}
                                              className="d-inline-block cursor-pointer"
                                            >
                                              <svg
                                                id="copy"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12"
                                                height="13"
                                                viewBox="0 0 15.001 18.734"
                                              >
                                                <g
                                                  id="Group_1875"
                                                  data-name="Group 1875"
                                                >
                                                  <path
                                                    id="Path_1849"
                                                    data-name="Path 1849"
                                                    d="M58.84,85.4H50.131a1.482,1.482,0,0,0-1.481,1.481v12.5a1.482,1.482,0,0,0,1.481,1.481H58.84a1.482,1.482,0,0,0,1.481-1.481v-12.5A1.487,1.487,0,0,0,58.84,85.4Zm.441,13.973a.445.445,0,0,1-.445.445H50.127a.445.445,0,0,1-.445-.445V86.881a.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445Z"
                                                    transform="translate(-48.65 -82.124)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path_1850"
                                                    data-name="Path 1850"
                                                    d="M145.64,0h-8.709a1.482,1.482,0,0,0-1.481,1.481.518.518,0,1,0,1.036,0,.445.445,0,0,1,.445-.445h8.709a.445.445,0,0,1,.445.445v12.5a.445.445,0,0,1-.445.445.518.518,0,0,0,0,1.036,1.482,1.482,0,0,0,1.481-1.481V1.481A1.482,1.482,0,0,0,145.64,0Z"
                                                    transform="translate(-132.12)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="code-view">
                                          <div className="code-info">
                                            <p id="frameSelect7">
                                              <span className="aquaCodeText">
                                                &lt;application
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                android:label=
                                              </span>
                                              <span className="greenCodeText">
                                                "flutter_android"
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                android:name=
                                              </span>
                                              <span className="greenCodeText">
                                                ".App"
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                tools:replace=
                                              </span>
                                              <span className="greenCodeText">
                                                "android:icon,label"
                                              </span>
                                              <br />
                                              <span className="whiteCodeText">
                                                android:icon=
                                              </span>
                                              <span className="greenCodeText">
                                                "@mipmap/ic_launcher"
                                              </span>
                                              <br />
                                              <span className="aquaCodeText">
                                                &gt;
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        Now Rebuild the application and run the
                                        application.
                                      </li>
                                    </ol>
                                  </div>
                                )}
                              </div>
                              <ReactTooltip />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="loading"
          style={{ display: this.state.loader ? "block" : "none" }}
        >
          Loading&#8230;
        </div>

        <div
          className="popupShadow"
          style={{
            display:
              this.state.successMessage ||
              this.state.openEmailBox ||
              this.state.modalVisible
                ? "block"
                : "none",
          }}
        ></div>

        <div
          className="popupInstallLivechat popupLivechat congratulationPopup"
          style={{ display: this.state.successMessage ? "block" : "none" }}
        >
          {this.state.successMessage ? (
            <lottie-player
              autoplay
              mode="normal"
              src={this.state.filePath}
              style={{
                width: `${this.w}px`,
                position: "absolute",
                top: 0,
                left: 0,
                height: `${this.h}px`,
              }}
            />
          ) : null}
          <div className="popupBody pb-5 px-5 text-center">
            <div className="py-5 feilds-instruction">
              <h1 className="card-title mb-0">Congratulation!</h1>
              <span className="congratilation-circle my-5">
                <svg
                  id="like"
                  xmlns="http://www.w3.org/2000/svg"
                  width="108.606"
                  height="123.57"
                  viewBox="0 0 108.606 123.57"
                >
                  <path
                    id="Path_2459"
                    data-name="Path 2459"
                    d="M242.024,148.406a7.262,7.262,0,0,0-7.24-7.24,7.24,7.24,0,0,0,0-14.481H205.1c4.416-13.25,4.344-17.715,4.344-25.824a10.826,10.826,0,0,0-7.24-10.209,10.034,10.034,0,0,0-3.62-.652H196.41a3.744,3.744,0,0,0-3.476,2.751l-2.027,7.892c-3.041,11.8-12.816,26.042-24.907,26.042v50.683l16.146,5.358a36.092,36.092,0,0,0,11.44,1.883h33.958a7.24,7.24,0,1,0,0-14.481h7.24a7.24,7.24,0,0,0,0-14.481A7.262,7.262,0,0,0,242.024,148.406Z"
                    transform="translate(-133.418 -68.279)"
                    fill="#fedbab"
                  />
                  <path
                    id="Path_2460"
                    data-name="Path 2460"
                    d="M329.962,184.608a7.24,7.24,0,0,0,0-14.481h7.24a7.24,7.24,0,1,0,0-14.481,7.24,7.24,0,1,0,0-14.481,7.24,7.24,0,1,0,0-14.481H307.517c4.416-13.25,4.344-17.715,4.344-25.824a10.826,10.826,0,0,0-7.24-10.209A10.034,10.034,0,0,0,301,90v94.608Z"
                    transform="translate(-235.836 -68.279)"
                    fill="#fec478"
                  />
                  <g id="Group_2111" data-name="Group 2111">
                    <path
                      id="Path_2461"
                      data-name="Path 2461"
                      d="M56.341,284.4H34.62a3.618,3.618,0,0,1-3.62-3.62V215.62A3.618,3.618,0,0,1,34.62,212H56.341A10.873,10.873,0,0,1,67.2,222.861v50.683A10.873,10.873,0,0,1,56.341,284.4Z"
                      transform="translate(-31 -160.834)"
                      fill="#1689fc"
                    />
                    <ellipse
                      id="Ellipse_42"
                      data-name="Ellipse 42"
                      cx="3.927"
                      cy="3.491"
                      rx="3.927"
                      ry="3.491"
                      transform="translate(13.993 102.107)"
                      fill="#1689fc"
                    />
                    <path
                      id="Path_2462"
                      data-name="Path 2462"
                      d="M293.24,3.62v7.24a3.62,3.62,0,0,1-7.24,0V3.62a3.62,3.62,0,0,1,7.24,0Z"
                      transform="translate(-224.456)"
                      fill="#1689fc"
                    />
                    <path
                      id="Path_2463"
                      data-name="Path 2463"
                      d="M196.717,50.836,191.6,45.717a3.62,3.62,0,0,1,5.119-5.119l5.119,5.119a3.62,3.62,0,0,1-5.119,5.119Z"
                      transform="translate(-152.033 -29.995)"
                      fill="#1689fc"
                    />
                  </g>
                  <g
                    id="Group_2112"
                    data-name="Group 2112"
                    transform="translate(65.164)"
                  >
                    <path
                      id="Path_2464"
                      data-name="Path 2464"
                      d="M361.313,50.836a3.619,3.619,0,0,1,0-5.119l5.119-5.119a3.62,3.62,0,0,1,5.119,5.119l-5.119,5.119A3.619,3.619,0,0,1,361.313,50.836Z"
                      transform="translate(-345.952 -29.995)"
                      fill="#136ef1"
                    />
                    <path
                      id="Path_2465"
                      data-name="Path 2465"
                      d="M304.62,3.62v7.24a3.585,3.585,0,0,1-3.62,3.62V0A3.585,3.585,0,0,1,304.62,3.62Z"
                      transform="translate(-301)"
                      fill="#136ef1"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <div className="pb-5 feilds-instruction">
              <h5 className="card-title mb-0">Your Livechat is now Live.</h5>
            </div>
            <div>
              <button
                className="btnBlue"
                onClick={this.hideSuceesMessage}
                style={{ position: "relative" }}
              >
                Join Chat
              </button>
            </div>
          </div>
        </div>

        <div
          className="popupInstallLivechat popupLivechat popup-scroll"
          style={{ display: this.state.openEmailBox ? "block" : "none" }}
        >
          <div className="popupHeader py-4 px-5 k-flex align-items-center">
            <h6 className="card-title mb-0 white-text">
              Send installation instructions to your developer
            </h6>
            <span
              className="close-popup ml-auto"
              onClick={() => this.closeEmailBox()}
            >
              <svg
                id="Group_1587"
                data-name="Group 1587"
                xmlns="http://www.w3.org/2000/svg"
                width="16.41"
                height="16.41"
                viewBox="0 0 16.41 16.41"
              >
                <path
                  id="Path_1769"
                  data-name="Path 1769"
                  d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z"
                  fill="#fff"
                />
                <g
                  id="Group_1479"
                  data-name="Group 1479"
                  transform="translate(2.388 8.158) rotate(-45)"
                >
                  <rect
                    id="Rectangle_42"
                    data-name="Rectangle 42"
                    width="1.36"
                    height="8.16"
                    rx="0.68"
                    transform="translate(3.4 0)"
                    fill="#407adc"
                  />
                  <rect
                    id="Rectangle_43"
                    data-name="Rectangle 43"
                    width="1.36"
                    height="8.16"
                    rx="0.68"
                    transform="translate(8.16 3.4) rotate(90)"
                    fill="#407adc"
                  />
                </g>
              </svg>
            </span>
          </div>
          <div className="popupBody pb-5 px-5">
            <div className="py-5 feilds-instruction">
              <h6 className="card-title mb-0">
                Enter developer's email address
              </h6>
              <input
                name="email"
                onChange={(e) => this.onChangeHandler(e)}
                value={this.state.developerEmail}
              />
              <p className="error">{this.state.email_error}</p>
            </div>
            <div className="pb-5 feilds-instruction">
              <h6 className="card-title mb-0">
                Add a message for your developer's (Optional)
              </h6>
              <textarea
                name="developerMessage"
                rows="3"
                placeholder="We wish to start using Appy Pie Livechat and I want you to install its widget code, enclosed in this email along with instructions, in our website."
                onChange={(e) => this.handleDeveloperMessage(e)}
                value={this.state.instructions}
              ></textarea>
            </div>
            <div>
              <button
                onClick={() => this.sendEmailDeveloper()}
                className="btnBlueSettings"
              >
                Send instructions{" "}
              </button>
              &nbsp;
              <button
                className="btnWhiteSettings mr-2"
                onClick={() => this.closeEmailBox()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testsetup;
