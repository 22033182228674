import React from 'react';
import LeftPanel from '../views/LeftPanel';
import Profile from './profile';
import { MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../../config/siteConfig';
// import db from '../../../config/firebase';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Device from 'react-device';
import { getOwnerSnapShot } from "../../../services/payment";
import { activityLog, getCookie } from '../../../services/service';
import PopUp from '../../../layout/components/popup';
import firebaseServices from '../../../firebase';

class DataManagement extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if (this.agent.roleType !== 'Owner') {
            window.location.href = "/" + this.agent.ownerIdentifire;
        }
        this.state = {
            loader: false,
            showProfilePage: false,
            showRevokeConsent: false,
            userConsent: true,
            consentChecked: true,
            deactivateAccountShow: false,
            deleteAccountShow: false,
            password: '',
            deleteReasons: [
                { name: 'better_alternative', value: "I found a better alternative" },
                { name: 'bot_complicated', value: "Making a bot here is too complicated" },
                { name: 'poor_support', value: "Poor customer support" },
                { name: 'feautures', value: "Didn't find feautures I was looking for" },
                { name: 'stopped_service', value: "I stopped using this service" },
                { name: 'other', value: "Other reason" }
            ],
            delete_reason: '',
            delete_description: '',
            showDeletePop: false,
            havesentOtp: false,
            exportingData: false,
            showExportPopup: false,
            otpError: '',
            UA: '',
            myBrowser: '',
            OS: '',
            company_name: '',
            paidAccount: true,
            deactiveKd:true
        }
    }

    componentDidMount() {
        this.getOwnerDetails();
        if (this.agent.ownerId) {
            getOwnerSnapShot(this.agent.ownerId, async (results) => {
                if (results.status === 200) { //payment_data.payment_status
                    let _data = results.data;
                    if(_data.payment_data.payment_status.includes("complete")){
                        this.setState({
                            deactiveKd:  !false
                        })
                    }else{
                    this.setState({
                        deactiveKd: false
                    })
                }
                } else {
                    this.setState({
                        deactiveKd: false
                    })
                }
            })
            activityLog('Opened data management page');
        }
    }

    getOwnerDetails = () => {
        if (this.agent.ownerId) {
            this.setState({
                loader: true
            })
            firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then((docs) => {
                if (docs.exists) {
                    let _data = docs.data();
                    this.setState({
                        userConsent: _data.consent === false ? false : true,
                        consentChecked: _data.consent === false ? false : true,
                        loader: false,
                        exportingData: _data.export === true || _data.export === 'IN_PROCESS' ? true : false,
                        company_name: _data.company_name
                    })
                }
            })
        }
    }

    showProfile = () => {
        this.setState({
            showProfilePage: true
        })
        activityLog('Opened data managemnt')
    }

    hideProfilePage = () => {
        this.setState({
            showProfilePage: false
        })
    }

    exportData = () => {
        this.setState({
            showExportPopup: true,
            exportingData: true
        })
        var body = [`ownerId=${this.agent.ownerId}&ownerEmail=${this.agent.ownerId}`];
        fetch(MAIL_FUNCTION_URL + "/exportData", {
            method: "post",
            body: body,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then((resp) => {
                if (resp.status !== 404 && resp.status !== 500) {
                    return resp.json();
                } else {
                    return false;
                }
            })
            .then(resp => {
                console.log(resp);
                activityLog('exported data');
            }).catch(async () => {
            });
    }

    revokeConsent = () => {
        this.setState({
            showRevokeConsent: true
        })
    }

    hideRevokeConsent = () => {
        this.setState({
            showRevokeConsent: false
        })
    }

    confirmRevokeConsent = () => {
        if (this.agent.ownerId) {
            this.setState({
                loader: true
            })
            firebaseServices.db.collection('users').doc(this.agent.ownerId).update({
                consent: false
            }).then(() => {
                this.setState({
                    loader: false,
                    showRevokeConsent: false,
                    userConsent: false,
                    consentChecked: false
                }, () => {
                    NotificationManager.success("", 'Consent has been successfully revoked', 5000);
                    activityLog('Revoked consent');
                })
            })
        }
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]: target.checked
        })
    }

    agreeConsent = (e) => {
        if (this.agent.ownerId) {
            this.setState({
                loader: true
            })
            firebaseServices.db.collection('users').doc(this.agent.ownerId).update({
                consent: true
            }).then(() => {
                this.setState({
                    loader: false,
                    showRevokeConsent: false,
                    userConsent: true,
                    consentChecked: true
                }, () => {
                    NotificationManager.success("", 'Consent has been successfully provided', 5000);
                    activityLog('Agreed consent');
                })
            })
        }
    }

    deactivateAccount = (e) => {
        if (this.state.deactiveKd) {
            this.setState({
                deactivatePaidAccountShow: true
            })
        } else {
            this.setState({
                deactivateAccountShow: true
            })
        }
        activityLog('Opened deactivate account page');
    }

    cancelDeactivateAccount = (e) => {
        this.setState({
            deactivateAccountShow: false
        })
    }

    confirmDeactivateAccount = async () => {
        if (this.agent.ownerId) {
            this.setState({
                loader: true
            })
            var body = [`browser=${this.state.myBrowser}&OS=${this.state.OS}`]
           // fetch(MAIL_FUNCTION_URL + "/loginApi/deactivateAccount", {
            fetch(GATEWAY_URL + "/deactivateAccount", {
                method: "post",
                body: body,
                headers: {"X-API-Key" : FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded","APPYID" :getCookie("APPYID")}
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.setState({
                            loader: false,
                            deactivateAccountShow: false
                        })
                    } else {
                        this.setState({
                            loader: false,
                            deactivateAccountShow: false
                        }, () => {
                            NotificationManager.error("", 'Something went wrong!', 5000);
                        })
                    }
                }).catch(() => {
                    this.setState({
                        loader: false,
                        deactivateAccountShow: false
                    }, () => {
                        NotificationManager.error("", 'Something went wrong!', 5000);
                    })
                });
        }
    }

    deleteAccount = () => {
        if (this.state.paidAccount) {
            this.setState({
                deletePaidAccountShow: true
            })
        } else {
            this.sendOtp();
            this.setState({
                deleteAccountShow: true
            })
        }
        activityLog('Opened delete account page');
    }

    cancelDeleteAccount = () => {
        this.setState({
            deleteAccountShow: false,
            password: ''
        })
    }

    handlePassword = (e) => {
        let target = e.target;
        this.setState({
            [target.name]: target.value,
            otpError: ''
        })
    }

    confirmPassword = () => {
        if (this.state.password.trim() === '') {
            this.setState({
                otpError: 'OTP can not be blank',
                password: ''
            })
            return false;
        }
        this.setState({
            loader: true
        })
        var body = [`ownerId=${this.agent.ownerId}&deleteOtp=${this.state.password}`];
        //fetch(MAIL_FUNCTION_URL + "/loginApi/verifydeleteotp", {
        fetch(GATEWAY_URL + "/verifydeleteotp", {
            method: "post",
            body: body,
            headers: { "X-API-Key" : FUNCTION_API_KEY,"Content-type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        loader: false,
                        deleteAccountShow: false,
                        showDeletePop: true
                    })
                } else if (response.status === 202) {
                    this.setState({
                        loader: false,
                        otpError: 'OTP has expired please click on "resend OTP" button to receive new OTP'
                    })
                } else {
                    this.setState({
                        loader: false,
                        otpError: 'Incorrect OTP'
                    })
                }
            }).catch(() => {
                this.setState({
                    loader: false,
                    deleteAccountShow: false
                }, () => {
                    NotificationManager.error("", 'Something went wrong!', 5000);
                })
            });
    }

    handleReason = (e) => {
        let value = e.target.value;
        this.state.deleteReasons.forEach((i, k) => {
            if (i.name !== value) {
                document.getElementById('description_' + i.name).style.display = "none"
            } else {
                this.setState({
                    [e.target.name]: i.value
                })
            }
        })
        document.getElementById('description_' + value).style.display = "block";
    }

    handleDescription = (e) => {
        this.setState({
            delete_description: e.target.value
        })
    }

    confirmDeleteAccount = (e) => {
        e.preventDefault();
        if (this.agent.ownerId) {
            this.setState({
                loader: true
            })
            let deleteOption = this.state.delete_reason;
            let deleteDescription = this.state.delete_description;
            var body = [`ownerId=${this.agent.ownerId}&deleteReason=${deleteOption}&deleteDescription=${deleteDescription}&ownerEmail=${this.agent.email.toLowerCase()}&browser=${this.state.myBrowser}&OS=${this.state.OS}&ownerName=${this.agent.name}&companyName=${this.state.company_name}`]
            //fetch(MAIL_FUNCTION_URL + "/loginApi/deleteAccount", {
            fetch(GATEWAY_URL + "/deleteAccount", {
                method: "post",
                body: body,
                headers: { "X-API-Key" : FUNCTION_API_KEY,"Content-type": "application/x-www-form-urlencoded" }
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.setState({
                            loader: false,
                            deactivateAccountShow: false
                        })
                    } else {
                        this.setState({
                            loader: false,
                            deactivateAccountShow: false
                        }, () => {
                            NotificationManager.error("", 'Something went wrong!', 5000);
                        })
                    }
                }).catch(() => {
                    this.setState({
                        loader: false,
                        deactivateAccountShow: false
                    }, () => {
                        NotificationManager.error("", 'Something went wrong!', 5000);
                    })
                });
        }
    }

    sendOtp = (toast = false) => {
        this.setState({
            otpError: ''
        })
        var body = [
            `email=${this.agent.email.toLowerCase()}&type=delete_account_otp&ownerId=${this.agent.ownerId}`
        ]
        //fetch(MAIL_FUNCTION_URL+"/mailFunctions/sendEmail", {
        fetch(GATEWAY_URL + "/sendEmail", {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === false || response.err !== null) {
                    this.setState({
                        loader: false,
                        deleteAccountShow: false
                    }, () => {
                        NotificationManager.error("", 'Something went wrong!', 5000);
                    })
                } else {
                    this.setState({
                        loader: false,
                        havesentOtp: true
                    })
                    if (toast === true) {
                        NotificationManager.success("", 'New OTP has been sent to your registered email id', 5000);
                    }
                }
            }).catch(() => {
                this.setState({
                    loader: false,
                    deleteAccountShow: false
                }, () => {
                    NotificationManager.error("", 'Something went wrong!', 5000);
                })
            });
    }

    hideExportPopup = () => {
        this.setState({
            showExportPopup: false
        })
    }

    cancelDeletePopup = () => {
        this.setState({
            showDeletePop: false,
            password: '',
            delete_reason: '',
            otpError: '',
            delete_description: ''
        })
    }

    onChangee = (deviceInfo) => {
        this.setState({
            UA: deviceInfo.ua,
            myBrowser: deviceInfo.browser.name,
            OS: deviceInfo.os.name
        })
    }

    gotoSubscription = () => {
        let organizatrionAlias = this.agent.ownerIdentifire ? this.agent.ownerIdentifire : this.agent.organization_alias;
        window.location.href = "/" + organizatrionAlias + "/subscription"
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        return (
            <>
                <Device onChange={this.onChangee} />
                <NotificationContainer />
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                    <h3 className="heding-text mr-auto">Settings</h3>
                    <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                {!this.state.paidAccount ? <PopUp /> : <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="main-heading main-heading-title py-5">
                                            <h3 className="heding-text">Data Management
                                            </h3>
                                        </div>
                                        <div className="bg-white left-widgetsetings-box mb-foter-Fixed">
                                            <div className="dataManagement-section idle-section all-setting-bg-section-feilds px-5 py-5 mob-card">

                                                <div className="dataMangmentRow  px-5 py-5 my-5 border-bottom">
                                                    <div className="settings-feld form-feilds mb-0">
                                                        <h5 className="card-title mb-5">In compliance with GDPR regulations we've provided some tools to enable customers to have primary control over their data .</h5>
                                                        <div className="dataManegment-row k-flex align-items-center">
                                                            <div className="dataManegment-left">
                                                                <h5 className="card-title mb-1">View and edit your information</h5>
                                                                <p className="card-body-text mb-0">With this option you'll able to view and edit your information</p>
                                                            </div>
                                                            <div className="dataManegment-right ml-auto">
                                                                <button className="btndataMangement" onClick={this.showProfile}>View and edit company details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dataMangmentRow  px-5 py-5 my-5 border-bottom">
                                                    <div className="settings-feld form-feilds mb-0">
                                                        <div className="dataManegment-row k-flex align-items-center">
                                                            <div className="dataManegment-left">
                                                                <h5 className="card-title mb-1">Export Data </h5>
                                                                <p className="card-body-text mb-0">With this option you'll able to export all data of your livechat account.</p>
                                                            </div>
                                                            <div className="dataManegment-right ml-auto">
                                                                {this.state.exportingData ? (<button className="btndataMangement" >Preparing data</button>) : (<button className="btndataMangement" onClick={this.exportData}>Export my data</button>)}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dataMangmentRow  px-5 py-5 my-5 border-bottom">
                                                    <div className="settings-feld form-feilds mb-0">
                                                        <div className="dataManegment-row k-flex align-items-center">
                                                            <div className="dataManegment-left">
                                                                <h5 className="card-title mb-2">Data consent {this.state.userConsent ? (<span className="constantProvided pl-3"><svg xmlns="http://www.w3.org/2000/svg" width="18.89" height="18.89" viewBox="0 0 18.89 18.89">
                                                                    <path id="checked" d="M9.445,0A9.445,9.445,0,1,0,18.89,9.445,9.445,9.445,0,0,0,9.445,0ZM7.712,14.3,3.554,10.138,4.94,8.752l2.773,2.772L13.95,5.286l1.386,1.386Z" fill="#6ac259" />
                                                                </svg>
                                                                    <span className="pl-2">Consent provided</span></span>) : (null)}</h5>
                                                                <p className="card-body-text mb-3">Provide consent for processing your data to show anlytics of your agent.</p>
                                                                <p className="card-body-text mb-1">{this.state.userConsent ? (<input type="checkbox" name="consentChecked_true" onChange={this.handleChange} checked={true} />) : (<input type="checkbox" name="consentChecked" value={this.state.consentChecked} onChange={this.handleChange} checked={this.state.consentChecked} />)}
                                                                    <span className="pl-2">I hereby agree that my personal data may be processed for the needs of automated individual decision making</span></p>
                                                            </div>
                                                            <div className="dataManegment-right ml-auto">
                                                                {this.state.userConsent ? (<button className="btndataMangement" onClick={this.revokeConsent}>Revoke consent</button>) : (
                                                                    this.state.consentChecked ? (<button className="btndataMangement" onClick={this.agreeConsent}>I agree</button>) : <button className="btndataMangement" disabled={true}>I agree</button>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataMangmentRow  px-5 py-5 my-5 border-bottom">
                                                    <div className="settings-feld form-feilds mb-0">
                                                        <div className="dataManegment-row k-flex align-items-center">
                                                            <div className="dataManegment-left">
                                                                <h5 className="card-title mb-1">Deactivate account </h5>
                                                                <p className="card-body-text mb-0">With this option you can deactivate your account. You can reactivate it anytime you want by just logging into your account.</p>
                                                            </div>
                                                            <div className="dataManegment-right ml-auto">
                                                                <button className="btndataMangement" onClick={this.deactivateAccount}>Deactivate my account</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="dataMangmentRow  px-5 py-5 my-5 border-bottom">
                                                    <div className="settings-feld form-feilds mb-0">
                                                        <div className="dataManegment-row k-flex align-items-center">
                                                            <div className="dataManegment-left">
                                                                <h5 className="card-title mb-1">Delete account </h5>
                                                                <p className="card-body-text mb-0">With this option you can delete your account.</p>
                                                            </div>
                                                            <div className="dataManegment-right ml-auto">
                                                                <button className="btndataMangement" onClick={this.deleteAccount}>Delete account</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {/* Masking div start here */}
                {this.state.showRevokeConsent || this.state.deactivateAccountShow || this.state.deleteAccountShow || this.state.showDeletePop || this.state.showExportPopup || this.state.deactivatePaidAccountShow || this.state.deletePaidAccountShow ? (<div id="shadowMasking" className="popupShadow" style={{ display: 'block' }}></div>) : (null)}
                {/* Masking div ends here */}

                {/* Revoke Consent popup start here */}
                {this.state.showRevokeConsent ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="popupHeader py-4 px-5 k-flex align-items-center">
                        <h6 className="card-title mb-0 white-text">Confirm</h6>
                        <span className="close-popup ml-auto cursor-pointer" onClick={this.hideRevokeConsent}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
                    </div>
                    <div className="popupBody py-5 px-5 my-4 mx-4 popup-scroll">
                        <div className="feilds-instruction text-left">
                            <p className="card-body-text pb-4 text-black">Are you sure you wish to revoke your consent?</p>
                            <p className="card-body-text pb-4 text-black"><strong>Note:</strong> if you revoke your consent then we won't be able to show analytics about your agent's performance</p>
                        </div>
                        <div className="text-left">
                            <button className="btnBlueSettings mr-3" onClick={this.confirmRevokeConsent}>Yes</button>
                            <button className="btnWhiteSettings " onClick={this.hideRevokeConsent}>No</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Revoke Consent popup ends here */}

                {/* Export data popup start here */}
                {this.state.showExportPopup ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <p className="text-right px-5 pt-5"><span className="cursor-pointer" onClick={this.hideExportPopup} >
                        <svg id="Group_1947" data-name="Group 1947" xmlns="http://www.w3.org/2000/svg" width="13.171" height="13.171" viewBox="0 0 13.171 13.171">
                            <rect id="Rectangle_42" data-name="Rectangle 42" width="2.661" height="15.965" rx="1.33" transform="translate(0 1.882) rotate(-45)" fill="#1c223a" />
                            <rect id="Rectangle_43" data-name="Rectangle 43" width="2.661" height="15.965" rx="1.33" transform="translate(11.289 0) rotate(45)" fill="#1c223a" />
                        </svg>
                    </span></p>
                    <div className="popupBody pb-5 px-5 my-4 mx-4">
                        <div className="feilds-instruction text-left">
                            <p className="card-body-text pb-4 text-black">Data of your livechat account is being prepared for export. Once it is ready it will be sent to your registered email - {this.agent.email}</p>
                        </div>
                        <div className="text-center">
                            <button className="btnBlueSettings" onClick={this.hideExportPopup}>Ok</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Export data popup ends here */}

                {/* Deactivate account popup for free start here */}
                {this.state.deactivateAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="popupHeader py-4 px-5 k-flex align-items-center">
                        <h6 className="card-title mb-0 white-text">Confirm</h6>
                        <span className="close-popup ml-auto cursor-pointer" onClick={this.cancelDeactivateAccount}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
                    </div>

                    <div className="popupBody py-5 px-5 my-4 mx-4">
                        <div className="pb-5 feilds-instruction">
                            <p className="card-body-text pb-4 text-black">Are you sure you want to deactivate your account?</p>
                        </div>
                        <div className="text-left">
                            <button className="btnBlueSettings mr-3" onClick={this.confirmDeactivateAccount}>Yes</button>
                            <button className="btnWhiteSettings" onClick={this.cancelDeactivateAccount}>No</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Deactivate account popup ends here */}

                {/* Deactivate account popup for paid start here */}
                {this.state.deactivatePaidAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="k-flex align-items-center">
                        <span className="px-2 py-2 close-popup ml-auto cursor-pointer" onClick={() => { this.setState({ deactivatePaidAccountShow: false }) }}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="30.41" height="30.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
                    </div>
                    <div className="popupBody pb-5 px-5 my-4 mx-4">
                        <div className="pb-5 feilds-instruction">
                            <p className="card-body-text pb-4 text-black">To deactivate your account you need to cancel your subscription.</p>
                        </div>
                        <div className="text-center">
                            <button className="btnBlueSettings mr-3" onClick={this.gotoSubscription}>Go to subscription section.</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Deactivate account popup ends here */}

                {/* delete account popup for paid start here */}
                {this.state.deletePaidAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="k-flex align-items-center">
                        <span className="px-2 py-2 close-popup ml-auto cursor-pointer" onClick={() => { this.setState({ deletePaidAccountShow: false }) }}><svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="30.41" height="30.41" viewBox="0 0 16.41 16.41"><path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" /><g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)"><rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" /><rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" /></g></svg></span>
                    </div>
                    <div className="popupBody pb-5 px-5 my-4 mx-4">
                        <div className="pb-5 feilds-instruction">
                            <p className="card-body-text pb-4 text-black">To delete your account you need to cancel your subscription.</p>
                        </div>
                        <div className="text-center">
                            <button className="btnBlueSettings mr-3" onClick={()=>{
                                window.location.href = "https://accounts.appypie.com/privacy/subscriptions"
                            }}>Go to subscription section.</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Deactivate account popup ends here */}

                {/* Delete account popup start here */}
                {this.state.deleteAccountShow ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="popupBody py-5 px-5 my-5 mx-4">
                        <div className="feilds-instruction">
                            <p className="card-body-text text-black">Enter OTP sent your email ID to complete verification process.</p>
                            {this.state.otpError !== '' ? (<><p className="error" style={{ marginTop: 10, marginBottom: -8 }}><img alt="" src={require("../../../assets/images/danger.png")} style={{ height: 20 }} /> {this.state.otpError}</p></>) : (null)}
                        </div>
                        <div className="deleteOTPfeilds py-5">
                            <input type="text" placeholder="Enter OTP" className="w-100" name="password" value={this.state.password} onChange={this.handlePassword} autoComplete="off" />
                        </div>
                        <div>
                            <><button className="btnBlueSettings mr-2" onClick={this.confirmPassword}>Submit</button><button className="btnBlueSettings mr-2" onClick={() => { this.sendOtp(true) }}>Re-Send OTP</button></>
                            <button className="btnWhiteSettings" onClick={this.cancelDeleteAccount}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}
                {/* Delete account popup ends here */}

                {/* Delete Reasons div starts here */}
                {this.state.showDeletePop ? (<div className="rightPanelSection add-agent" >
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Delete Account</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.cancelDeletePopup}>
                                            <img alt="" src={require('../../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5 deleteAccountChoose">
                                    <div className="form-feilds px-5 py-4">
                                        <h5 className="card-title">Please provide a reason for choosing to delete your account.</h5>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        {this.state.deleteReasons.map((i, k) => {
                                            let descriptionId = "description_" + i.name;
                                            return <div className="form-feilds px-5 py-4" key={k}>
                                                <div className="wrapperInfo">
                                                    <p className="card-body-text"><input type="radio" name="delete_reason" value={i.name} style={{ width: 'auto', height: 'auto', margin: '6px 4px' }} onChange={this.handleReason} /> <span className="pl-2">{i.value}</span></p>
                                                    <textarea rows="4" name={descriptionId} id={descriptionId} style={{ display: 'none' }} onChange={this.handleDescription}></textarea>
                                                </div>
                                            </div>
                                        })}
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                {this.state.delete_reason === '' ? (<button className="mr-3 btnRestore" disabled={true}>Delete</button>) : (<button className="mr-2 btnBlueSettings" onClick={this.confirmDeleteAccount}>Delete</button>)}
                                                <button className="btnWhiteSettings" onClick={this.cancelDeletePopup}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (null)}

                {/* Delete Reasons div ends here */}

                <Profile showProfilePage={this.state.showProfilePage} hideProfilePage={this.hideProfilePage} />
            </>
        )
    }
}

export default DataManagement;