import React from "react";
import LeftPanel from "./views/LeftPanel";
// import db from "../../config/firebase";
import { Link } from "react-router-dom";
import { releaseNumber } from '../../modules/livechat/components/Twilio';
import { getDateInUTC } from '../../modules/livechat/components/Comman';
import firebaseServices from "../../firebase";

class Phonenumbers extends React.Component {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};

    constructor() {
        super();
        if(this.agent.roleType!=='Owner') {
            window.location.href = "/"+this.agent.ownerIdentifire;
        }
        this.state = {
            loader: false,
            numberList: [],
            error_message: null,
            showConfirmWindow: false,
            selectedNumber: false
        }
    }

    getAgentDetail = (agentId) => {
        return new Promise(resolve => {
            debugger;
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(agentId).get().then(agent => {
                if (agent.exists) {
                    var _data = agent.data();
                    resolve(_data.name);
                } else {
                    resolve("");
                }
            }).catch(errrr => {
                resolve("");
            });
        });
    }

    getPhoneNumbers = () => {
        this.setState({
            loader: true
        })
        var allPhones = [];
        debugger;
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('phone_numbers').where("agent_id", "!=", "released").get().then(async (phonenumbers) => {
            for (const index222 in phonenumbers.docs) {
                const change22 = phonenumbers.docs[index222];
                let vist = change22.data();
                vist.id = change22.id;
                /* get agent name start */
                if (vist.agent_id && vist.agent_id.length > 3) {
                    debugger;
                    vist.agent_name = await this.getAgentDetail(vist.agent_id);
                }
                /* get agent name end */
                allPhones.push(vist);
            }
            this.setState({ numberList: allPhones, loader: false });
        });
    }

    componentDidMount() {

        console.log(this.agent)
        if (this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        this.getPhoneNumbers();
    }

    deleteConfirm = (numberObj) => {
        if (this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        if (this.state.showConfirmWindow) {
            this.cancelDelete();
        } else {
            this.setState({
                showConfirmWindow: true,
                selectedNumber: numberObj
            });
        }
    }

    cancelDelete = () => {
        this.setState({
            showConfirmWindow: false,
            selectedNumber: false
        });
    }

    deleteNumber = async () => {
        if (this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        this.setState({
            loader: true
        });
        const numData = this.state.selectedNumber;
        var res = await releaseNumber(numData.sid);
        if (res.status === "success") {
            let serverTimestamp = await getDateInUTC();
            if(numData.agent_id !== 0 && numData.agent_id !== "released") {
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(numData.agent_id).update({ phone_number: "", updatedon : serverTimestamp });
            }
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('phone_numbers').doc(numData.id).update({
                agent_id : "released",
                status: "released",
                updatedon : serverTimestamp,
                released_info: {
                    agent_id: this.agent.agentId,
                    date: serverTimestamp,
                    agent_name: this.agent.agent_name,
                }
            }).then(reddd => {
                this.getPhoneNumbers();
                this.setState({
                    showConfirmWindow: false,
                    selectedNumber: false
                });
            });
        } else {
            this.setState({
                loader: false,
                showConfirmWindow: false,
                selectedNumber: false,
                error_message: res.data
            });
        }
    }
    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        return (
            <>
                {this.state.showConfirmWindow ? (<div className="popupInstallLivechat popupLivechat" >
                    <div className="popupBody pb-5 px-5 text-center">
                        <div className="py-5 feilds-instruction">
                            <span className="congratilation-circle my-2">
                                <img alt="" src={require('../../assets/img/icon/info.png')} />
                            </span>
                        </div>
                        <div className="pb-5 feilds-instruction">
                            <h6 className="card-title mb-0">Are you sure? You want to release this number.</h6>
                        </div>
                        <div>
                            <button className="btnBlue" onClick={this.deleteNumber}>Release</button> &nbsp;&nbsp;
                            <button className="btnBlue" onClick={this.cancelDelete}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                    <h3 className="heding-text">Phone Numbers</h3>
                    <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8 mb-11">
                                    <div className="body-section bg-white px-5 py-5">
                                        <div className="agent-section " id="resposnive-table-class">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="agent-table-header table-header row mb-4">
                                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                                        <div className="aside-agent k-flex align-items-center justify-content-end">
                                                            <div className="aside-agent k-flex align-items-center mr-5">
                                                                <Link to={"/" + this.agent.ownerIdentifire + "/buy-number"} title="Get Number">Get Number</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.error_message ? (<div className="error">{this.state.error_message}</div>) : (null)}
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="agent-table">
                                                    <table className="table table-borderless mb-0 liveCgatUserTable">
                                                        <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <th>Country</th>
                                                                <th>Agent</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="outline-1-black border-radius-table">
                                                            {this.state.numberList.map((v, i) => {
                                                                return <tr id={v.id} key={i}>
                                                                    <td>{v.phone}</td>
                                                                    <td>{v.country_iso}</td>
                                                                    <td>{v.agent_name ? v.agent_name : "N/A"}</td>
                                                                    <td>
                                                                        <button className="btnBlueSettings mr-2 py-2 px-6" onClick={() => { this.deleteConfirm(v) }}>Release</button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                            {this.state.numberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Phonenumbers;