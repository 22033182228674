import React, { Component } from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import LiveChat from "../modules/livechat/index";
import Agents from "../modules/agents/index";
import AgentLogin from "../modules/agents/login";
import Department from "../modules/agents/Department";
import Roles from "../modules/agents/Roles";
import Settings from "../modules/settings/Settings";
import Gdpr from "../modules/settings/GdprConsent";
import PreviousChat from "../modules/settings/PreviousChat";
import IdleTime from "../modules/settings/IdleTime";
import Notifications from "../modules/settings/Notifications";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";/*added by ruchi */
import DeactivatedRoute from "./DeactivatedRoute";
import Logout from "./Logout";
import Chathistory from "../modules/chathistory/index";
// import CompanyInfoComponent   from   './../modules/organization/CompanyInfoComponent'
import CompanyInfoComponentStep1 from '../modules/organization/CompanyInfoComponentStep1'
// import CompanyInfoComponentStep3   from   './../modules/organization/CompanyInfoComponentstep3'
// import CompanySetupComponent   from   './../modules/organization/CompanySetupComponent'
import ComapanyWidgetInitialSetup from '../modules/organization/ComapanyWidgetInitialSetup'
import CompanyRegistration from './../modules/organization/CompanyRegistration'
import CompanyVerificationComponent from './../modules/organization/CompanyVerificationComponent'
//import  ForgetPasswordComponent   from   './../modules/organization/ForgetPasswordComponent'
import LoginComponent from './../modules/organization/LoginComponent'
import AgentVerification from '../modules/agents/AgentVerification'
import AgentResetPassword from '../modules/agents/AgentResetPassword'
import CommonSession from '../modules/organization/CommonSession'
import VerifyEmail from '../modules/organization/VerifyEmail'

import BannedUsers from '../modules/settings/bannedUsers/index';
import Chatformsettings from './../modules/chatformsetting/index';
import Addroledes from './../modules/agents/addRolePage';
import EditRole from './../modules/agents/EditRolePage';
import WidgetForm from './../modules/chatformsetting/widgetCustamization';
import Setup from './../modules/chatformsetting/setup';
import Thankyou from './../modules/chatformsetting/thankyou';

import AgentProfileComponent from '../modules/organization/AgentProfileComponent'
import WidgetSetupComponent from './../modules/organization/WidgetSetupComponent'
import WidgetSuccessComponent from './../modules/organization/WidgetSuccessComponent'
import UnsubscribeComponent from './../modules/unsubscribe/index';
import notFound from './../modules/notfound/index';
// import WidgetInstallationComponent   from   './../modules/organization/WidgetInstallationComponent'
// import WidgetNotInstall   from   './../modules/organization/WidgetNotInstallComponent'

//import '../assets/css/layout.css'
// import SidebarNavigation from "./sidebarNavigation";
import Subscription from './../modules/settings/Subscription'
import Paymentsuccess from './../modules/settings/Paymentsuccess'
import Paymentfail from './../modules/settings/Paymentfail'
import DataManagement from './../modules/settings/DataManagement/index';
import EmailPreference from './../modules/settings/EmailPreferances/index';
import DeactivatedAccount from './../modules/deactivatedAccount/index';
import GlobinLoginComponent from './../modules/globalLogin/index';
import Phonenumbers from "../modules/settings/Phonenumbers";
import Searchnumbers from "../modules/settings/PhoneNumbers/index";
import Agentnotifications from "../modules/settings/Agentnotifications";
import Analytics from "../modules/analytics";
import TestDrive from "../modules/testDrive/index";
import agentsReport from "../modules/analytics/agentsReport";
import CallTextSettings from "../modules/settings/CallTextSettings/index";
import agentsLeaderboard from "../modules/analytics/agentsLeaderboard";
import chatAnalytics from "../modules/analytics/chatAnalytics";
import fbMessenger from "../modules/settings/fbMessenger";
import callbackFbMessenger from "../modules/settings/callbackFbMessenger";
import CannedText from "../modules/settings/cannedtext";
import Triggers from "../modules/settings/triggers/index";
import Testsetup from "../modules/chatformsetting/view/Testsetup";
import UpgradeAccpunt from "../modules/upgradeAccount";
import Integration from "../modules/integration";
import Rb2b from "../modules/Rb2b"
import Ticketing from "../modules/settings/Ticketing";
import verifyConnect from "../modules/connect/verify";
import authorizeLivechat from "../modules/oauth/authorize";
import autoCompanyInfo from '../modules/organization/AutoCompanyInfo';
import callbackInstaMessenger from "../modules/settings/callbackInstaMessenger";
import HandsOffChatTranscript from "../modules/settings/HandoffChatsTranscripts";
import TotalUsers from "../modules/analytics/totalUsers";
import AgentsNewleaderboard from "../modules/analytics/agentsLeaderboardNew";
import { checkSession } from "../modules/organization/Model/DbModel";
import { deleteFilters } from "../modules/livechat/components/Dblovefield";
import ClaimTrial from "../modules/upgradeAccount/claim-trial";
import Testwidget from "../modules/testDrive/test-widget";

class Layout extends Component {

  constructor(props) {
    super(props);
    this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    this.state = {
      functionNmae: "",
      showDetail: false,
      Ploader: false,
      agents_products: {},
      show_hide: false,
      AgentDeleted: false
    };
  }
  componentDidMount() {
    //  this.checkSession();
    setInterval(() => {
      checkSession()
        .then((result) => {
          console.log(result);
          if (result === false && this.agent && this.agent.agentId) {
            //show popup alert
            this.setState({ show_hide: true });
          } // true or false
          return result;
        })
        .catch((error) => {
          console.error(error);
        });
    }, 30000)
    setInterval(() => {
      const cookieEnabled = this.checkCookie();
      if (!cookieEnabled) {
        window.location.reload();
      }
    }, 3000);
  }

  checkCookie = () => {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie") != -1;
    }
    return cookieEnabled;
  }




  render() {

    var className = "layout-not-liveChat";
    var organization_alias = this.agent.ownerIdentifire ? this.agent.ownerIdentifire : this.agent.organization_alias;
    organization_alias = organization_alias ? organization_alias.trim() : "";
    if (window.location.pathname === '/' + organization_alias) {
      className = 'layout';
    }
    return (
      <div id="maintoproutediv" className={this.props.headerStrip ? `${className} headerStrip` : `${className}`}>

        {/* Phone call start */}
        <div style={{ display: "none" }} id="mainPhoneCall" className="calling-layout header-calling-icon ml-3 header-calling-icon2">
          <div style={{ display: "none" }} id="acceptRejectCall" className="phone-buttons">
            <span className="calling_pra_header"><span className="mr-3">
              <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="20.874" height="20.874" viewBox="0 0 27.874 27.874">
                <g id="Group_2755" data-name="Group 2755" transform="translate(13.937 7.433)">
                  <g id="Group_2754" data-name="Group 2754">
                    <path id="Path_2242" data-name="Path 2242" d="M240.929,128a.929.929,0,0,0,0,1.858,3.72,3.72,0,0,1,3.717,3.717.929.929,0,0,0,1.858,0A5.582,5.582,0,0,0,240.929,128Z" transform="translate(-240 -128)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2757" data-name="Group 2757" transform="translate(13.937 3.717)">
                  <g id="Group_2756" data-name="Group 2756">
                    <path id="Path_2243" data-name="Path 2243" d="M240.929,64a.929.929,0,0,0,0,1.858,7.441,7.441,0,0,1,7.433,7.433.929.929,0,1,0,1.858,0A9.3,9.3,0,0,0,240.929,64Z" transform="translate(-240 -64)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2759" data-name="Group 2759" transform="translate(13.937)">
                  <g id="Group_2758" data-name="Group 2758">
                    <path id="Path_2244" data-name="Path 2244" d="M240.929,0a.929.929,0,0,0,0,1.858,11.161,11.161,0,0,1,11.15,11.15.929.929,0,1,0,1.858,0A13.023,13.023,0,0,0,240.929,0Z" transform="translate(-240)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2761" data-name="Group 2761" transform="translate(0 1.858)">
                  <g id="Group_2760" data-name="Group 2760">
                    <path id="Path_2245" data-name="Path 2245" d="M25.087,50.583a17.247,17.247,0,0,1-7.638-1.745.912.912,0,0,0-.712-.043.937.937,0,0,0-.533.474l-1.461,3.023a23.37,23.37,0,0,1-9.016-9.016l3.022-1.464a.928.928,0,0,0,.429-1.245,17.247,17.247,0,0,1-1.745-7.638A.929.929,0,0,0,6.5,32H.929A.929.929,0,0,0,0,32.929,25.115,25.115,0,0,0,25.087,58.016a.929.929,0,0,0,.929-.929V51.512A.929.929,0,0,0,25.087,50.583Z" transform="translate(0 -32)" fill="#2c3149" />
                  </g>
                </g>
              </svg>
            </span>
              <span className="callingMSG">New incoming call from </span><span id="contact_number">+919999999999</span></span>
            <button className="btn-calling main-btn-accept">
              <span className="mr-3">
                <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="22.94" height="22.94" viewBox="0 0 31.94 31.94">
                  <g id="Group_4586" data-name="Group 4586" transform="translate(15.97 8.517)">
                    <g id="Group_4585" data-name="Group 4585">
                      <path id="Path_2554" data-name="Path 2554" d="M241.065,128a1.065,1.065,0,0,0,0,2.129,4.263,4.263,0,0,1,4.259,4.259,1.065,1.065,0,1,0,2.129,0A6.4,6.4,0,0,0,241.065,128Z" transform="translate(-240 -128)" fill="#fff" />
                    </g>
                  </g>
                  <g id="Group_4588" data-name="Group 4588" transform="translate(15.97 4.259)">
                    <g id="Group_4587" data-name="Group 4587">
                      <path id="Path_2555" data-name="Path 2555" d="M241.065,64a1.065,1.065,0,1,0,0,2.129,8.526,8.526,0,0,1,8.517,8.517,1.065,1.065,0,0,0,2.129,0A10.659,10.659,0,0,0,241.065,64Z" transform="translate(-240 -64)" fill="#fff" />
                    </g>
                  </g>
                  <g id="Group_4590" data-name="Group 4590" transform="translate(15.97)">
                    <g id="Group_4589" data-name="Group 4589">
                      <path id="Path_2556" data-name="Path 2556" d="M241.065,0a1.065,1.065,0,0,0,0,2.129,12.789,12.789,0,0,1,12.776,12.776,1.065,1.065,0,1,0,2.129,0A14.923,14.923,0,0,0,241.065,0Z" transform="translate(-240 0)" fill="#fff" />
                    </g>
                  </g>
                  <g id="Group_4592" data-name="Group 4592" transform="translate(0 2.129)">
                    <g id="Group_4591" data-name="Group 4591">
                      <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                    </g>
                  </g>
                </svg>
              </span>Accept</button>
            <button className="btn-calling main-btn-reject ml-3">
              <span className="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                  <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                    <g id="Group_4591" data-name="Group 4591">
                      <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                    </g>
                  </g>
                </svg>
              </span>Reject</button>
          </div>
          <div style={{ display: "none" }} id="callInProgress" className="phone-buttons">
            <span className="calling_pra_header"><span className="mr-3">
              <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="20.874" height="20.874" viewBox="0 0 27.874 27.874">
                <g id="Group_2755" data-name="Group 2755" transform="translate(13.937 7.433)">
                  <g id="Group_2754" data-name="Group 2754">
                    <path id="Path_2242" data-name="Path 2242" d="M240.929,128a.929.929,0,0,0,0,1.858,3.72,3.72,0,0,1,3.717,3.717.929.929,0,0,0,1.858,0A5.582,5.582,0,0,0,240.929,128Z" transform="translate(-240 -128)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2757" data-name="Group 2757" transform="translate(13.937 3.717)">
                  <g id="Group_2756" data-name="Group 2756">
                    <path id="Path_2243" data-name="Path 2243" d="M240.929,64a.929.929,0,0,0,0,1.858,7.441,7.441,0,0,1,7.433,7.433.929.929,0,1,0,1.858,0A9.3,9.3,0,0,0,240.929,64Z" transform="translate(-240 -64)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2759" data-name="Group 2759" transform="translate(13.937)">
                  <g id="Group_2758" data-name="Group 2758">
                    <path id="Path_2244" data-name="Path 2244" d="M240.929,0a.929.929,0,0,0,0,1.858,11.161,11.161,0,0,1,11.15,11.15.929.929,0,1,0,1.858,0A13.023,13.023,0,0,0,240.929,0Z" transform="translate(-240)" fill="#2c3149" />
                  </g>
                </g>
                <g id="Group_2761" data-name="Group 2761" transform="translate(0 1.858)">
                  <g id="Group_2760" data-name="Group 2760">
                    <path id="Path_2245" data-name="Path 2245" d="M25.087,50.583a17.247,17.247,0,0,1-7.638-1.745.912.912,0,0,0-.712-.043.937.937,0,0,0-.533.474l-1.461,3.023a23.37,23.37,0,0,1-9.016-9.016l3.022-1.464a.928.928,0,0,0,.429-1.245,17.247,17.247,0,0,1-1.745-7.638A.929.929,0,0,0,6.5,32H.929A.929.929,0,0,0,0,32.929,25.115,25.115,0,0,0,25.087,58.016a.929.929,0,0,0,.929-.929V51.512A.929.929,0,0,0,25.087,50.583Z" transform="translate(0 -32)" fill="#2c3149" />
                  </g>
                </g>
              </svg>
            </span>
              <span className="callingMSG">Ongoing call with </span><span id="contact_number2">+919999999999</span></span>
            <button className="btn-calling main-btn-reject ml-2">
              <span className="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                  <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                    <g id="Group_4591" data-name="Group 4591">
                      <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                    </g>
                  </g>
                </svg>
              </span>Hang Up
            </button>
            <button style={{ display: "none" }} id="unmuteAudio" className="btn-calling btn-mute ml-2">
              <span className="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.467" height="28.877" viewBox="0 0 32.467 33.877">
                  <g id="Group_4599" data-name="Group 4599" transform="translate(13554 -336)">
                    <g id="no-sound" transform="translate(-13554 336)">
                      <g id="Group_4594" data-name="Group 4594" transform="translate(9.882 0.001)">
                        <g id="Group_4593" data-name="Group 4593">
                          <path id="Path_2558" data-name="Path 2558" d="M167.772.008h-2.823a4.946,4.946,0,0,0-4.941,4.941V19.063a4.873,4.873,0,0,0,.185,1.266L172.712,7.241V4.949A4.946,4.946,0,0,0,167.772.008Z" transform="translate(-160.008 -0.008)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                        <g id="Group_4595" data-name="Group 4595">
                          <path id="Path_2559" data-name="Path 2559" d="M25.482,24H28.3a4.946,4.946,0,0,0,4.941-4.941V11.318L42.93,1.193A.706.706,0,0,0,41.91.217L10.855,32.684a.706.706,0,0,0,1.021.975l8.066-8.433a6.257,6.257,0,0,0,4.129,1.592h2.117v5.646H21.247a.706.706,0,1,0,0,1.412H32.54a.706.706,0,1,0,0-1.412H27.6V26.819h2.117a6.36,6.36,0,0,0,6.352-6.352V13.409a.706.706,0,0,0-1.412,0v7.058a4.946,4.946,0,0,1-4.941,4.941H24.07a4.844,4.844,0,0,1-3.158-1.2l1.379-1.441A4.843,4.843,0,0,0,25.482,24Z" transform="translate(-10.659 0)" fill="#fff" />
                        </g>
                      </g>
                      <g id="Group_4598" data-name="Group 4598" transform="translate(7.059 12.704)">
                        <g id="Group_4597" data-name="Group 4597">
                          <path id="Path_2560" data-name="Path 2560" d="M118.753,199.751v-7.058a.706.706,0,1,0-1.412,0v7.058a6.31,6.31,0,0,0,.5,2.475l1.11-1.16A4.869,4.869,0,0,1,118.753,199.751Z" transform="translate(-117.341 -191.987)" fill="#fff" />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>Unmute
            </button>
            <button id="muteAudio" className="btn-calling btn-mute ml-2">
              <span><img alt="Mute" src={require('../assets/images/umute.svg')} /></span> Mute
            </button>
          </div>
        </div>
        {/* Phone call end */}

        {/* <div className="popupShadow"></div> */}

        <div className="loading loadingMain" id="GlobalLoader" style={{ display: 'none' }}>Loading&#8230;</div>
        {this.state.show_hide && (window.location.pathname === '/' + organization_alias) ? <div class="popupShadowSession" style={{ display: this.state.show_hide ? "block" : "none" }}></div> : null}
        {this.state.show_hide && (window.location.pathname === '/' + organization_alias) ? <div class="session-expire" style={{ display: "block" }}>
          <h4>Session Expired</h4>
          <p>Your session has expired.</p>
          <p>Please refresh the page to continue working.</p>
          <button class="ref-btn" onClick={() => {
            this.setState({ show_hide: false });
            window.location.reload();
          }}><svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35.426 45">
              <path id="Icon_material-refresh" data-name="Icon material-refresh"
                d="M36.234,11.206a17.727,17.727,0,1,0,4.608,16.949H36.234A13.287,13.287,0,1,1,23.717,10.431a13.1,13.1,0,0,1,9.349,3.944l-7.134,7.134H41.441V6Z"
                transform="translate(-6.015 -6)" fill="#333" />
            </svg> Refresh</button>
        </div> : null}
        <Switch>
          <PublicRoute exact path="/auto-company-info" component={autoCompanyInfo}></PublicRoute>
          <PublicRoute exact path="/" component={CompanyInfoComponentStep1}></PublicRoute>
          {/* Self hosted Global login is hidden */}
          {/* <Route exact path="/salesgetin" component={GlobinLoginComponent} /> */}
          <Route exact path="/:ownerIden/test-drive" component={TestDrive} /> 
          <Route exact path="/:ownerIden/livechat" component={Testwidget} /> 

          <PublicRoute exact path="/common-session" component={CommonSession}></PublicRoute>
          <PublicRoute exact path="/verify-email" component={VerifyEmail}></PublicRoute>

          <PublicRoute exact path="/login" component={LoginComponent}></PublicRoute>
          <PublicRoute exact path="/company-info" component={CompanyInfoComponentStep1}></PublicRoute>
          <PublicRoute exact path="/customize-widget" component={ComapanyWidgetInitialSetup}></PublicRoute>
          <Route exact path="/agent-setup" component={AgentProfileComponent} ></Route>
          <PublicRoute exact path="/widget-setup" component={WidgetSetupComponent}></PublicRoute>
          <PublicRoute exact path="/widget-success" component={WidgetSuccessComponent}></PublicRoute>
          <Route exact path="/:organization_name/agent-verification/:token" component={AgentVerification}></Route>
          <Route exact path="/:organization_name/agent-reset-password" component={AgentResetPassword}></Route>

          <PrivateRoute exact path='/:ownerIden/subscription' component={Subscription} />
          <PrivateRoute exact path='/:ownerIden/paymentsuccess' component={Paymentsuccess} />
          <PrivateRoute exact path='/:ownerIden/paymentfail' component={Paymentfail} />
          <PrivateRoute exact path='/:ownerIden' component={LiveChat} />
          <PrivateRoute exact path='/:ownerIden/chathistory' component={Chathistory} />
          <PrivateRoute exact path="/:ownerIden/agents" component={Agents} />
          <PrivateRoute exact path="/:ownerIden/departments" component={Department} />
          <PrivateRoute exact path="/:ownerIden/roles" component={Roles} />
          <PrivateRoute exact path="/:ownerIden/settings" component={Settings} />
          <PrivateRoute exact path="/:ownerIden/gdpr-consent" component={Gdpr} />
          {/* <PrivateRoute exact path="/:ownerIden/previous-chat" component={PreviousChat} /> */}
          <PrivateRoute exact path="/:ownerIden/idle-time" component={IdleTime} />
          <PrivateRoute exact path="/:ownerIden/notifications" component={Notifications} />
          <PrivateRoute exact path="/:ownerIden/chatsettingform" component={Chatformsettings} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/addroledesign" component={Addroledes} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/widgetsetting" component={WidgetForm} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/install-widget" component={Setup} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/thankyou" component={Thankyou} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/add-roles" component={Addroledes} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/roles/:id" component={EditRole} />
          <PrivateRoute exact path="/:ownerIden/banned-users" component={BannedUsers} ></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/manage-webhooks" component={HandsOffChatTranscript} ></PrivateRoute>
          <PublicRoute exact path="/:ownerIden/login" component={AgentLogin}></PublicRoute>
          <Route exact path="/:ownerIden/logout" component={Logout}></Route>
          <Route exact path="/unsubscribe/:email" component={UnsubscribeComponent}></Route>
          <PrivateRoute exact path="/:ownerIden/data-management" component={DataManagement}></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/email-preference" component={EmailPreference}></PrivateRoute>
          <PrivateRoute exact path="/:ownerIden/phone-numbers" component={Phonenumbers} />
          <PrivateRoute exact path="/:ownerIden/buy-number" component={Searchnumbers} />
          <PrivateRoute exact path="/:ownerIden/my-notifications" component={Agentnotifications} />
          <PrivateRoute exact path="/:ownerIden/analytics" component={Analytics} />
          <PrivateRoute exact path="/:ownerIden/agentsreport" component={agentsReport} />
          <PrivateRoute exact path="/:ownerIden/call-text-settings" component={CallTextSettings} />
          <PrivateRoute exact path="/:ownerIden/agentsleaderboard" component={agentsLeaderboard} />
          <PrivateRoute exact path="/:ownerIden/agentsleaderboardnew" component={AgentsNewleaderboard} />
          <PrivateRoute exact path="/:ownerIden/chatAnalytics" component={chatAnalytics} />
          <PrivateRoute exact path="/:ownerIden/totalUsers" component={TotalUsers} />
          <PrivateRoute exact path="/:ownerIden/install-fb-messenger" component={fbMessenger} />
          <PrivateRoute exact path="/:ownerIden/canned-text" component={CannedText} />
          <PrivateRoute exact path="/:ownerIden/triggers" component={Triggers} />
          {/* <PrivateRoute exact path="/:ownerIden/test-setup" component={Testsetup} /> */}
          <PrivateRoute exact path="/:ownerIden/integration" component={Integration} />
          <PrivateRoute exact path="/:ownerIden/integration/:type" component={Integration} />
          <PrivateRoute exact path="/:ownerIden/ticketing" component={Ticketing} />
          <PublicRoute exact path="/:ownerIden/rb2b" component={Rb2b} />
          <Route exact path="/connect/verify" component={verifyConnect} />

          <Route exact path="/cb-fb-messenger/pagesetup" component={callbackFbMessenger} />
          <Route exact path="/cb-insta-messenger/pagesetup" component={callbackInstaMessenger} />
          <Route exact path="/:ownerIden/upgrade-account" component={UpgradeAccpunt}></Route>
          <Route exact path="/:ownerIden/claim-trial" component={ClaimTrial}></Route>

          <Route exact path="/v1/oauth/authorize" component={authorizeLivechat} />

          <DeactivatedRoute exact path="/:ownerIden/account-deactivated" component={DeactivatedAccount}></DeactivatedRoute>

          <Route path='*' exact={true} component={notFound} />
          
        </Switch>
      </div>
    );

  }
}

export default Layout
