import React, { useEffect, useState } from "react";
import firebaseServices from "../firebase";
import TextField from '@material-ui/core/TextField';
import SidebarNavigation from "../layout/sidebarNavigation";
import moment from "moment";
const Rb2b = () => {
  const [rB2bData, setRB2bData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [item , setItem] = useState(-1)
  
  useEffect(() => {
    const today = new Date();
    const past7Days = new Date();
    past7Days.setDate(today.getDate() - 7);
    
    const formattedToday = today.toISOString().split('T')[0];
    const formattedPast7Days = past7Days.toISOString().split('T')[0];
    
    setFromDate(formattedPast7Days);
    setToDate(formattedToday);
    
    // Fetch the data on initial load
    fetchData(formattedPast7Days, formattedToday);
  }, []);
  // useEffect(() => {
  //   console.log(item,"0-0-=-0--=0-0-");
  //   if (item !== "") {
  //     // Trigger the modal programmatically after state update
  //     const modal = document.getElementById("viewModal");
  //     if (modal) {
  //       $(modal).modal("show"); // Using Bootstrap's modal function
  //     }
  //   }
  // }, [item]);
  useEffect(() => {
    if (item !== "") {
      // Trigger the modal programmatically after state update
      const modal = document.getElementById("viewModal");
      if (modal) {
        const bootstrapModal = new window.bootstrap.Modal(modal);  // Using Bootstrap's Modal API
        bootstrapModal.show();
      }
    }
  }, [item]);

  // Fetch the data whenever fromDate or toDate changes
  useEffect(() => {
    if (fromDate && toDate) {
      fetchData(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  const fetchData = async (from, to) => {

    try {
      const snapshot = await firebaseServices.db
        .collection("Rb2b")
        .where("Seen At", ">=", from)  // Filter by `from` date
        .where("Seen At", "<=", to)    // Filter by `to` date
        .limit(500)
        .get();
        
      const data = snapshot.docs.map((doc) => {
        return {
          id: doc.id, // Document ID
          ...doc.data() // Document data
        };
      });
      setRB2bData(data);
    } catch (error) {
      console.error("Error fetching Rb2b data:", error);
    }
  };

  const handleDateChange = (e, type) => {
    const newDate = e.target.value;
    if (type === "from") {
      setFromDate(newDate);  // Update fromDate
    } else {
      setToDate(newDate);    // Update toDate
    }
  };

const handleWatch = async (index, email, id) => {
 
  
  if (rB2bData[index]['Business Email'] === email) {
    let count = rB2bData[index]['watch'] ? rB2bData[index]['watch'] + 1 : 1;
    
    const updatedRB2bData = [...rB2bData];  
    updatedRB2bData[index] = {
      ...updatedRB2bData[index], 
      watch: count,               
    };
    let agent = JSON.parse(localStorage.getItem("agent"))

   let agentName = agent?agent?.agent_name:"testUser"
   let agentId = agent?agent?.agentId:"9211-420"
   let watchTime = Date.now()
   let list ={id:agentId, name:agentName,time:watchTime}

try {
  // Step 1: Fetch the current watchArray from Firestore
  const docRef = firebaseServices.db.doc(`Rb2b/${id}`);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    const data = docSnapshot.data();
    const currentWatchArray = data.watchArray || [];

    // Step 2: Append the new list item to the array
    currentWatchArray.push(list);

    // Step 3: Update the document with the new array
    await docRef.update({
      watch: count,  // Update the 'watch' count
      watchArray: currentWatchArray,  // Update 'watchArray' with the new value
    });
    setRB2bData(updatedRB2bData);
    console.log("Document updated successfully.");
  } else {
    setRB2bData(updatedRB2bData);
    console.log("Document not found.");
  }
} catch (error) {
  setRB2bData(updatedRB2bData);
  console.error("Error updating document:", error);
}
  }else{
    console.log("inside else-=--=");
  }
};

const timeConversion = (time) => {
  let dateString = moment(time).format(
    "ddd, MMM DD, YYYY [at]  hh:mm A"
  );
  return dateString;
};

const hideProfileScreen = ()=>{
  return
}
  return (
    <>
      <SidebarNavigation />
      <div
        className="container chat-content-New px-lg-8 py-5 py-lg-5 mb-9"
        id="Testing"
      >
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center mb-4 fs-3">
              Visitor Information From RB2B
            </h3>
            <div className="k-flex from-to-date ">
              <TextField
                className="mr-5"
                id="from_date"
                name="from_date"
                variant="outlined"
                label="From"
                InputLabelProps={{ shrink: true }}
                type="date"
                value={fromDate}
                onChange={(e) => handleDateChange(e, "from")}
              />

              <TextField
                id="to_date"
                name="to_date"
                variant="outlined"
                label="To"
                InputLabelProps={{ shrink: true }}
                type="date"
                value={toDate}
                onChange={(e) => handleDateChange(e, "to")}
              />
            </div>

            <div
              className="repeat-wrap d-flex flex-wrap gap-3 scroll-4 overflow-x-hidden mt-3"
              style={{ height: "calc(-215px + 100vh)" }}
            >
              {rB2bData.length > 0 ? (
                rB2bData.map((data, index) => (
                  <div className="visitor-info visitor-info-repaet" key={index}>
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                        fontSize: "14px",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "40px",
                          flex: "0 0 auto",
                          borderRadius: "50%",
                          backgroundColor: "#007BFF",
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {index + 1}
                      </span>
                      <span>
                        Title: {data.Title && <strong>{data.Title}</strong>}
                      </span>
                    </div>
                    <ul className="d-flex flex-wrap mt-4">
                      <li>
                        <b>
                          <i>Company Name:</i>
                        </b>{" "}
                        {data["Company Name"]}
                      </li>
                      
                      <li>
                        <b>
                          <i>Estimate Revenue:</i>
                        </b>{" "}
                        {data["Estimate Revenue"]
                          ? data["Estimate Revenue"]
                          : "NA"}
                      </li>
                      <li>
                        <b>
                          <i>Legal Name:</i>
                        </b>{" "}
                        {data["First Name"]} {data["Last Name"]}
                      </li>
                      <li>
                        <b>
                          <i>Business Email:</i>
                        </b>{" "}
                        {data["Business Email"]}
                      </li>
                      <li>
                        <b>
                          <i>Referrer:</i>
                        </b>{" "}
                        {data["Referrer"]}
                      </li>
                      <li
                        onClick={() => {
                          handleWatch(
                            index,
                            data["Business Email"],
                            data["id"]
                          );
                        }}
                      >
                        <b>
                          <i>LinkedIn:</i>
                        </b>{" "}
                        <a
                          href={data["LinkedIn URL"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          See the profile
                        </a>
                      </li>
                      <li>
                        <b>
                          <i>Seen At:</i>
                        </b>{" "}
                        {data["Seen At"]}
                      </li>
                      <li>
                        <b>
                          <i>Captured URL:</i>
                        </b>{" "}
                        {data["Captured URL"]}
                      </li>
                      <li>
                        <b>
                          <i>Type:</i>
                        </b>{" "}
                        private
                      </li>
                      <li>
                        <b>
                          <i>Address:</i>
                        </b>{" "}
                        {data["City"]} {data["State"]} {data["Zipcode"]}
                      </li>
                      <li>
                        <b>
                          <i>Employees:</i>
                        </b>{" "}
                        {data["Employee Count"] ? data["Employee Count"] : "NA"}
                      </li>
                      <li className="row">
                        <div className="col-md-6 px-0">
                          <b className="col-md6">
                            <i>Tags:</i>
                          </b>{" "}
                          {data["Tags"]}
                        </div>
                        <div
                          className="col-md-6 text-right"
                          style={{'cursor':'pointer'}}
                          // data-toggle="modal"
                          // data-target="#viewModal"
                          onClick={() => {
                            setItem(index);
                          }}
                        >
                          {data["watch"] && 
                             <span className="view_icon">

                             <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 6.5a9.77 9.77 0 0 1 8.82 5.5c-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12A9.77 9.77 0 0 1 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5z"></path></svg>
                             </span>
                           
                          }
                        </div>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <p>No visitor information available.</p>
              )}
            </div>
          </div>
        </div>
        {/* style={{ 'display': profile_display === true ? 'block' : 'none' }} */}
        {item>(-1) && (
          <div
            class="modal fade"
            id="viewModal"
            tabindex="-1"
            data-backdrop="static"
            data-keyboard="false"
            aria-hidden="true"
          >
            
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header" style={{backgroundColor: '#e1dddd'}}>
                  <h5 class="modal-title text-18 text-black">Watch List </h5>
                  <button
                    type="button"
                    class="btn close"
                    data-dismiss="modal"  
                    aria-label="Close"
                    onClick={()=>{setItem(-1)}}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body bg-lightgray">
                  <table class="table table-bordered table-hover border-primary table-primary table-striped text-black mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                    {rB2bData[item]["watchArray"]
                      ?.filter((value, index, self) => 
                        index === self.findIndex((t) => (
                          t.id === value.id // Filter based on unique id
                        ))
                      )
                      .map((agent, int) => {
                        console.log(agent);
                        return (
                          <tr key={agent.id}>
                            <td>{agent?.name}</td>
                            <td>{timeConversion(agent?.time)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Rb2b;
